import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Pages/App';
import 'antd/dist/reset.css';
import { MantineProvider } from '@mantine/core';
import WebFont from "webfontloader";
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/it_IT'

WebFont.load({
  google: {
    families: ["Poppins", "Montserrat:700"],
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#FF006E',
      }
    }}
    locale={locale}>
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <App />
    </MantineProvider>
  </ConfigProvider>);


