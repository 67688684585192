import { IconInfoCircle } from '@tabler/icons-react';
import { Button, Modal } from 'antd';

export default function QrCodeRequest(props) {
    const { verifica, indietro } = props;
    return (
        <Modal
            open={true}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => indietro()}
            title={null}
            footer={null}>
            <div style={{ paddingTop:10, textAlign: "center" }}>
                <IconInfoCircle fill={"rgba(38, 126, 229, 1)"} color="#fff" size={80} />
                <div style={{ color: "rgba(131, 56, 236, 1)", fontFamily: "Poppins", fontWeight: 600,fontSize:18}}>
                    <div>QRCode identificato</div>
                    <div>Verificarlo?</div>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Button onClick={() => verifica()} type="primary"
                        size="large"
                        shape="round" className="btn_secondary">Verifica</Button>
                </div>
            </div>
        </Modal>
    );
}