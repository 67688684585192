import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { useRef } from 'react';
import { getImage } from '../../../Utils/FunctionUtils';
const CardCarousel = props => {
    const { data } = props;
    const slides = data && data !== null && data.map((item, i) => (
        <Carousel.Slide key={i}>
            <img src={getImage(item.url)} style={{ height: "auto", width: "100%",maxHeight: 250,maxWidth:250, aspectRatio: 1, borderRadius: 999}} alt={i.toString()}
                onError={(e) => { e.target.onerror = null; e.target.src = "https://site.cndl.org.br/wp-content/uploads/404.jpg" }}
            />
        </Carousel.Slide>
    ));

    const numeroRandom = Math.random() * (5000 - 2000) + 2000;
    const autoplay = useRef(Autoplay({ delay: Math.round(numeroRandom) }));

    return (
        <Carousel
            loop
            withIndicators
            plugins={[autoplay.current]}
            withControls={false}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            slideSize="100%"
            breakpoints={[{ maxWidth: 'md', slideSize: '100%', slideGap: 1 }]}
            align="start"
            slidesToScroll={1}>
            {slides}
        </Carousel>
    );
}

export default CardCarousel;