/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { saveStaff } from "../../../Utils/ApiUtils";

const AggiungiStaff = props => {
    const { visible, setVisible, handleRemoveToken, navigate, setLoading, setStaff, setError } = props;
    const labelProps = { style: { color: "#fb5607", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "3px solid #fb5607", backgroundColor: "#fff" }, error: { color: "#fb5607", fontFamily: "Poppins", fontSize: 12 } };

    const [loadingCrea, setLoadingCrea] = useState(false);

    const form = useForm({
        initialValues: {
            nome: '',
            cognome: '',
            ruolo: '',
            instagram: '',
        },
        validate: {
            nome: (val) => (typeof val === 'undefined' || val.trim().length <= 0 ? "Nome non valido" : null),
            ruolo: (val) => (typeof val === 'undefined' || val.trim().length <= 0 ? "Ruolo non valido" : null),
        }
    });

    useEffect(() => {
        if (visible !== null) {
            form.setValues(visible);
        } else {
            form.reset();
        }
    }, [visible])


    return (
        <Modal
            open={visible !== null}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => setVisible(null)}
            title={<center style={{ color: "rgb(160, 12, 152)", fontFamily: "Poppins", fontWeight: 600, marginBottom: 20 }}>
                {visible && visible !== null && visible.id !== null ? "Modifica staffer" : "Aggiungi staffer"}
            </center>}
            footer={null}>
            <>
                <LoadingOverlay visible={loadingCrea} />
                <form style={{ marginBottom: 5 }}
                    onSubmit={form.onSubmit((values) =>
                        saveStaff(visible.id, values, setLoading, setLoadingCrea, setVisible, setStaff, setError, handleRemoveToken, navigate))}>
                    <TextInput
                        required
                        label="Nome"
                        placeholder="Nome"
                        value={form.values.nome}
                        onChange={(event) => form.setFieldValue('nome', event.currentTarget.value)}
                        error={form.errors.nome}
                        radius="lg"
                        size='lg'
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <TextInput
                        label="Cognome"
                        placeholder="Cognome"
                        value={form.values.cognome}
                        onChange={(event) => form.setFieldValue('cognome', event.currentTarget.value)}
                        error={form.errors.cognome}
                        radius="lg"
                        size='lg'
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <TextInput
                        required
                        label="Ruolo"
                        placeholder="Ruolo"
                        value={form.values.ruolo}
                        onChange={(event) => form.setFieldValue('ruolo', event.currentTarget.value)}
                        error={form.errors.ruolo}
                        radius="lg"
                        size='lg'
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <TextInput
                        label="Instagram"
                        placeholder="Instagram"
                        value={form.values.instagram}
                        onChange={(event) => form.setFieldValue('instagram', event.currentTarget.value)}
                        error={form.errors.instagram}
                        radius="lg"
                        size='lg'
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <div style={{ marginTop: 25, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                        <Button onClick={() => {
                            form.reset();
                            setVisible(null);
                        }}
                            style={{ fontWeight: 600, fontFamily: "Poppins" }}>
                            Annulla
                        </Button>
                        <Button type="primary" shape="round" loading={loadingCrea} style={{ fontWeight: 600, fontFamily: "Poppins" }}
                            htmlType="submit">
                            {visible && visible !== null && visible.id !== null ? "Modifica staffer" : "Aggiungi staffer"}
                        </Button>
                    </div>
                </form>
            </>
        </Modal>
    )
}
export default AggiungiStaff;