import { LoadingOverlay } from "@mantine/core";
import { Button, Image, Modal } from "antd";
import { useState } from "react";
import { disdiciEventoWrap } from "../../../Utils/FunctionUtils";

const Biglietto = props => {
    const { biglietto, setBiglietto, loading, setLoading,
        setError, setEventi, setPrenotazioni, handleRemoveToken, navigate } = props;

    const [loadingAnnulla, setLoadingAnnulla] = useState(false);

    return (
        <Modal
            open={biglietto !== null}
            onCancel={() => setBiglietto(null)}
            style={{ maxWidth: 400, minWidth: 200 }}
            width={"92%"}
            title={<center style={{ color: "#fb5607", fontFamily: "Poppins", fontWeight: 600 }}>La tua prenotazione:</center>}
            footer={<div>
                <Button style={{ marginRight: 10 }} danger
                    onClick={() => setBiglietto(null)}>
                    Chiudi
                </Button>
                {biglietto && biglietto !== null && biglietto.id && biglietto.id !== null && (setPrenotazioni !== null || setEventi !== null) && 
                    <Button style={{ marginRight: 10 }} type="primary" loading={loadingAnnulla}
                        onClick={() => disdiciEventoWrap(biglietto.id, biglietto.limiteAnnullamento, biglietto.startDate, setLoadingAnnulla, loading, setLoading, setError, setEventi,
                            setPrenotazioni, handleRemoveToken, navigate, setBiglietto)}>
                        Annulla prenotazione
                    </Button>}
            </div>}>
            <center>
                <LoadingOverlay visible={typeof biglietto === 'undefined' || biglietto === null || typeof biglietto.qrCode === 'undefined' || biglietto.qrCode === null} />
                <div style={{ marginBottom: 5, color: "rgb(160, 12, 152)", fontFamily: "Poppins", fontWeight: 600, textAlign: "center" }}>
                    {biglietto && biglietto !== null && biglietto.titolo && biglietto.titolo !== null ? biglietto.titolo : "Caricamento..."}
                </div>
                <center style={{ marginBottom: 20, border: "3px solid #fb5607", borderRadius: 10, maxWidth: 300 }}>
                    {biglietto && biglietto !== null && biglietto.qrCode && biglietto.qrCode !== null &&
                        <Image alt={"qrCode"} draggable={false} src={biglietto.qrCode} style={{
                            userSelect: "none",
                            width: "100%", height: "auto", maxWidth: 300
                        }} preview={false} />}
                </center>
            </center>
        </Modal>

    )
}
export default Biglietto;