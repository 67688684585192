/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay, TextInput } from '@mantine/core';
import { IconBell, IconChevronLeft, IconDotsVertical, IconSearch } from '@tabler/icons-react';
import { Badge, Pagination } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadListaAttesaByAdmin, getNotificheCount } from '../Utils/ApiUtils';
import { useTokenActions } from '../Utils/tokenUtils';
import useWindowDimensions from '../Utils/windowDimension';
import ErrorPage from './Components/misc/ErrorPage';
import InvioEmailUtente from './Components/utenti/InvioEmailUtente';
import MenuPrenotazioniAttesa from './Components/utenti/MenuPrenotazioniAttesa';

export default function AmministraPrenotazioniAttesa() {
    const { height } = useWindowDimensions();

    const token = useSelector(state => state.token);
    const { handleRemoveToken } = useTokenActions();
    const { id } = useParams();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [search, setSearch] = useState("");
    const [utenti, setUtenti] = useState([])
    const [page, setPage] = useState(0);
    const [usersCount, setUsersCount] = useState(0);
    const [notificheCount, setNotificheCount] = useState(0);
    const [apriMenu, setApriMenu] = useState(null);
    const [apriInviaEmail, setApriInviaEmail] = useState(null);

    const labelProps = { style: { color: "#fb5607", fontWeight: 600, fontSize: 16, paddingBottom: 5, fontFamily: "Poppins" } };
    const labelStyles = {
        input: { border: "none", backgroundColor: "rgba(255,255,255,0.69)", color: "#fb5607", fontWeight: 600, fontFamily: "Poppins" },
        error: { color: "#fff", fontFamily: "Poppins", fontSize: 12 }
    };

    useEffect(() => {
        downloadListaAttesaByAdmin(id, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError);
        getNotificheCount(setNotificheCount);

        const interval = setInterval(() => {
            getNotificheCount(setNotificheCount);
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins" }}>
                            <div style={{ color: "#fff", fontSize: 30, marginBottom: 10, fontWeight: "bold", lineHeight: 1.4, paddingLeft: 20, paddingRight: 20 }}>
                                In Attesa ({usersCount})
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ maxWidth: 800, paddingLeft: 20, paddingRight: 20, width: "100%" }}>
                                    <TextInput placeholder='Cerca un utente' value={search} onChange={e => {
                                        setSearch(e.target.value)
                                        setPage(0);
                                        downloadListaAttesaByAdmin(id, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError);
                                    }}
                                        icon={<IconSearch style={{ padding: 3, color: "rgba(131, 56, 236, 1)" }} />}
                                        radius="md"
                                        size='lg'
                                        labelProps={labelProps}
                                        styles={labelStyles}
                                    />
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column" }}>
                                <div style={{
                                    width: "100%", height: height - 380, overflowY: "auto", marginTop: 20, maxWidth: 800,
                                    display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", paddingLeft: 20, paddingRight: 20
                                }}>
                                    {utenti && utenti.length > 0 ? utenti.map((item, i) => (
                                        <div key={i}
                                            style={{
                                                color: "#444", backgroundColor: "rgba(255,255,255,0.69)", padding: 14,
                                                borderRadius: 10, marginBottom: i === utenti.length - 1 ? 0 : 20, width: "100%", fontFamily: "Poppins",
                                                display: "flex", alignItems: "center", justifyContent: "space-between"
                                            }}>
                                            <div>
                                                <div style={{ color: "rgba(131, 56, 236, 1)", fontFamily: "Poppins", fontWeight: 600, fontSize: 18 }}>
                                                    {item.nome + " " + item.cognome}
                                                </div>
                                                {item.dataPrenotazione &&
                                                    <div style={{ fontSize: 12, color: "rgba(251, 86, 7, 1)" }}>Prenotato il {moment(item.dataPrenotazione).format("DD/MM/YYYY")}
                                                    </div>}
                                            </div>

                                            <div style={{
                                                backgroundColor: "rgba(255, 255, 255, 0.6)", borderRadius: 5,
                                                display: "flex", justifyContent: "center", alignItems: "center", aspectRatio: 1, cursor: "pointer", userSelect: "none"
                                            }}
                                                onClick={() => setApriMenu(item)}>
                                                <IconDotsVertical color="rgba(131, 56, 236, 1)" />
                                            </div>
                                        </div>
                                    )) :
                                        <center style={{ fontWeight: 600, fontFamily: "Poppins", color: "#fff", marginTop: 20 }}>Nessun risultato trovato</center>}
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingRight: 10, paddingLeft: 10, marginTop: 20 }}>
                                    <Pagination simple current={page + 1} total={usersCount} pageSize={10} onChange={(r) => {
                                        setPage(r - 1)
                                        downloadListaAttesaByAdmin(id, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError);
                                    }} />
                                </div>
                            </div>
                        </div>

                        {apriMenu && <MenuPrenotazioniAttesa visible={apriMenu} setVisible={setApriMenu} navigate={navigate}
                            handleRemoveToken={handleRemoveToken} setError={setError}
                            apriInviaEmail={item => {
                                setApriMenu(null);
                                setApriInviaEmail(item);
                            }}
                            eventId={id}
                            download={() => downloadListaAttesaByAdmin(id, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError)} />}

                        {apriInviaEmail && <InvioEmailUtente visible={apriInviaEmail} setVisible={setApriInviaEmail}
                            handleRemoveToken={handleRemoveToken} navigate={navigate} setError={setError} />}
                    </div>
                </div> : <ErrorPage onClick={() => {
                    setError(false);
                    downloadListaAttesaByAdmin(id, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError);
                }} />}
        </div>);
}