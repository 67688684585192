import { IconAlertCircle, IconAlertTriangle, IconCircleCheck } from '@tabler/icons-react';
import { Button, Modal } from 'antd';

export default function QrCodeVerifica(props) {
    const { verifica, indietro, data, segnala } = props;
    const utilizzi = data.utilizzi;
    const valido = data.valido;
    const warningMsg = "Questo biglietto è gia stato utilizzato " + utilizzi + (utilizzi > 1 ? " volte!" : " volta!");
    const errorMsg = "Questo biglietto non è valido!";
    const successMsg = "Questo biglietto è valido!";
    return (
        <Modal
            open={true}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => indietro()}
            title={null}
            footer={null}>
            <div style={{ paddingBottom: 10, textAlign: "center" }}>
                <div>
                    {!valido ? <IconAlertCircle color={"#fff"} fill="red" size={80} />
                        : utilizzi > 0 ? <IconAlertTriangle color={"#fff"} fill="orange" size={80} />
                            : <IconCircleCheck color={"#fff"} fill="green" size={80} />}
                </div>
                <div style={{ color: "rgba(131, 56, 236, 1)", fontFamily: "Poppins", fontWeight: 600, fontSize: 18 }}>
                    {!valido ? errorMsg : utilizzi > 0 ? warningMsg : successMsg}
                </div>

                {data.evento && <div style={{color:"rgba(251, 86, 7, 1)",fontWeight:600,fontFamily:"Poppins"}}>{data.evento}</div>}
                {valido && <div style={{ marginTop: 40,color:"rgba(251, 86, 7, 1)",fontFamily:"Poppins" }}>
                    <b>Cliente</b>
                    <div>{data.nome + " " + data.cognome}</div>
                </div>}

                <div style={{ marginTop: 40 }}>
                    {valido && <Button onClick={() => verifica()} type="primary"
                        size="large"
                        shape="round" className="btn_secondary">Utilizza biglietto</Button>
                    }
                    {!valido &&
                        <Button onClick={() => segnala()} type="primary"
                            size="large" style={{fontWeight:600,fontFamily:"Poppins"}}
                            shape="round">Segnala un problema</Button>
                    }
                </div>
            </div>
        </Modal>
    );
}