/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { IconBell, IconChevronLeft, IconPlus } from "@tabler/icons-react";
import { Badge, Button, Upload, notification } from "antd";
import React, { useEffect, useState } from "react";
import { downloadEntrypage, getNotificheCount, saveEntrypage } from "../Utils/ApiUtils";
import { getImage } from "../Utils/FunctionUtils";
import ErrorPage from "./Components/misc/ErrorPage";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTokenActions } from "../Utils/tokenUtils";

const ModificaHomepage = () => {
    const navigate = useNavigate();
    const { handleRemoveToken } = useTokenActions();
    const token = useSelector(state => state.token);

    const [loading, setLoading] = useState(false);
    const [loadingCrea, setLoadingCrea] = useState(false);
    const [file, setFile] = useState(null);
    const [url, setUrl] = useState(null);
    const [error, setError] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);

    const handleChange = ({ fileList: newFile }) => setFile(newFile[0]);

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const caricaGIF = () => {
        saveEntrypage(setLoadingCrea,file,token,handleRemoveToken,navigate,setError);
    }

    useEffect(() => {
        if (token && token !== null) {
            downloadEntrypage(setLoading, setUrl, setError);
            getNotificheCount(setNotificheCount);
        } else {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }

        const interval = setInterval(() => {
            if (token && token !== null) {
                downloadEntrypage(setLoading, setUrl, setError);
                getNotificheCount(setNotificheCount);
            } else {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }, 300000);
        return () => clearInterval(interval);

    }, [])

    useEffect(() => {
        if (url !== null) {
            setFile({
                uid: 1,
                name: url,
                fromEdit: true,
                status: 'done',
                url: getImage(url),
            });
        } else setFile(null);
    }, [url])


    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ color: "#fff", fontSize: 30, marginBottom: 10, fontWeight: "bold", lineHeight: 1.4 }}>
                                Personalizza
                                <div style={{ color: "rgb(160, 12, 152)", fontSize: 15, fontWeight: "bold" }}>
                                    Carica la gif per l'homepage
                                </div>
                            </div>
                            <div style={{
                                width: "100%", overflowY: "auto", paddingLeft: 10, paddingRight: 10,marginTop:40,
                                display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column"
                            }}>
                                <Upload
                                    onRemove={() => setFile(null)}
                                    accept="image/gif,image/webp"
                                    listType="picture-card"
                                    fileList={file && file !== null ? [file] : []}
                                    multiple={false}
                                    className="gif-upload"
                                    onChange={handleChange}
                                    customRequest={dummyRequest}>
                                    {file && file !== null ? null : <div>
                                        <IconPlus color="#fb5607" stroke={3} size={45} />
                                    </div>}
                                </Upload>
                                <div style={{ marginTop: 25, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                                    <Button type="primary" shape="round" loading={loadingCrea} style={{ fontWeight: 600, fontFamily: "Poppins" }}
                                    size="large" onClick={()=>file && file.originFileObj && caricaGIF()}>
                                        Carica gif
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    downloadEntrypage(setLoading, setUrl, setError);
                }} />}
        </div>)
}

export default ModificaHomepage;