import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../Utils/windowDimension";
import "./css/Entrypage.css"; // importa il file CSS
import { downloadEntrypage } from "../Utils/ApiUtils";
import { LoadingOverlay } from "@mantine/core";
import { getImage } from "../Utils/FunctionUtils";

const Entrypage = () => {
  const navigate = useNavigate();
  const { height } = useWindowDimensions();

  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      downloadEntrypage(setLoading, setUrl, null);
    const interval = setInterval(() => {
      downloadEntrypage(setLoading, setUrl, null);
    }, 300000);
    return () => clearInterval(interval);

  }, [])

  return (
    <>
    <LoadingOverlay visible={loading} />
    <div className="palette-root-root-root" style={{ height: height, overflowY: "auto" }}>
      <div className="wrapper">
        <div className="circle">
          <div className="inner-circle">
            <div className="image-circle">
              <img
                alt="main"
                className="image1"
                src={url && url !== null ? getImage(url) : "entrybase.png"}
                draggable={false}
              />
            </div>
            <div className="rectangle">
              <div className="title">CANDIZIOLAND</div>
            </div>
          </div>
        </div>
        <center><img alt="logo" className="logo" src={process.env.PUBLIC_URL + "/logo.png"} /></center>
      </div>
      <div className="rectangle1">
        <div className="events" onClick={() => navigate("/homepage")}>
          Eventi
        </div>
      </div>
    </div>
    </>
  );
};

export default Entrypage;