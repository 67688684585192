import { Group, Spoiler, Text } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import { Button } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { btnPrenotaOraAction, calcolaCapienzaRimanente, getBtnPrenotaText } from '../../../Utils/FunctionUtils';
import useWindowDimensions from '../../../Utils/windowDimension';
import CardCarousel from './CardCarousel';
import MenuEvento from './MenuEvento';
import ModificaCapienza from './ModificaCapienza';

const EventCard = (props) => {
    const { prenotazione, item, setLoading, setError, setEventi, handleRemoveToken,
        navigate, isAdmin, token, loading, setBiglietto, setPrenotazioni, gruppi } = props;

    const [loadingBtnPrenota, setLoadingBtnPrenota] = useState(false);
    const [apriMenu, setApriMenu] = useState(false);
    const [apriCapienza, setApriCapienza] = useState(null);
    const { width } = useWindowDimensions();

    let enableGroupButton = !isAdmin && gruppi && gruppi.length > 0;

    const getTitle = item => moment(item.startDate).isAfter(new Date()) ?
        "Inizia il " + moment(item.startDate).format('DD MMMM YYYY') + " alle ore " + moment(item.startDate).format('HH:mm')
        : item.concluso ? "L'evento è concluso" : "L'evento è iniziato!";


    function calcolaColorePercentuale(item) {
        const percentuale = calcolaCapienzaRimanente(item);
        if (percentuale > 50) {
            return "green";
        } else if (percentuale > 20) {
            return "yellow";
        } else {
            return "darkred";
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", maxWidth: 800,width:"100%" }}>
            {item.images && item.images !== null && item.images.length > 0 &&
            <div style={{width:width,borderRadius:999,textAlign:"center"}}>
                <CardCarousel data={item.images} />
                </div>}

            <Text align="center" size="md" weight={600} color="#fff" style={{ paddingTop: 10, fontFamily: 'Poppins' }} pl={10} pr={10} mb={"xs"}>
                {item.titolo}
            </Text>

            <div style={{
                borderRadius: 20, backgroundColor: "rgba(255, 255, 255, 0.2)", width: "90%", borderBottomLeftRadius: 0, borderTopRightRadius: 0, padding: 5
            }}>
                <Text align="center" size="sm" color='#fff' style={{ fontFamily: "Poppins" }}>
                    {getTitle(item)}
                </Text>

                <Text align="center" size="xs" color="#000" mb="xs">
                    <Spoiler maxHeight={50} showLabel="Mostra altro" hideLabel="Nascondi" style={{ fontFamily: "Poppins" }}>
                        {item.descrizione}
                    </Spoiler>
                </Text>

                {item.indirizzo && item.indirizzo !== null &&
                    <Text align="center" size="sm" color="dimmed" mb="xs">
                        <Link to={item.indirizzo} target="_blank" rel="noopener noreferrer" style={{ textTransform: "none", textDecoration: "none" }}>
                            Google Maps</Link>
                    </Text>}

                <Group position="center" spacing={30}>
                    {item.capienzaPubblica && <div>
                        <Text align="center" size="sm" weight={500} style={{ fontFamily: "Poppins" }}>
                            LIBERI
                        </Text>
                        <Text align="center" weight={600} size="sm" color={calcolaColorePercentuale(item)} style={{ fontFamily: "Poppins" }}>
                            {calcolaCapienzaRimanente(item) + " %"}
                        </Text>
                    </div>}
                    {item.costoPubblico && item.costo && <div>
                        <Text align="center" size="sm" weight={500} style={{ fontFamily: "Poppins" }}>
                            COSTO
                        </Text>
                        <Text align="center" size="sm" color="#000" style={{ fontFamily: "Poppins" }}>
                            {item.costo} €
                        </Text>
                    </div>}
                </Group>

            </div>
            <div style={{ marginTop: 20, display: "flex", justifyContent: isAdmin||enableGroupButton?"space-between":"center", width: "90%" }}>
                <Button loading={loadingBtnPrenota}
                    onClick={() => btnPrenotaOraAction(item.id, item.titolo, item.limiteAnnullamento, item.startDate, item.alertPrePrenotazione,
                        setLoadingBtnPrenota, setLoading,
                        setError, setEventi, handleRemoveToken, navigate, isAdmin, loading, setBiglietto, token, setPrenotazioni)}
                    type="primary" size={enableGroupButton ? "middle" : "large"} shape="round"
                    style={{ minWidth: 170, fontFamily: "Poppins", fontWeight: 600 }}>
                    {getBtnPrenotaText(isAdmin, item, token, prenotazione)}
                </Button>
                {enableGroupButton &&
                    <Button onClick={() => navigate('/prenotazione-gruppo/'+item.id)}
                        type="primary" size="middle" shape="round"
                        className="btn_secondary"
                        style={{ fontFamily: "Poppins", fontWeight: 600 }}>
                        Prenota per gruppo
                    </Button>}
                {isAdmin &&
                    <div style={{
                        backgroundColor: "rgba(255, 255, 255, 0.6)", borderRadius: 10,
                        display: "flex", justifyContent: "center", alignItems: "center", aspectRatio: 1, cursor: "pointer", userSelect: "none",
                        minWidth:40
                    }}
                        onClick={() => setApriMenu(true)}>
                        <IconDotsVertical color="rgba(131, 56, 236, 1)" />
                    </div>}
            </div>

            {apriMenu && <MenuEvento visible={apriMenu} setVisible={setApriMenu} item={item} navigate={navigate} loading={loading}
                setLoading={setLoading} setEventi={setEventi} handleRemoveToken={handleRemoveToken} setError={setError}
                apriModificaCapienza={() => {
                    setApriMenu(false);
                    setApriCapienza(item);
                }}
            />}

            {apriCapienza && <ModificaCapienza visible={apriCapienza} setVisible={setApriCapienza}
                loading={loading} setLoading={setLoading} handleRemoveToken={handleRemoveToken}
                navigate={navigate} setError={setError} setEventi={setEventi} />}
        </div>
    );
}

export default EventCard;