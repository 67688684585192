import { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import CheckoutForm from './CheckoutForm';
import { Drawer } from 'antd';
import { url } from '../../../Utils/Costants';

const appearance = {
    theme: 'flat',
    variables: { colorPrimaryText: '#fff' }
};

function Payment(props) {
    const { stripePromise } = props;
    const [clientSecret, setClientSecret] = useState('');
    const [ammount, setAmmount] = useState(0);

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        axios.get(url+"/payment/create-payment-intent")
            .then(res => {
                setClientSecret(res.data.apiKey);
                setAmmount(res.data.ammount);
            }).catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <div style={{ width: "100%", justifyContent: "flex-start", alignItems: "center", alignContent: "center", display: "flex", flexDirection: "column", height: "100vh", padding: "40px 25px" }}>
            <h1><img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} /></h1>
            <Drawer placement='bottom' open={true} height={"80%"} closeIcon={null} title={<div>Acquisto prevendita</div>} closable={false}>
                <div style={{ maxWidth: 500, justifyContent: "center", alignItems: "center", alignContent: "center", display: "flex", flexDirection: "column" }}>
                    {clientSecret && stripePromise && (
                        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                            <CheckoutForm ammount={ammount} />
                        </Elements>
                    )}
                </div>
            </Drawer>
        </div>
    );
}

export default Payment;