import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  role: null
};

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    removeToken: (state) => {
      state.token = null;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    removeRole: (state) => {
      state.role = null;
    }
  },
});

export const { setToken, removeToken,setRole,removeRole } = tokenSlice.actions;
export default tokenSlice.reducer;
