/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from "@ant-design/icons";
import { LoadingOverlay, TextInput } from "@mantine/core";
import { IconBell, IconChevronLeft, IconTrash, IconX } from "@tabler/icons-react";
import { Badge, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteGroup, esciGroup, espelliGruppo, getGruppo, getNotificheCount, invitaGruppo } from "../Utils/ApiUtils";
import ErrorPage from "./Components/misc/ErrorPage";
import { useForm } from "@mantine/form";
import { showPropsConfirm } from "../Utils/FunctionUtils";
import { useTokenActions } from "../Utils/tokenUtils";
import useWindowDimensions from "../Utils/windowDimension";
const GruppiDettaglio = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const { handleRemoveToken } = useTokenActions();
    const { id } = useParams();
    const { height } = useWindowDimensions();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [notificheCount, setNotificheCount] = useState(0);
    const [gruppo, setGruppo] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const labelProps = { style: { color: "#fff", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "3px solid #fb5607", backgroundColor: "#fbe0b9" }, error: { color: "#fff" } };

    const submit = values => {
        const okType = "primary";
        const cancelText = "Annulla"
        const title = "Invitare nel gruppo?";
        const content = "Sei sicuro di voler invitare nel gruppo: " + values.cellulare + " ?";
        const okText = "Invita";
        const onOk = () => invitaGruppo(id, values.cellulare, setLoading, setGruppo, navigate, form, handleRemoveToken, setError);
        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    const cancellaGruppo = item => {
        const okType = "primary";
        const cancelText = "Annulla"

        let title = "Cancellare il gruppo?";
        let content = "Sei sicuro di voler cancellare il gruppo: " + item.name + "?";
        let okText = "Cancella";
        let onOk = () => deleteGroup(setLoading, null, setError, setLoadingDelete, item.id, handleRemoveToken, navigate);

        if (!item.proprietario) {
            title = "Uscire dal gruppo?";
            content = "Sei sicuro di voler uscire dal gruppo: " + item.name + "?";
            okText = "Esci";
            onOk = () => esciGroup(setLoading, null, setError, setLoadingDelete, item.id, handleRemoveToken, navigate);
        }

        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    const rimuoviPartecipante = item => {
        const okType = "primary";
        const cancelText = "Annulla"
        const title = "Espellere dal gruppo?";
        const content = "Sei sicuro di voler espellere dal gruppo: " + item.nome + " ?";
        const okText = "Espelli";
        const onOk = () => espelliGruppo(id, item.id, setLoadingDelete, setLoading, setGruppo, navigate, handleRemoveToken, setError);
        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    const form = useForm({
        initialValues: {
            cellulare: '',
        },

        validate: {
            cellulare: (val) => (typeof val === 'undefined' || val.length < 10 ? 'Inserisci un cellulare valido' : null),
        },
    });

    useEffect(() => {
        getGruppo(id, setLoading, setGruppo, navigate, handleRemoveToken);
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
        }

        const interval = setInterval(() => {
            getGruppo(id, setLoading, setGruppo, navigate, handleRemoveToken);
            if (token && token !== null) {
                getNotificheCount(setNotificheCount);
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ marginTop: 10, display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
                                <div style={{ color: "#fff", fontSize: 30, fontWeight: "bolder" }}>
                                    {gruppo && gruppo !== null && gruppo.name}
                                </div>
                                <div>
                                    <IconTrash
                                        onClick={() => cancellaGruppo(gruppo)}
                                        style={{ cursor: "pointer", userSelect: "none" }}
                                        color="#fff" size={25} />
                                </div>
                            </div>
                            {gruppo && gruppo !== null && gruppo.proprietario &&
                                <div style={{ marginTop: 15 }}>
                                    <form onSubmit={form.onSubmit((values) => submit(values))}
                                        style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", gap: 10 }}>
                                        <div>
                                            <TextInput
                                                required
                                                id="cellulare"
                                                name="cellulare"
                                                placeholder="Numero di cellulare"
                                                value={form.values.cellulare}
                                                onChange={(event) => form.setFieldValue('cellulare', event.currentTarget.value)}
                                                error={form.errors.cellulare}
                                                type="tel"
                                                radius="lg"
                                                size='lg'
                                                labelProps={labelProps}
                                                styles={labelStyles} />
                                        </div>
                                        <div>
                                            <Button className="btn_secondary" htmlType="submit"
                                                type="primary" shape="round">Aggiungi</Button>
                                        </div>
                                    </form>
                                </div>}
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {gruppo && gruppo !== null && gruppo.partecipanti
                                    && gruppo.partecipanti !== null && gruppo.partecipanti.length > 0 ?
                                    <div style={{ width: "100%", height: height-290, overflowY: "auto", marginTop: 25, maxWidth: 400}}>
                                        {gruppo.partecipanti.map((item, index) =>
                                            <div key={index}
                                                style={{
                                                    marginBottom: 10,
                                                    borderBottom: "1px solid rgba(100, 100, 100, 0.1)",
                                                    display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: "100%",
                                                    borderRadius: 7, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 15, paddingBottom: 12
                                                }}>
                                                <div style={{
                                                    color: "#A00C98", fontSize: 15, fontWeight: 600, fontFamily: "Poppins",
                                                    flex: 0.8
                                                }}>
                                                    <div style={{
                                                        color: "#A00C98", fontSize: 15, fontWeight: 600, fontFamily: "Poppins",
                                                    }}>
                                                        {item.nome}
                                                    </div>
                                                    <div style={{
                                                        color: "#A00C98", fontSize: 13, fontWeight: 600, fontFamily: "Poppins",
                                                    }}>
                                                        Cellulare: <span style={{ color: "#3771c8" }}>{item.cellulare}</span>
                                                    </div>
                                                    {item.pending && <div style={{
                                                        color: "#fb5607", fontSize: 13, fontWeight: 600, fontFamily: "Poppins",
                                                    }}>
                                                        in attesa di conferma
                                                    </div>}
                                                </div>
                                                {gruppo.proprietario && <div style={{ cursor: loadingDelete ? "not-allowed" : "pointer", userSelect: "none", flex: 0.05 }}
                                                    onClick={() => !loadingDelete && rimuoviPartecipante(item)}>
                                                    {loadingDelete ? <LoadingOutlined spin /> : <IconX color="red" size={20} />}
                                                </div>}
                                            </div>
                                        )}
                                    </div> :
                                    <div style={{ marginBottom: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 15, borderRadius: 10, marginTop: 30 }}>
                                        <div style={{ color: "#A00C98", fontSize: 14 }}>Questo gruppo non ha nessun partecipante</div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    getGruppo(id, setLoading, setGruppo, navigate, handleRemoveToken);
                }} />}
        </div>
    )
}
export default GruppiDettaglio;