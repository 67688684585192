/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Checkbox, LoadingOverlay, Select, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconBell, IconChevronLeft } from '@tabler/icons-react';
import { Badge, Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { downloadGruppiByAdmin, getNotificheCount, inviaNotificaMultipleByAdmin } from '../Utils/ApiUtils';
import { useTokenActions } from '../Utils/tokenUtils';
import useWindowDimensions from '../Utils/windowDimension';
import ErrorPage from './Components/misc/ErrorPage';

function InvioNotifica() {
    const token = useSelector(state => state.token);
    const { handleRemoveToken } = useTokenActions();
    const navigate = useNavigate();
    const { height } = useWindowDimensions();

    const labelProps = { style: { color: "#fff", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "none", backgroundColor: "#fff" }, error: { color: "#fb5607", fontFamily: "Poppins", fontSize: 12 } };

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);
    const [loadingGruppi, setLoadingGruppi] = useState(false);
    const [gruppi, setGruppi] = useState(null);

    const items = [
        { label: 'Tutti', value: 'tutti' },
        { label: 'Solo bannati', value: 'banned' },
        { label: 'Solo chi ha saltato 1 prenotazione', value: '1pre' },
        { label: 'Solo chi ha saltato 2 prenotazioni', value: '2pre' },
        { label: 'Registrati nelle ultime 24 ore', value: '24h' },
        { label: 'Registrati nelle ultime 48 ore', value: '48h' },
        { label: 'Registrati nelle ultime 72 ore', value: '72h' },
    ];

    const inoltraNotifica = values => {
        const title = values.title;
        const testo = values.testo;
        const type = values.type;
        const gruppo = values.gruppo;
        inviaNotificaMultipleByAdmin(testo, type, gruppo, title, handleRemoveToken, navigate, setError, setLoading,false);
    }

    const form = useForm({
        initialValues: {
            title: '',
            testo: '',
            gruppo: '',
            type: ''
        },
        validate: {
            testo: (val) => (typeof val === 'undefined' || val.trim().length <= 0 ? "Testo non valido" : null),
            title: (val) => (typeof val === 'undefined' || val.trim().length <= 0 ? "Titolo non valido" : null),
        }
    });

    useEffect(() => {
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
            downloadGruppiByAdmin(setLoadingGruppi, setGruppi, handleRemoveToken, navigate);
        } else {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }

        const interval = setInterval(() => {
            getNotificheCount(setNotificheCount);
            downloadGruppiByAdmin(setLoadingGruppi, setGruppi, handleRemoveToken, navigate);
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset", overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ color: "#fff", fontSize: 30, marginTop: 10, fontWeight: "bolder", marginBottom: 20 }}>
                                Invio Notifica
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ maxWidth: 800, height: height - 300, overflowY: "auto", width: "100%" }}>
                                    <form style={{ marginBottom: 5 }}
                                        onSubmit={form.onSubmit((values) => inoltraNotifica(values))}>
                                        {items.map((item, index) =>
                                            <Checkbox
                                                size={"md"}
                                                key={index}
                                                label={<div style={{ color: "rgba(131, 56, 236, 1)", fontSize: 16, fontFamily: "Poppins", fontWeight: 600 }}>
                                                    {item.label}
                                                </div>}
                                                value={form.values.type === item.value}
                                                checked={form.values.type === item.value}
                                                onChange={() => form.setFieldValue('type', form.values.type === item.value ? '' : item.value)}
                                                style={{ marginBottom: 10 }}
                                                labelProps={labelProps} />)}

                                        {gruppi && <Select
                                            searchable
                                            clearable
                                            value={form.values.gruppo}
                                            icon={loadingGruppi && <LoadingOutlined spin style={{ color: "blue" }} />}
                                            transitionProps={{ transition: 'pop-top-left', duration: 80, timingFunction: 'ease' }}
                                            nothingFound="Nessun gruppo trovato"
                                            label="Solo i partecipanti di un gruppo"
                                            placeholder="Seleziona gruppo"
                                            onChange={gruppo => form.setFieldValue("gruppo", gruppo)}
                                            data={gruppi}
                                            labelProps={labelProps}
                                            styles={labelStyles}
                                            radius="md"
                                            size='md' />}

                                        <TextInput
                                            required
                                            mt="md"
                                            label="Titolo"
                                            placeholder="Titolo..."
                                            value={form.values.title}
                                            error={form.errors.title}
                                            onChange={(event) => form.setFieldValue("title", event.target.value)}
                                            radius="md"
                                            size='xl'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <Textarea
                                            required
                                            minRows={4}
                                            mt="md"
                                            label="Testo"
                                            placeholder="Scrivi un testo.."
                                            value={form.values.testo}
                                            error={form.errors.testo}
                                            onChange={(event) => form.setFieldValue("testo", event.target.value)}
                                            radius="md"
                                            size='xl'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <div style={{ marginTop: 25, display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}>
                                            <Button className="btn_secondary" size="large" type="primary" shape="round"
                                                loading={loading} style={{ fontWeight: 600, fontFamily: "Poppins" }}
                                                htmlType="submit">
                                                Invia Notifica
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <ErrorPage onClick={() => {
                    setError(false);
                }} />
            }
        </div>
    );
}


export default InvioNotifica;