/* eslint-disable react-hooks/exhaustive-deps */
import {
    Anchor,
    Checkbox,
    Group,
    PasswordInput,
    Stack,
    Text,
    TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Button, Modal, notification } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API } from '../Utils/Request';
import useWindowDimensions from '../Utils/windowDimension';

export default function Registrazione() {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const { confirm } = Modal;
    const [loading, setLoading] = useState(false);
    const { height } = useWindowDimensions();

    const labelProps = { style: { color: "#fff", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const inputStyles = { input: { backgroundColor: "#fff", border: "none" }, error: { color: "#fff" } };

    const form = useForm({
        initialValues: {
            nome: '',
            cognome: '',
            codiceFiscale: '',
            cellulare: '',
            instagram: '',
            email: '',
            password: '',
            terms: true,
            noCF: false,
            nrPassaporto: '',
        },

        validate: {
            nome: (val) => (typeof val === 'undefined' || val.length <= 0 ? 'Inserisci nome' : null),
            cognome: (val) => (typeof val === 'undefined' || val.length <= 0 ? 'Inserisci cognome' : null),
            codiceFiscale: (val) => (!form.values.noCF && (typeof val === 'undefined' || val.length < 16) ? 'Inserisci codice fiscale' : null),
            nrPassaporto: (val) => (form.values.noCF && (typeof val === 'undefined' || val.length <= 0) ? 'Inserisci il nr. del passaporto' : null),
            cellulare: (val) => (typeof val === 'undefined' || val.length < 10 ? 'Inserisci un cellulare valido' : null),
            email: (val) => (typeof val === 'undefined' || /^\S+@\S+$/.test(val) ? null : 'Email non valida'),
            password: (val) => (typeof val === 'undefined' || val.length < 6 ? 'La password deve contenere minimo 6 caratteri' : null),
            instagram: null
        },
    });

    const submit = (values) => {
        if (loading)
            return;

        setLoading(true);

        const url = API.register;
        axios.post(url, values)
            .then(() => {
                setLoading(false);
                notification.success({
                    placement: "top",
                    message: 'Registrazione effettuata',
                    description:
                        'Registrazione effettuata con successo',
                });
                navigate('/login');
            })
            .catch(err => {
                setLoading(false);
                if (err.response) {
                    if (err.response.status === 400) {
                        form.validateField(err.reponse.data);
                        return;
                    }
                    if (err && err.response && err.response.status === 401) {
                        if (err.response.data === 'codiceFiscaleInvalid') {
                            const msg = "Codice Fiscale non valido!";
                            form.setFieldError('codiceFiscale', msg);
                            Modal.error({
                                title: "C'è stato un errore!",
                                content: msg,
                            });
                        }
                        else if (err.response.data === 'codiceFiscaleNotAge') {
                            const msg = "Devi avere minimo 18 anni per registrarti!";
                            form.setFieldError('codiceFiscale', msg);
                            Modal.error({
                                title: "C'è stato un errore!",
                                content: msg,
                            });

                        }
                        else {
                            const msg = "associato già ad un altro utente!";
                            form.setFieldError(err.response.data, msg);
                            console.log(form)
                            Modal.error({
                                title: "C'è stato un errore",
                                content: "Un campo è già "+msg,
                            });
                        }

                        return;
                    }
                } else {
                    notification.error({
                        placement: "top",
                        message: 'Errore interno',
                        description:
                            'Errore imprevisto, riprova',
                    });
                    return;
                }
            })
    }

    const openPrivacyPolicy = () => {
        confirm({
            title: <center style={{color:"rgb(160, 12, 152)",fontFamily:"Poppins",fontWeight:600}}>Privacy Policy</center>,
            icon: null,
            content: <div>
                <p>Gentile Utente,</p>
                <p>privacy è importante per noi. Questo contratto fornisce informazioni sul tipo di informazioni che raccolgiamo, come le utilizziamo e come le proteggiamo.</p>
                <p>Quali informazioni raccogliamo?</p>
                <p>Raccogliamo le informazioni che ci fornisci in modo attivo, ad esempio quando ti registri al nostro sito web o ci invii una richiesta tramite il nostro modulo di contatto.</p>
                <p>Le informazioni che raccogliamo possono includere nome, indirizzo email, indirizzo IP, informazioni sul browser e sul dispositivo, informazioni sull'attività sul sito e altro ancora.</p>
                <p>Come utilizziamo le informazioni?</p>
                <p>Utilizziamo le informazioni raccolte per fornirti i servizi richiesti, migliorare il nostro sito web e personalizzare esperienza di utilizzo. Inoltre, possiamo utilizzare le informazioni per comunicare con te su novità, offerte speciali e altre informazioni di tuo interesse.</p>
                <p>Come proteggiamo le informazioni?</p>
                <p>Prendiamo la privacy e la sicurezza dei dati degli utenti seriamente e utilizziamo misure di sicurezza appropriate per proteggere le informazioni raccolte. Tuttavia, non possiamo garantire la sicurezza delle informazioni che invii tramite il sito web, quindi ti invitiamo a utilizzare discrezione quando ci fornisci informazioni personali.</p>
                <p>Diritti degli utenti</p>
                <p>Gli utenti hanno il diritto di richiedere la modifica o la cancellazione dei propri dati personali. Inoltre, gli utenti hanno il diritto di richiedere l'accesso alle informazioni personali raccolte e di chiedere la limitazione del trattamento delle informazioni.</p>
                <p>Modifiche al contratto per la privacy</p>
                <p>Ci riserviamo il diritto di modificare questo contratto per la privacy in qualsiasi momento. Le modifiche entreranno in vigore immediatamente dopo la pubblicazione della versione aggiornata sul sito web.</p>
                <p>Contattaci</p>
                <p>Se hai domande o commenti sulla nostra politica sulla privacy, ti preghiamo di contattarci tramite il nostro modulo di contatto.</p>
                <p>Grazie per aver visitato il nostro sito web e per aver letto il nostro contratto per la privacy.</p>
                <p>Cordiali saluti,</p>
                <p>Il team di Candizioland</p>

                <div style={{ color: "#1c7ed6", cursor: "pointer", userSelect: "none", paddingBottom: 20 }} onClick={() => openProfilazione()}>Contratto per la divulgazione dei dati personali</div>
            </div>,
            okText: 'Accetta',
            okType: 'primary',
            cancelText: 'Esci',
            onOk() {
                form.setFieldValue('terms', true);
            },
            onCancel() {
            },
        });
    }

    const openProfilazione = () => {
        confirm({
            title: <center style={{color:"rgb(160, 12, 152)",fontFamily:"Poppins",fontWeight:600}}>Dati Personali</center>,
            icon: null,
            content: <div>
                <p>Acconsento alla vendita dei miei dati personali, a terze parti selezionate e autorizzate dal Titolare del Trattamento dei dati.</p>
                <p>Comprendo che la vendita dei miei dati personali può includere informazioni come il mio nome, indirizzo email, indirizzo IP, informazioni sul browser e sul dispositivo, informazioni sull'attività sul sito e altro ancora.</p>
                <p>Comprendo inoltre che la vendita dei miei dati personali potrebbe consentire a terze parti di inviarmi comunicazioni di marketing personalizzate e di profilare le mie attività sul sito web.</p>
                <p>Certifico che ho letto e compreso la politica sulla privacy di Candizioland e che ho ricevuto informazioni sulla vendita dei miei dati personali a terze parti.</p>
            </div>,
            okText: 'Accetta',
            okType: 'primary',
            cancelText: 'Esci',
            onOk() {
                form.setFieldValue('terms', true);
            },
            onCancel() {
            },
        });
    }

    useEffect(() => {
        if (token && token !== null && token.trim().length > 0) {
            navigate('/homepage')
        }
    }, [])


    return (
        <div style={{
            paddingTop: 40, paddingBottom: 40, width: "100%",
            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
        }}>
            <center>
                <img
                    draggable={false}
                    src={process.env.PUBLIC_URL+"/logo.png"} alt="logo" style={{ maxWidth: 50, height: "auto", userSelect: "none" }} />
            </center>

            <div style={{
                display: "flex", alignItems: "center", justifyContent: "center", height: height-240,
                width: "100%", minWidth: 250, overflowY: "auto", marginTop: 80, paddingLeft: 25, paddingRight: 25,
            }}>
                <form onSubmit={form.onSubmit((values) => submit(values))} style={{ width: "100%", height: "100%", maxWidth: 300 }}>
                    <Stack>
                        <TextInput
                            required
                            label="Nome"
                            placeholder="Nome"
                            value={form.values.nome}
                            onChange={(event) => form.setFieldValue('nome', event.currentTarget.value)}
                            error={form.errors.nome}
                            labelProps={labelProps}
                            radius="lg"
                            size='xl'
                            styles={inputStyles} />

                        <TextInput
                            required
                            label="Cognome"
                            placeholder="Cognome"
                            value={form.values.cognome}
                            onChange={(event) => form.setFieldValue('cognome', event.currentTarget.value)}
                            error={form.errors.cognome}
                            labelProps={labelProps}
                            radius="lg"
                            size='xl'
                            styles={inputStyles} />

                        {form.values.noCF ? <TextInput
                            required
                            label="Nr. Passaporto"
                            placeholder="Nr Passaporto"
                            value={form.values.nrPassaporto}
                            onChange={(event) => form.setFieldValue('nrPassaporto', event.currentTarget.value.toUpperCase())}
                            error={form.errors.nrPassaporto}
                            labelProps={labelProps}
                            radius="lg"
                            size='xl'
                            styles={inputStyles} />
                            : <TextInput
                                required
                                label="Codice Fiscale"
                                placeholder="Codice Fiscale"
                                value={form.values.codiceFiscale}
                                onChange={(event) => form.setFieldValue('codiceFiscale', event.currentTarget.value.toUpperCase())}
                                error={form.errors.codiceFiscale}
                                labelProps={labelProps}
                                radius="lg"
                                size='xl'
                                styles={inputStyles} />}

                        <Checkbox
                            color='pink'
                            label={<b style={{ color: "#fff" }}>
                                Non ho un codice fiscale
                            </b>}
                            checked={form.values.noCF}
                            onChange={(event) => form.setFieldValue('noCF', event.currentTarget.checked)}
                        />

                        <TextInput
                            required
                            label="Cellulare"
                            placeholder="Cellulare"
                            value={form.values.cellulare}
                            type='tel'
                            error={form.errors.cellulare}
                            onChange={(event) => form.setFieldValue('cellulare', event.currentTarget.value)}
                            labelProps={labelProps}
                            radius="lg"
                            size='xl'
                            styles={inputStyles} />

                        <TextInput
                            label="Instagram"
                            placeholder="Instagram"
                            value={form.values.instagram}
                            error={form.errors.instagram}
                            onChange={(event) => form.setFieldValue('instagram', event.currentTarget.value)}
                            labelProps={labelProps}
                            radius="lg"
                            size='xl'
                            styles={inputStyles} />

                        <TextInput
                            required
                            id="email"
                            name="email"
                            label="Email"
                            placeholder="Email"
                            value={form.values.email}
                            onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                            error={form.errors.email}
                            type="email"
                            labelProps={labelProps}
                            radius="lg"
                            size='xl'
                            styles={inputStyles} />

                        <PasswordInput
                            required
                            id="password"
                            name="password"
                            label={"Password"}
                            placeholder={"Password"}
                            value={form.values.password}
                            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                            error={form.errors.password}
                            labelProps={labelProps}
                            radius="lg"
                            size='xl'
                            styles={inputStyles} />

                        <Checkbox
                            mt={20}
                            required
                            color='pink'
                            size="md"
                            label={<div style={{ fontWeight: 600, color: "#fff" }}>
                                <span>Accetto i</span>
                                <Anchor
                                    weight={600}
                                    ml={5}
                                    component="button"
                                    type="button"
                                    onClick={() => openPrivacyPolicy()}>
                                    termini e le condizioni
                                </Anchor>

                            </div>}
                            checked={form.values.terms}
                            onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)}
                        />

                    </Stack>

                    <Group position="center" mt={40}>
                        <Button loading={loading} type="primary" htmlType='submit' shape='round' style={{ width: "100%", fontWeight: 600 }} size="large">
                            Registrati
                        </Button>
                    </Group>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 5, paddingBottom: 40, marginTop: 20 }}>
                        <Text
                            size="md"
                            weight={600}
                            color="#fff">
                            Hai già un'account?
                        </Text>

                        <Anchor
                            component="button"
                            type="button"
                            weight={600}
                            onClick={() => navigate('/login')}
                            size="md">
                            Accedi!
                        </Anchor>
                    </div>
                </form>
            </div>
        </div>
    );
}