/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { IconBell, IconPlus } from "@tabler/icons-react";
import { Badge } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { downloadEventi, downloadGruppiByOwner, downloadPrenotazioni, downloadRole, getNotificheCount } from "../Utils/ApiUtils";
import { getPrenotazioneByEventId } from "../Utils/FunctionUtils";
import { useTokenActions } from "../Utils/tokenUtils";
import useWindowDimensions from '../Utils/windowDimension';
import Biglietto from './Components/eventi/Biglietto';
import EventCard from "./Components/eventi/EventCard";
import ErrorPage from "./Components/misc/ErrorPage";

const Eventi = () => {
    const navigate = useNavigate();
    const { handleSetRole, handleRemoveRole, handleRemoveToken } = useTokenActions();

    const token = useSelector(state => state.token);
    const isAdmin = useSelector(state => state.role);

    const [prenotazioni, setPrenotazioni] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [eventi, setEventi] = useState([]);
    const [biglietto, setBiglietto] = useState(null);
    const [notificheCount, setNotificheCount] = useState(0);
    const [gruppi, setGruppi] = useState(null);

    const { height } = useWindowDimensions();

    useEffect(() => {
        downloadEventi(loading, setLoading, setError, setEventi,navigate,handleRemoveToken);
        if (token && token !== null) {
            downloadRole(handleSetRole, handleRemoveRole, navigate);
            downloadPrenotazioni(setLoading, setError, setPrenotazioni, handleRemoveToken, navigate);
            downloadGruppiByOwner(null, setGruppi, handleRemoveToken, navigate);
            getNotificheCount(setNotificheCount);
        }

        const interval = setInterval(() => {
            downloadEventi(loading, setLoading, setError, setEventi,navigate,handleRemoveToken);
            if (token && token !== null) {
                downloadRole(handleSetRole, handleRemoveRole, navigate);
                downloadPrenotazioni(setLoading, setError, setPrenotazioni, handleRemoveToken, navigate);
                downloadGruppiByOwner(null, setGruppi, handleRemoveToken, navigate);
                getNotificheCount(setNotificheCount);
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset", overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>
                    <LoadingOverlay visible={loading} />

                    <div style={{ marginBottom: 15, userSelect: "none" }}>
                        {isAdmin &&
                            <div style={{
                                position: "absolute", left: 15, top: 25,
                                cursor: "pointer", userSelect: "none",
                                backgroundColor: "rgba(255,255,255,0.5)", aspectRatio: 1,
                                borderRadius: 8, display: "flex", justifyContent: "center", alignItems: "center", padding: 5
                            }} onClick={() => navigate("/evento/new")}>
                                <IconPlus color="rgba(131, 56, 236, 1)" stroke={3} />
                            </div>}
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                        {token && token !== null &&
                            <div style={{ position: "absolute", right: 15, top: 25 }}>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell size={35} color="transparent" fill="#fff" style={{ cursor: "pointer", userSelect: "none" }} onClick={() => navigate("/notifiche")} />
                                </Badge>
                            </div>}

                        <div>
                        </div>
                    </div>

                    <div style={{
                        userSelect: "none", height: height - 150, overflowY: "auto",
                        display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start"
                    }}>
                        {eventi && eventi.length > 0 && eventi.map((item, index) =>
                            <div key={index} style={{ marginBottom: index === eventi.length - 1 ? 20 : 40,minWidth:300 }}>
                                <EventCard gruppi={gruppi} isAdmin={isAdmin} prenotazione={getPrenotazioneByEventId(prenotazioni, item.id)}
                                    item={item} setLoading={setLoading} setError={setError} setEventi={setEventi}
                                    handleRemoveToken={handleRemoveToken} navigate={navigate} token={token} loading={loading} setBiglietto={setBiglietto}
                                    setPrenotazioni={setPrenotazioni} />
                            </div>)}
                    </div>

                    <Biglietto biglietto={biglietto} setBiglietto={setBiglietto}
                        loading={loading} setLoading={setLoading} setError={setError} setEventi={setEventi}
                        setPrenotazioni={setPrenotazioni} handleRemoveToken={handleRemoveToken} navigate={navigate}
                    />
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    downloadEventi(loading, setLoading, setError, setEventi,navigate,handleRemoveToken);
                    if (token && token !== null) {
                        downloadRole(handleSetRole, handleRemoveRole, navigate);
                        downloadPrenotazioni(setLoading, setError, setPrenotazioni, handleRemoveToken, navigate);
                        downloadGruppiByOwner(null, setGruppi, handleRemoveToken, navigate);
                        getNotificheCount(setNotificheCount);
                    }
                }} />}
        </div>
    )
}
export default Eventi;