/* eslint-disable react-hooks/exhaustive-deps */
import {
    Anchor,
    Group,
    PasswordInput,
    Stack,
    Text,
    TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Button, notification } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API } from '../Utils/Request';

export default function RecuperaCredenziali() {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const [loading, setLoading] = useState(false);
    const [timeoutCode, setTimeoutCode] = useState(0)

    const labelProps = { style: { color: "#fff", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "none", backgroundColor: "#fff" } , error: { color: "#fff" } };

    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },

        validate: {
            codice: (val) => (typeof val === 'undefined' || val.length <= 0 ? "Codice non valido" : null),
            email: (val) => (typeof val === 'undefined' || /^\S+@\S+$/.test(val) ? null : 'Email non valida'),
            password: (val) => (typeof val === 'undefined' || val.length < 6 ? 'La password deve contenere minimo 6 caratteri' : null),

        },
    });

    const submit = values => {
        if (loading)
            return;

        setLoading(true);

        const url = API.recovery;
        axios.post(url, values)
            .then(() => {
                setLoading(false);
                notification.success({
                    placement: "top",
                    message: 'Password cambiata',
                    description:
                        'Password cambiata con successo',
                });
                navigate('/login')
            })
            .catch(err => {
                setLoading(false);
                if (err.response) {
                    if (err.response.status === 400) {
                        form.validateField(err.reponse.data);
                        return;
                    }
                    if (err && err.response && err.response.status === 401) {
                        const msg = err.response.data === 'email' ? "Email non presente nel sistema" : "Codice scaduto o non valido"
                        form.setFieldError(err.response.data, msg);
                        return;
                    }
                } else {
                    notification.error({
                        placement: "top",
                        message: 'Errore interno',
                        description:
                            'Errore imprevisto, riprova',
                    });
                    return;
                }
            })
    }

    const sendRecoveryCode = () => {
        form.validateField('email');
        if (typeof form.values.email === 'undefined' || form.values.email === null || form.values.email.trim().length <= 0) {
            return
        }

        const url = API.recovery + '?email=' + form.values.email
        axios.get(url)
            .then(() => {
                setTimeoutCode(200);
                setLoading(false);
                notification.success({
                    placement: "top",
                    message: 'Codice Inviato',
                    description:
                        'Abbiamo inviato un codice di recupero sulla tua email',
                });
                form.clearErrors();
            })
            .catch(err => {
                setLoading(false);
                if (err.response) {
                    if (err && err.response && err.response.status === 401) {
                        const msg = err.response.data === 'codice' ? "Attendi prima di generare un'altro codice" : "Email non presente nel sistema"
                        form.setFieldError(err.response.data, msg)
                        return;
                    }
                }
                notification.error({
                    placement: "top",
                    message: 'Errore interno',
                    description:
                        'Errore imprevisto, riprova',
                });
            })
    }

    useEffect(() => {
        if (timeoutCode > 0) {
            const intervalId = setInterval(() => {
                setTimeoutCode(prevTimeoutCode => prevTimeoutCode - 1);
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [timeoutCode]);

    useEffect(() => {
        if (token && token !== null && token.trim().length > 0) {
            navigate('/homepage')
        }
    }, [])


    return (
        <div style={{
            paddingTop: 40, paddingBottom: 40, width: "100%",
            paddingLeft: 25, paddingRight: 25, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
        }}>
            <center>
                <img
                    draggable={false}
                    src={process.env.PUBLIC_URL+"/logo.png"} alt="logo" style={{ maxWidth: 50, height: "auto", userSelect: "none" }} />
            </center>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: 300, minWidth: 250,marginTop:30 }}>
                <form onSubmit={form.onSubmit((values) => submit(values))} style={{ width: "100%" }}>
                    <Stack>
                        <TextInput
                            required
                            id="email"
                            name="email"
                            label="Email"
                            placeholder="La tua email"
                            value={form.values.email}
                            onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                            error={form.errors.email}
                            type="email"
                            radius="lg"
                            size='xl'
                            labelProps={labelProps}
                            styles={labelStyles} />

                        <Group position="center" mt="sm">
                            <Button
                                style={{ maxWidth: 220,fontWeight:600}}
                                size='large'
                                type="primary" loading={timeoutCode > 0} onClick={() => sendRecoveryCode()} shape="round">
                                {timeoutCode > 0 ? timeoutCode : "Invia codice di recupero"}
                            </Button>
                        </Group>

                        <TextInput
                            style={{ flex: 0.8 }}
                            required
                            label="Codice"
                            placeholder="Codice di recupero"
                            value={form.values.codice}
                            onChange={(event) => form.setFieldValue('codice', event.currentTarget.value)}
                            error={form.errors.codice}
                            radius="lg"
                            size='xl'
                            labelProps={labelProps}
                            styles={labelStyles} />

                        <PasswordInput
                            required
                            id="password"
                            name="password"
                            label="Nuova Password"
                            placeholder="La Tua Nuova Password"
                            value={form.values.password}
                            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                            error={form.errors.password}
                            radius="lg"
                            size='xl'
                            labelProps={labelProps}
                            styles={labelStyles} />

                    </Stack>

                    <Group position="center" mt="xl">
                        <Button size='large' style={{ width: "100%",fontWeight:600 }}
                            loading={loading} type="primary" htmlType="submit" shape="round">
                            Cambia Password
                        </Button>
                    </Group>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 5,marginTop:25 }}>
                        <Text
                            size="md"
                            weight={600}
                            color="#fff">
                            Ricordi le credenziali?
                        </Text>
                        <Anchor
                            component="button"
                            type="button"
                            onClick={() => navigate('/login')}
                            size="md"
                            weight={600}>
                            Accedi!
                        </Anchor>
                    </div>
                </form>
            </div>
        </div>
    );
}