/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay, Slider } from '@mantine/core';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { cambiaCapienza, downloadCapienza } from '../../../Utils/ApiUtils';

function ModificaCapienza(props) {
    const { visible, setVisible, loading, setLoading, handleRemoveToken, navigate, setError, setEventi } = props;

    const [loadingCapienza, setLoadingCapienza] = useState(false);
    const [capienza, setCapienza] = useState(0);

    const handleSliderChange = (value) => {
        setCapienza(value);
    };

    useEffect(() => {
        if(visible !== null){
            downloadCapienza(setLoadingCapienza, visible.id, setCapienza, handleRemoveToken, navigate, setVisible)
        }
    }, [visible])
    

    return (
        <Modal
            open={visible}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => setVisible(null)}
            title={<center style={{ color: "rgb(160, 12, 152)", fontFamily: "Poppins", fontWeight: 600, marginBottom: 20 }}>
                Modifica Capienza
            </center>}
            okText="Modifica"
            cancelText="Annulla"
            onOk={() => cambiaCapienza(visible.id, capienza, setLoadingCapienza, loading, setLoading,
                handleRemoveToken, navigate, setError, setEventi, setVisible)}>
            <div style={{paddingBottom:60,paddingTop:30}}>
                <LoadingOverlay visible={loadingCapienza} />
                <Slider
                    defaultValue={capienza ? capienza : 0}
                    value={capienza ? capienza : 0}
                    min={0}
                    max={100}
                    step={1}
                    onChange={handleSliderChange}
                    marks={[
                        { value: 0, label: '0%' },
                        { value: 20, label: '20%' },
                        { value: 50, label: '50%' },
                        { value: 80, label: '80%' },
                        { value: 100, label: '100%' },
                    ]}
                />
            </div>
        </Modal>
    );
}

export default ModificaCapienza;
