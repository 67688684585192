import { LoadingOverlay, PasswordInput, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button, Drawer } from "antd";
import React from "react";
import { changeEmail } from "../../../Utils/ApiUtils";

const CambiaEmail = props => {
    const { email, setEmail, loading, setLoading, handleRemoveToken, navigate, setError } = props;

    const labelProps = { style: { color: "rgb(160, 12, 152)", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "none", color: "#fb5607", backgroundColor: "#fff" }, error: { color: "#fff", fontFamily: "Poppins", fontSize: 12 } };

    const emailForm = useForm({
        initialValues: {
            email: "",
            newEmail: "",
            confirmEmail: "",
            password: "",
        },
        validate: {
            email: (val) => (typeof val === 'undefined' || val === null || val.length <= 0 ? "Inserisci l'indirizzo email" : null),
            newEmail: (val) => (typeof val === 'undefined' || val === null || val.length <= 0 ? "Inserisci il nuovo indirizzo email" : null),
            confirmEmail: val => val !== emailForm.values.newEmail ? "Le email non corrispondono" : null,
            password: (val) => (typeof val === 'undefined' || val === null || val.length <= 0 ? 'Inserisci la password' : null),
        }
    })

    return (
        <Drawer
            open={email}
            onClose={() => setEmail(false)}
            placement="bottom" height={500}
            style={{ backgroundColor: "#fb5607", borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            maskStyle={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            bodyStyle={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            rootStyle={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            drawerStyle={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            contentWrapperStyle={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            headerStyle={{ display: "none" }}>
            <div style={{ marginTop: 15, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start" }}>
                <LoadingOverlay visible={loading} />
                <div
                    style={{ color: "#fff", fontFamily: "Poppins", fontWeight: 600, textAlign: "center", fontSize: 16, marginBottom: 20 }}>
                    Cambio Email
                </div>
                <form
                    style={{ maxWidth: 400, width: "100%", paddingBottom: 10 }}
                    onSubmit={emailForm.onSubmit((values) =>
                        changeEmail(values, setLoading, emailForm, handleRemoveToken, navigate, setError))}>
                    <Stack>
                        <TextInput
                            required
                            placeholder="Email attuale"
                            type="email"
                            value={emailForm.values.email}
                            error={emailForm.errors.email}
                            onChange={(event) => emailForm.setFieldValue("email", event.target.value)}
                            radius="md"
                            size='xl'
                            labelProps={labelProps}
                            styles={labelStyles} />

                        <TextInput
                            required
                            placeholder="Nuova email"
                            type="email"
                            value={emailForm.values.newEmail}
                            error={emailForm.errors.newEmail}
                            onChange={(event) => emailForm.setFieldValue("newEmail", event.target.value)}
                            radius="md"
                            size='xl'
                            labelProps={labelProps}
                            styles={labelStyles} />

                        <TextInput
                            required
                            placeholder="Conferma email"
                            type="email"
                            value={emailForm.values.confirmEmail}
                            error={emailForm.errors.confirmEmail}
                            onChange={(event) => emailForm.setFieldValue("confirmEmail", event.target.value)}
                            radius="md"
                            size='xl'
                            labelProps={labelProps}
                            styles={labelStyles} />

                        <PasswordInput
                            required
                            placeholder={"La tua password"}
                            value={emailForm.values.password}
                            error={emailForm.errors.password}
                            onChange={(event) => emailForm.setFieldValue('password', event.currentTarget.value)}
                            radius="md"
                            size='xl'
                            labelProps={labelProps}
                            styles={labelStyles}
                            style={{ marginTop: 20 }} />
                    </Stack>
                    <div style={{ textAlign: "center", marginTop: 20 }}>
                        <Button
                            loading={loading}
                            type="primary"
                            className="btn_secondary"
                            size="large"
                            htmlType="submit"
                            style={{ marginTop: 10 }}>
                            Cambia Email
                        </Button>
                    </div>
                </form>
            </div>

        </Drawer>

    )
}
export default CambiaEmail;