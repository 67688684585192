/* eslint-disable react-hooks/exhaustive-deps */
import {
    Anchor,
    PasswordInput,
    Stack,
    Text,
    TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { Button, Modal, notification } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API } from '../Utils/Request';
import { useTokenActions } from '../Utils/tokenUtils';
import useWindowDimensions from '../Utils/windowDimension';

export default function Login() {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const { handleSetToken, handleSetRole } = useTokenActions();
    const { confirm } = Modal;
    const [loading, setLoading] = useState(false);
    const { height } = useWindowDimensions();
    const labelProps = { style: { color: "#fff", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "3px solid #fb5607", backgroundColor: "#fbe0b9" }, error: { color: "#fff" } };

    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },

        validate: {
            email: (val) => (typeof val === 'undefined' || /^\S+@\S+$/.test(val) ? null : 'Email non valida'),
            password: (val) => (typeof val === 'undefined' || val.length < 6 ? 'La password deve contenere minimo 6 caratteri' : null),
        },
    });

    const submit = (values) => {
        if (loading)
            return;

        setLoading(true);

        const url = API.login;
        axios.post(url, values)
            .then(res => {
                setLoading(false);
                handleSetToken(res.data.token);
                handleSetRole(res.data.admin);
                notification.success({
                    placement: "top",
                    message: 'Accesso effettuato',
                    description:
                        'Accesso effettuato con successo',
                });
                if (res.data.controllore) {
                    navigate('/check-qr/verifica');
                } else navigate('/homepage');
            })
            .catch(err => {
                setLoading(false);
                if (err.response) {
                    if (err && err.response && err.response.status === 401) {
                        if (err.response.data === 'email')
                            form.setFieldError(err.response.data, 'Email non presente nel sistema')
                        return;
                    }
                    if (err.response.status === 403) {
                        form.setFieldError("password", 'Password errata')
                        return;
                    }
                    if (err.response.status === 451) {
                        confirm({
                            title: <center style={{ color: "rgb(160, 12, 152)", fontFamily: "Poppins", fontWeight: 600 }}>Il tuo profilo è stato bannato</center>,
                            icon: null,
                            content: <center style={{ fontFamily: "Poppins", fontWeight: 400, marginTop: 20, marginBottom: 20 }}>
                                Questo profilo è stato bannato,contatta il supporto per maggiori informazioni
                            </center>,
                            okText: 'Contattaci',
                            okType: 'primary',
                            cancelText: 'Annulla',
                            onOk() {
                                navigate('/contatti')
                            },
                            onCancel() {
                            },
                        });
                        return;
                    }
                }
                notification.error({
                    placement: "top",
                    message: 'Errore interno',
                    description:
                        'Errore imprevisto, riprova',
                });
                return;
            })
    }

    useEffect(() => {
        if (token && token !== null && token.trim().length > 0) {
            navigate('/homepage')
        }
    }, [])


    return (
        <div style={{
            paddingTop: 40, paddingBottom: 40, width: "100%",
            paddingLeft: 25, paddingRight: 25, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
        }}>
            <center>
                <img
                    draggable={false}
                    src={process.env.PUBLIC_URL+"/logo.png"} alt="logo" style={{ maxWidth: 50, height: "auto", userSelect: "none" }} />
            </center>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: height-250, width: "100%", maxWidth: 300, minWidth: 250 }}>
                <form onSubmit={form.onSubmit((values) => submit(values))} style={{ width: "100%" }}>
                    <Stack>
                        <TextInput
                            required
                            id="email"
                            name="email"
                            label="Email"
                            placeholder="La tua email"
                            value={form.values.email}
                            onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                            error={form.errors.email}
                            type="email"
                            radius="xl"
                            size='xl'
                            labelProps={labelProps}
                            styles={labelStyles} />

                        <PasswordInput
                            required
                            id="password"
                            name="password"
                            labelProps={labelProps}
                            type="password"
                            label="Password"
                            placeholder="La tua password"
                            value={form.values.password}
                            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                            error={form.errors.password}
                            radius="xl"
                            size='xl'
                            styles={labelStyles} />
                    </Stack>
                    <div style={{ textAlign: "right", width: "100%" }}>
                        <Button
                            style={{ padding: 0, margin: 0, color: "#fff", fontSize: 16, marginTop: 5 }}
                            type="link"
                            onClick={() => navigate('/recupera-credenziali')}
                            size="small">
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                Recupera le tue credenziali <IconArrowNarrowRight />
                            </div>
                        </Button>
                    </div>

                    <Button type="primary" loading={loading} htmlType="submit" shape="round" size='large' style={{ marginTop: 25, fontWeight: 600, width: "100%" }}>
                        Accedi
                    </Button>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 5, fontWeight: 600, marginTop: 30 }}>
                        <Text
                            size="md"
                            color="#fff">
                            Non hai un'account?
                        </Text>

                        <Anchor
                            component="button"
                            type="button"
                            onClick={() => {
                                navigate('/registrazione')
                                form.clearErrors();
                            }}
                            size="md"
                            weight={600}>
                            Registrati!
                        </Anchor>
                    </div>

                </form>
            </div>
        </div>
    );
}