/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconPlus } from "@tabler/icons-react";
import { Button, Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { savePartner } from "../../../Utils/ApiUtils";
import { getImage } from "../../../Utils/FunctionUtils";

const AggiungiPartner = props => {
    const { visible, setVisible, handleRemoveToken, navigate, setLoading, setPartners, setError, token } = props;
    const labelProps = { style: { color: "#fb5607", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "3px solid #fb5607", backgroundColor: "#fff" }, error: { color: "#fb5607", fontFamily: "Poppins", fontSize: 12 } };

    const [loadingCrea, setLoadingCrea] = useState(false);
    const [file, setFile] = useState(null);
    const handleChange = ({ fileList: newFile }) => setFile(newFile[0]);

    const form = useForm({
        initialValues: {
            nome: '',
            link: '',
        },
        validate: {
            nome: (val) => (typeof val === 'undefined' || val.trim().length <= 0 ? "Nome non valido" : null),
        }
    });

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    useEffect(() => {
        if (visible !== null) {
            form.setValues(visible);
            if (visible.img && visible.img !== null) {
                setFile({
                    uid: 1,
                    name: visible.img,
                    fromEdit: true,
                    status: 'done',
                    url: getImage(visible.img),
                });
            }
        } else {
            form.reset();
            setFile(null);
        }
    }, [visible])


    return (
        <Modal
            open={visible !== null}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => setVisible(null)}
            title={<center style={{ color: "rgb(160, 12, 152)", fontFamily: "Poppins", fontWeight: 600, marginBottom: 20 }}>
                {visible && visible !== null && visible.id !== null ? "Modifica partner" : "Aggiungi partner"}
            </center>}
            footer={null}>
            <>
                <LoadingOverlay visible={loadingCrea} />
                <form style={{ marginBottom: 5 }}
                    onSubmit={form.onSubmit((values) => savePartner(visible.id, values, setLoading, setLoadingCrea, file, token, setVisible, setPartners, setError, handleRemoveToken, navigate))}>
                    <TextInput
                        required
                        label="Nome partner"
                        placeholder="Nome partner"
                        value={form.values.nome}
                        onChange={(event) => form.setFieldValue('nome', event.currentTarget.value)}
                        error={form.errors.nome}
                        radius="lg"
                        size='lg'
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <TextInput
                        label="Link sito web"
                        placeholder="Link sito web partner"
                        value={form.values.link}
                        onChange={(event) => form.setFieldValue('link', event.currentTarget.value)}
                        radius="lg"
                        size='lg'
                        style={{ marginTop: 10 }}
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <div style={{ marginTop: 20 }}>
                        <div style={{ fontWeight: 600, fontFamily: "Poppins", color: "rgb(251, 86, 7)", fontSize: 14, marginBottom: 5 }}>
                            Immagine*
                        </div>
                        <Upload
                            onRemove={() => {
                                setFile(null);
                                setVisible({...visible,img:null});
                            }}
                            accept="image/*"
                            listType="picture-card"
                            fileList={file && file !== null ? [file] : []}
                            multiple={false}
                            className="upload-edit"
                            onChange={handleChange}
                            customRequest={dummyRequest}>
                            {file && file !== null ? null : <div>
                                <IconPlus color="#fb5607" stroke={4} />
                            </div>}
                        </Upload>
                    </div>

                    <div style={{ marginTop: 25, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                        <Button onClick={() => {
                            form.reset();
                            setVisible(null);
                        }}
                            style={{ fontWeight: 600, fontFamily: "Poppins" }}>
                            Annulla
                        </Button>
                        <Button type="primary" shape="round" loading={loadingCrea} style={{ fontWeight: 600, fontFamily: "Poppins" }}
                            htmlType="submit">
                            {visible && visible !== null && visible.id !== null ? "Modifica partner" : "Aggiungi partner"}
                        </Button>
                    </div>
                </form>
            </>
        </Modal>
    )
}
export default AggiungiPartner;