/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from '@mantine/core';
import { IconBell, IconChevronLeft } from '@tabler/icons-react';
import { Badge, Statistic, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadStatistiche, getNotificheCount } from '../Utils/ApiUtils';
import { useTokenActions } from '../Utils/tokenUtils';
import ErrorPage from './Components/misc/ErrorPage';
import useWindowDimensions from '../Utils/windowDimension';

function Statistiche() {
    const token = useSelector(state => state.token);
    const { handleRemoveToken } = useTokenActions();
    const { id } = useParams();
    const isGenerali = id === 'generali';
    const navigate = useNavigate();
    const { height } = useWindowDimensions();

    const [statistiche, setStatistiche] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);


    useEffect(() => {
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
            downloadStatistiche(id, setLoading, isGenerali, setStatistiche, handleRemoveToken, navigate, setError);
        } else {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }

        const interval = setInterval(() => {
            getNotificheCount(setNotificheCount);
            downloadStatistiche(id, setLoading, isGenerali, setStatistiche, handleRemoveToken, navigate, setError);
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    function getRandomColor() {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset", overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflowY: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ color: "#fff", fontSize: 30, marginTop: 10, fontWeight: "bolder", marginBottom: 20 }}>
                                Statistiche {isGenerali ? "Generali" : "Evento"}
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', alignItems: "center", textAlign: "center",
                                 height: height - 280, overflowY: "auto",width:"100%" }}>
                                    <div style={{ borderRadius: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", width: "100%", maxWidth: 250 }}>
                                        <Statistic value={statistiche?.entrati}
                                            title={<div style={{ color: "rgba(251, 86, 7, 1)", fontFamily: "Poppins", fontWeight: 600 }}>
                                                {isGenerali ? "Ingressi Totali" : "Ingressi"}
                                            </div>}
                                            valueStyle={{ color: "rgba(131, 56, 236, 1)", fontWeight: 600 }}
                                        />
                                    </div>
                                    <div style={{ borderRadius: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", width: "100%", maxWidth: 250, minWidth: 200 }}>
                                        <Statistic value={statistiche?.prenotati}
                                            title={<div style={{ color: "rgba(251, 86, 7, 1)", fontFamily: "Poppins", fontWeight: 600 }}>
                                                {isGenerali ? "Prenotazioni Totali" : "Prenotazioni"}
                                            </div>}
                                            valueStyle={{ color: "rgba(131, 56, 236, 1)", fontWeight: 600 }}
                                        />
                                    </div>
                                    <div style={{ borderRadius: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", width: "100%", maxWidth: 250, minWidth: 200 }}>
                                        <Statistic value={statistiche?.attesa}
                                            title={<div style={{ color: "rgba(251, 86, 7, 1)", fontFamily: "Poppins", fontWeight: 600 }}>
                                                Prenotazioni in attesa
                                            </div>}
                                            valueStyle={{ color: "rgba(131, 56, 236, 1)", fontWeight: 600 }}
                                        />
                                    </div>
                                    <div style={{ borderRadius: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", width: "100%", maxWidth: 250, minWidth: 200 }}>
                                        <Statistic value={statistiche && statistiche.etaMedia ? statistiche.etaMedia : ""}
                                            title={<div style={{ color: "rgba(251, 86, 7, 1)", fontFamily: "Poppins", fontWeight: 600 }}>
                                                Eta Media</div>}
                                            suffix={statistiche?.etaMedia ? "Anni" : ""}
                                            valueStyle={{ color: "rgba(131, 56, 236, 1)", fontWeight: 600 }}
                                        />
                                    </div>
                                    {statistiche && statistiche.citta && Object.entries(statistiche.citta).length > 0 &&
                                        <div style={{ borderRadius: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", width: "100%", maxWidth: 400, userSelect: "none" }}>
                                            <div style={{ color: "rgba(251, 86, 7, 1)", fontFamily: "Poppins", fontWeight: 600, userSelect: "none" }}>
                                                Citta {isGenerali ? "degli utenti registrati" : "dei partecipanti"}</div>

                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', userSelect: "none",width:"100%" }}>
                                                <Chart
                                                    width={350}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={[['Citta', 'Percent'],
                                                    ...Object.entries(statistiche.citta)
                                                    ]}
                                                    options={{
                                                        backgroundColor: { fill: 'transparent' },
                                                        is3D: true,
                                                        slices: {
                                                            0: { color: getRandomColor() }, // Set color for slice with index 0 (Napoli)
                                                            1: { color: getRandomColor() }, // Set color for slice with index 1 (Milano)
                                                            2: { color: getRandomColor() },
                                                            3: { color: getRandomColor() },
                                                            4: { color: getRandomColor() },
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>}

                                    {statistiche && (statistiche.maschi > 0 || statistiche.femmine > 0) &&
                                        <div style={{ borderRadius: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", width: "100%", maxWidth: 400 }}>
                                            <div style={{ color: "rgba(251, 86, 7, 1)", fontFamily: "Poppins", fontWeight: 600 }}>
                                                Sesso {isGenerali ? "degli utenti registrati" : "dei partecipanti"}</div>

                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Chart
                                                    width={350}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={[['Gender', 'Percent'],
                                                    ['Maschi', statistiche.maschi],
                                                    ['Femmine', statistiche.femmine],
                                                    ]}
                                                    options={{
                                                        is3D: true,
                                                        backgroundColor: { fill: 'transparent' },
                                                        colors: ['#33adff', '#d06295'],
                                                    }}
                                                />
                                            </div>

                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <ErrorPage onClick={() => {
                    setError(false);
                    downloadStatistiche(id, setLoading, isGenerali, setStatistiche, handleRemoveToken, navigate, setError);
                }} />
            }
        </div>
    );
}


export default Statistiche;
