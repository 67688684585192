import { feUrl, url } from "./Costants";

export const API = {
    getHomepage: url + '/events/get-homepage',
    getEntrypageGIF: url + '/entrypage/get',
    saveEntrypageGIF: url + '/entrypage/save',

    getEventi: url + '/events/get',
    getEventiByDate: url + '/events/get-by-date',
    getEventiAllDate: url + '/events/get-all-date',
    getEventoPrenotabile: url + '/events/is-prenotabile',
    getEventoById: url + '/events/get-by-id',
    salvaEvento: url + '/events/save',
    bloccaEvento: url + '/events/prenotabile',
    cancellaEvento: url + '/events/delete',
    concludiEvento: url + '/events/concludi',
    cambiaCapienza: url + '/events/capienza',
    downloadCapienza: url + '/events/capienza',

    getUserPrenotato: url + '/booking/is-user-prenotato',
    getBiglietto: url + '/booking/biglietto',
    getPrenotazioni: url + '/booking/get-prenotazioni',
    getPrenotazioniByDate: url + '/booking/get-prenotazioni-by-date',
    getPrenotazioniAttuali: url + '/booking/get-prenotazioni-attuali',
    getPrenotazioniPassate: url + '/booking/get-prenotazioni-passate',
    getPrenotazioniAttesa: url + '/booking/get-prenotazioni-attesa',
    getListaPrenotatiByAdmin: url + '/booking/prenotati-by-admin',
    getListaPrenotabiliByAdmin: url + '/booking/not-prenotati-by-admin',
    getListaAttesaByAdmin: url + '/booking/attesa-by-admin',
    prenotaEvento: url + '/booking/prenota',
    disdiciEvento: url + '/booking/disdici',
    getBigliettoByAdmin: url + '/booking/biglietto-by-admin',
    rimuoviPrenotazioneByAdmin: url + '/booking/rimuovi-by-admin',
    approvaAttesaByAdmin: url + '/booking/approva-by-admin',
    prenotaEventoByAdmin: url + '/booking/prenota-by-admin',

    login: url + '/auth/login',
    register: url + '/auth/register',
    recovery: url + '/auth/recovery-password',

    getImage: url + '/storage/get-image',
    removeImage: url + '/storage/remove-image',

    getNotifiche: url + '/notifiche/get',
    getNotificheCount: url + '/notifiche/count',
    deleteNotifiche: url + '/notifiche/delete',
    notificaForwardUser: url + '/notifiche/send-push',

    getMenu: url + '/menu/get',

    getStaff: url+ '/staff/get',
    saveStaff: url+ '/staff/save',
    deleteStaff: url+ '/staff/delete',

    getAvvisi: url + '/avvisi/get',
    salvaAvvisi: url + '/avvisi/save',
    cancellaAvviso: url + '/avvisi/delete',

    getPartners: url + '/partners/get',
    savePartners: url + '/partners/save',
    deletePartner: url + '/partners/delete',

    getGruppi: url + '/groups/get',
    cancellaGruppo: url + '/groups/delete',
    esciGruppo: url + '/groups/exit',
    getGruppo: url + '/groups/get-by-id',
    espelliGruppo: url + '/groups/espelli',
    invitaGruppo: url + '/groups/invita',
    creaGruppo: url + '/groups/create',
    rispondiInvitoGruppo: url + '/groups/rispondi-invito',
    getGruppiSelect: url + '/groups/get-select',
    getGruppiByOwnerSelect: url + '/groups/get-by-owner-select',
    getPartecipantiGruppo: url + '/groups/get-partecipanti',
    prenotaUtenteGruppo: url + '/groups/prenota-utente',

    getRole: url + '/users/get-role',
    cambiaInformazioni: url + '/users/change-personal-info',
    cambiaEmail: url + '/users/change-email',
    cambiaPassword: url + '/users/change-password',
    getInfoPersonali: url + '/users/get-personal-info',
    getUtenti: url + '/users/get',
    bannaUser: url + '/users/ban',
    cancellaUser: url + '/users/delete',
    ruoloUser: url + '/users/role',
    mailUser: url + '/users/mail',
    mailForwardUser: url + '/users/mail-forward',

    getContatti: url+ '/contatti/get',
    saveContatti: url+ '/contatti/save',
    contattaStaff: url+ '/contatti/contatta',

    getStatistiche: url+ '/statistiche/get',

    validaQr: url + '/controllore/verifica',
    verificaQrCode: feUrl + '/check-qr',
    isControllore: url + '/controllore/get',

    /*cambiaPosizioneAvvisi: url + '/avvisi/position',
    cambiaPosizioneEventi: url + '/events/position',*/
}
