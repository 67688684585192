import { QrReader } from '@blackbox-vision/react-qr-reader';
import React, { useEffect } from 'react';

const QRCodeScanner = props => {
  const { setResult,width,height } = props;

  useEffect(() => {
    const qrVideoElement = document.querySelector('video');
    if (qrVideoElement) {
      qrVideoElement.setAttribute('playsinline', '');
      qrVideoElement.setAttribute('muted', '');
      qrVideoElement.setAttribute('autoplay', '');
    }
  }, []);

  return (
    <div style={{height:height,width:width}}>
      <QrReader
        constraints={{ facingMode: 'environment',aspectRatio:1 }}
        onResult={(result, error) => {
          if (!!result) {
            setResult(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: width,height:height }}
        videoStyle={{ width: width,height:height }}
        containerStyle={{ width: width,height:height }}
        videoContainerStyle={{ width: width,height:height }}
      />
      <img draggable={false} style={{ top: "15%", position: "absolute", left: "15%",objectFit:"contain" }} width={"70%"} height={"70%"} alt="" src={process.env.PUBLIC_URL + "/frame.png"} />
    </div>
  );
};

export default QRCodeScanner;
