/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { downloadHomepage, downloadRole, getPartners } from "../Utils/ApiUtils";
import { getImage, openWebsiteInNewTab } from "../Utils/FunctionUtils";
import ErrorPage from "./Components/misc/ErrorPage";
import { useTokenActions } from "../Utils/tokenUtils";
import useWindowDimensions from "../Utils/windowDimension";

const Homepage = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const isAdmin = useSelector(state => state.role);
    const { handleSetRole, handleRemoveRole,handleRemoveToken } = useTokenActions();
    const { height } = useWindowDimensions();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [eventi, setEventi] = useState([]);
    const [partners, setPartners] = useState(null);

    const exitError = () => {
        setError(false);
        if (!loading) {
            downloadHomepage(setLoading, setError, setEventi,handleRemoveToken);
        }
    }

    const handleButton = () => {
        const url = token ? '/eventi' : "/login"
        navigate(url);
    }

    useEffect(() => {
        downloadRole(handleSetRole, handleRemoveRole, navigate);
        if (!loading) {
            downloadHomepage(setLoading, setError, setEventi,handleRemoveToken);
        }
        getPartners(null, setPartners, null);
    }, [])

    if (error)
        return <ErrorPage onClick={exitError} />
    else
        return (
            <div style={{ overflowY: "auto", height: height-60, textAlign: "center", paddingTop: 40, paddingBottom: 40 }}>
                <LoadingOverlay visible={loading} />

                {eventi && eventi.length > 0 &&
                    <div style={{ marginBottom: 60 }}>
                        <img alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>}

                {eventi && eventi.length > 0 ?
                    <div>
                        {eventi.map((item, index) => <div key={index} style={{ marginBottom: 20 }}>
                            <div><img alt={item.titolo} src={getImage(item.image)} style={{ height: "auto", width: "80vw", maxHeight: "80vw", borderRadius: 9999, objectFit: "cover", objectPosition: "center", maxWidth: 500, aspectRatio: 1 }}
                            onError={(e) => { e.target.onerror = null; e.target.src = "https://site.cndl.org.br/wp-content/uploads/404.jpg" }} /></div>
                            <div style={{ padding: 20, color: "#fff", fontWeight: "bold", fontFamily: "Poppins", fontSize: 18 }}>{item.titolo}</div>
                        </div>)}
                        <Button
                            onClick={handleButton}
                            type="primary" size="large" shape="round">{token ? (isAdmin ? "Gestisci eventi" : "Prenota ora") : "Accedi per prenotare"}</Button>

                    </div> :
                    <div>
                        <div>
                            <div><img alt={"logo"} src={"/logo.png"} style={{ height: "auto", width: "80vw", maxHeight: "80vw", objectFit: "cover", objectPosition: "center", maxWidth: 500, aspectRatio: 1 }} /></div>
                            <div style={{ padding: 20, color: "#fff", fontWeight: "bold", fontFamily: "Poppins", fontSize: 18 }}>Nessun evento in programma</div>
                        </div>
                        {token && isAdmin &&
                            <div>
                                <Button
                                    onClick={handleButton}
                                    type="primary" size="large" shape="round">Gestisci eventi</Button>
                            </div>}
                    </div>}


                {partners && partners !== null && partners.length > 0 &&
                    <div style={{ display: "flex", flexDirection: "column", gap: 20, justifyContent: "flex-start", alignItems: "center", marginTop: 40 }}>
                        {partners.map((partner, index) =>
                            <div key={index} style={{
                                width: "90%", backgroundColor: "#f8dbb8", padding: 20, borderRadius: 10, height: 120, maxWidth: 500,
                                display: "flex", alignItems: "center", justifyContent: "center", userSelect: "none", cursor: "pointer"
                            }}
                                onClick={() => openWebsiteInNewTab(partner.link)}>
                                <img alt="sponsor1" src={getImage(partner.img)} 
                                style={{ width: "100%", height: "auto", maxWidth: 150, padding: 10,aspectRatio:1,objectFit:"contain",maxHeight:120 }} />
                            </div>)}
                    </div>}
            </div>
        )
}

export default Homepage;