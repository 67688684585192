/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { IconBell, IconChevronLeft, IconPencil, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorPage from "./Components/misc/ErrorPage";
import { deleteAvviso, getAvvisi, getNotificheCount } from "../Utils/ApiUtils";
import { Badge, Button } from "antd";
import { useSelector } from "react-redux";
import AggiungiAvviso from "./Components/avvisi/AggiungiAvviso";
import { useTokenActions } from "../Utils/tokenUtils";
import { showPropsConfirm } from "../Utils/FunctionUtils";
import useWindowDimensions from "../Utils/windowDimension";

const Avvisi = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const isAdmin = useSelector(state => state.role);
    const { handleRemoveToken } = useTokenActions();
    const { height } = useWindowDimensions();

    const [loading, setLoading] = useState(false);
    const [avvisi, setAvvisi] = useState([]);
    const [error, setError] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);
    const [aggiungi, setAggiungi] = useState(null);

    const cancellaAvviso = (item) => {
        const okType = "primary";
        const cancelText = "Annulla"

        const title = "Cancellare questo avviso?";
        const content = "Sei sicuro di voler cancellare l'avviso: " + item.titolo + "?";
        const okText = "Cancella";
        const onOk = () => deleteAvviso(setLoading, setAvvisi, setError, item.id, handleRemoveToken, navigate);
        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    useEffect(() => {
        getAvvisi(setLoading, setAvvisi, setError);
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
        }

        const interval = setInterval(() => {
            getAvvisi(setLoading, setAvvisi, setError);
            if (token && token !== null) {
                getNotificheCount(setNotificheCount);
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ color: "#fff", fontSize: 30, marginBottom: 10, fontWeight: "bold" }}>
                                Avvisi
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{
                                    width: "100%", borderRadius: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 20, height: height-345, overflowY: "auto",
                                    maxWidth: 800
                                }}>
                                    {avvisi && avvisi !== null && avvisi.length > 0 ? avvisi.map((avviso, index) =>
                                        <div style={{ marginBottom: 20 }} key={index}>
                                            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                                {isAdmin &&
                                                    <div style={{marginRight:10}}>
                                                        <div style={{ lineHeight: 0 }}>
                                                            <IconX
                                                                color="red"
                                                                style={{ cursor: "pointer", userSelect: "none" }} onClick={() => cancellaAvviso(avviso)} />
                                                        </div>
                                                        <div style={{ lineHeight: 0 }}>
                                                            <IconPencil color="#ffb60b"
                                                                style={{ cursor: "pointer", userSelect: "none" }} onClick={() => setAggiungi(avviso)} />
                                                        </div>
                                                    </div>}
                                                <div>
                                                    <div style={{ color: "#A00C98", fontSize: 14 }}>{avviso.titolo}</div>
                                                    <div style={{ color: "#fb5607", fontSize: 14 }}>{avviso.testo}</div>
                                                </div>
                                            </div>
                                        </div>)
                                        : <div style={{ marginBottom: 20 }}>
                                            <div style={{ color: "#A00C98", fontSize: 14 }}>Nessun avviso al momento</div>
                                        </div>}
                                </div>
                            </div>
                            {isAdmin &&
                                <center style={{ marginTop: 20 }}>
                                    <Button type="primary" shape="round" size="large"
                                        onClick={() => setAggiungi({ id: null })}>
                                        Aggiungi avviso
                                    </Button>
                                </center>}
                        </div>
                    </div>
                    <AggiungiAvviso visible={aggiungi} setVisible={setAggiungi}
                        handleRemoveToken={handleRemoveToken} navigate={navigate} setLoading={setLoading}
                        setAvvisi={setAvvisi} setError={setError} />
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    getAvvisi(setLoading, setAvvisi, setError);
                }} />}
        </div>
    )
}
export default Avvisi;