/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { IconBell, IconChevronLeft, IconPencil, IconX } from "@tabler/icons-react";
import { Badge, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deletePartner, getNotificheCount, getPartners } from "../Utils/ApiUtils";
import { getImage, openWebsiteInNewTab, showPropsConfirm } from "../Utils/FunctionUtils";
import { useTokenActions } from "../Utils/tokenUtils";
import ErrorPage from "./Components/misc/ErrorPage";
import AggiungiPartner from "./Components/partner/AggiungiPartner";
import useWindowDimensions from "../Utils/windowDimension";
const Partners = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const isAdmin = useSelector(state => state.role);
    const { handleRemoveToken } = useTokenActions();
    const { height } = useWindowDimensions();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);
    const [partners, setPartners] = useState(null);
    const [aggiungi, setAggiungi] = useState(null);

    const rimuoviPartner = item => {
        const okType = "primary";
        const cancelText = "Annulla"
        const title = "Cancellare il partner?";
        const content = "Sei sicuro di voler cancellare il partner: " + item.nome + "?";
        const okText = "Cancella";
        const onOk = () => deletePartner(setLoading, setPartners, setError, item.id, handleRemoveToken, navigate);
        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }


    useEffect(() => {
        getPartners(setLoading, setPartners, setError);
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
        }

        const interval = setInterval(() => {
            getPartners(setLoading, setPartners, setError);
            if (token && token !== null) {
                getNotificheCount(setNotificheCount);
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ color: "#fff", fontSize: 30, marginTop: 10, fontWeight: "bolder" }}>
                                Partners
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {partners && partners !== null && partners.length > 0 ?
                                    <div style={{ width: "100%", height: height-360, overflowY: "auto", marginTop: 15, maxWidth: 500 }}>
                                        {partners.map((item, index) =>
                                            <div key={index}
                                                style={{
                                                    marginBottom: 20,
                                                    borderBottom: "1px solid rgba(100, 100, 100, 0.1)",
                                                    display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%",
                                                    borderRadius: 10, backgroundColor: "rgba(255, 255, 255, 0.6)"
                                                }}>
                                                {isAdmin && <div style={{ alignSelf: "flex-start", flex: 0.1, paddingTop: 5, paddingLeft: 5 }}>
                                                    <IconX color="red" onClick={() => rimuoviPartner(item)} style={{cursor:"pointer",userSelect:"none"}} />
                                                    <IconPencil color="#fd8e09" onClick={() => setAggiungi(item)} style={{cursor:"pointer",userSelect:"none"}} />
                                                </div>}
                                                <div style={{ flex: isAdmin ? 0.79 : 1 }}>
                                                    <img 
                                                    onError={(e) => { e.target.onerror = null; e.target.src = "https://site.cndl.org.br/wp-content/uploads/404.jpg"; }}
                                                    draggable={false} onClick={() => openWebsiteInNewTab(item.link)}
                                                        src={getImage(item.img)} alt={index} style={{
                                                            width: "100%", padding: 25,
                                                            paddingLeft: isAdmin ? "20%" : "25%", paddingRight: isAdmin ? "20%" : "25%",
                                                            userSelect: "none",
                                                            cursor: "pointer",height:140,objectFit:"contain"
                                                        }}/>
                                                </div>
                                            </div>
                                        )}
                                    </div> :
                                    <div style={{ marginBottom: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 15, borderRadius: 10, marginTop: 30 }}>
                                        <div style={{ color: "#A00C98", fontSize: 14 }}>Nessun partner al momento</div>
                                    </div>}
                            </div>
                            {isAdmin && <center>
                                <Button type="primary" shape="round"
                                    size="large"
                                    style={{ fontFamily: "Poppins", fontWeight: 600,marginTop:20 }}
                                    onClick={() => setAggiungi({ id: null })}>
                                    Aggiungi partner
                                </Button>
                            </center>}
                        </div>
                    </div>
                    <AggiungiPartner visible={aggiungi} setVisible={setAggiungi}
                        handleRemoveToken={handleRemoveToken} navigate={navigate} setLoading={setLoading}
                        setPartners={setPartners} setError={setError} token={token}
                    />
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    getPartners(setLoading, setPartners, setError);
                }} />}
        </div>
    )
}
export default Partners;