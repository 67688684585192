/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Checkbox, LoadingOverlay, Select } from '@mantine/core';
import { IconBell, IconChevronLeft } from '@tabler/icons-react';
import { Badge, Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadGruppiByOwner, downloadPartecipantiGruppo, getNotificheCount, prenotaUtenteGruppo } from '../Utils/ApiUtils';
import { useTokenActions } from '../Utils/tokenUtils';
import useWindowDimensions from '../Utils/windowDimension';
import ErrorPage from './Components/misc/ErrorPage';
import { showPropsConfirm } from '../Utils/FunctionUtils';

export default function PrenotazioneGruppo() {
    const { height } = useWindowDimensions();

    const token = useSelector(state => state.token);
    const { handleRemoveToken } = useTokenActions();

    const navigate = useNavigate();
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);

    const [gruppi, setGruppi] = useState([]);
    const [loadingGruppi, setLoadingGruppi] = useState(false);
    const [gruppoSelezionato, setGruppoSelezionato] = useState(null);

    const [utenti, setUtenti] = useState([])
    const [utentiSelezionati, setUtentiSelezionati] = useState([]);

    const labelProps = { style: { color: "#fff", fontWeight: 600, fontSize: 16, paddingBottom: 5, fontFamily: "Poppins" } };
    const labelStyles = {
        input: { border: "none", backgroundColor: "rgba(255,255,255,0.69)", color: "#fb5607", fontWeight: 600, fontFamily: "Poppins" },
        error: { color: "#fff", fontFamily: "Poppins", fontSize: 12 }
    };

    const prenotaUtenti = () => {
        const content = "Sei sicuro di voler effettuare la prenotazione per questi utenti?";
        const title = "Effettuare la prenotazione?";
        const onOk = () => prenotaUtenteGruppo(setLoading, gruppoSelezionato, utentiSelezionati, id, handleRemoveToken, navigate,setError);
        const onCancel = () => {return};
        const okText="Prenota";
        const okType="primary";
        const cancelText="Annulla";
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    useEffect(() => {
        if (token && token !== null) {
            downloadGruppiByOwner(setLoadingGruppi, setGruppi, handleRemoveToken, navigate);
            getNotificheCount(setNotificheCount);
        } else {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }

        const interval = setInterval(() => {
            if (token && token !== null) {
                getNotificheCount(setNotificheCount);
            } else {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (gruppoSelezionato !== null) {
            downloadPartecipantiGruppo(setLoading, setError, setUtenti, handleRemoveToken, navigate, gruppoSelezionato, id);
        } else {
            setUtenti([]);
            setUtentiSelezionati([]);
        }
    }, [gruppoSelezionato])


    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading || loadingGruppi} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ color: "#fff", fontSize: 30, marginBottom: 10, fontWeight: "bold", lineHeight: 1.4 }}>
                                Prenotazione gruppo
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{
                                    display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", gap: 10, maxWidth: 800,
                                    marginTop: 10
                                }}>
                                    <Select
                                        searchable
                                        clearable
                                        value={gruppoSelezionato}
                                        icon={loadingGruppi && <LoadingOutlined spin style={{ color: "blue" }} />}
                                        transitionProps={{ transition: 'pop-top-left', duration: 80, timingFunction: 'ease' }}
                                        nothingFound="Nessun gruppo trovato"
                                        label="Gruppo:"
                                        placeholder="Seleziona gruppo"
                                        onChange={gruppo => setGruppoSelezionato(gruppo)}
                                        data={gruppi}
                                        labelProps={labelProps}
                                        styles={labelStyles}
                                        radius="lg"
                                        size='xl' />
                                </div>
                            </div>
                            {gruppoSelezionato && utenti && utenti !== null && utenti.length > 0 ?
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column" }}>
                                    <div style={{
                                        width: "100%", height: height - 450, overflowY: "auto", marginTop: 20, maxWidth: 800,
                                        display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column"
                                    }}>
                                        {utenti && utenti.length > 0 && utenti.map((item, i) => (
                                            <div key={i}
                                                style={{
                                                    color: "#444", backgroundColor: "rgba(255,255,255,0.69)", padding: 14,
                                                    borderRadius: 10, marginBottom: i === utenti.length - 1 ? 0 : 20, width: "100%", fontFamily: "Poppins",
                                                    display: "flex", alignItems: "center", justifyContent: "space-between"
                                                }}>
                                                <div style={{ color: "rgba(131, 56, 236, 1)", fontFamily: "Poppins", fontWeight: 600, fontSize: 18 }}>
                                                    {item.nomeCognome}
                                                </div>
                                                <div style={{

                                                }}>
                                                    <Checkbox
                                                        size={"md"}
                                                        label={<div style={{ color: "rgba(131, 56, 236, 1)", fontSize: 16, fontFamily: "Poppins", fontWeight: 600 }}>
                                                            {item.label}
                                                        </div>}
                                                        value={utentiSelezionati.includes(item.id)}
                                                        checked={utentiSelezionati.includes(item.id)}
                                                        onChange={() => utentiSelezionati.includes(item.id) ?
                                                            setUtentiSelezionati(utentiSelezionati.filter(id => id.toString() !== item.id.toString()))
                                                            : setUtentiSelezionati([...utentiSelezionati, item.id])}
                                                        labelProps={labelProps} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div> : gruppoSelezionato && gruppoSelezionato !== null ?
                                    <center style={{ color: "#fff", fontWeight: 600, fontFamily: "Poppins", marginTop: 40 }}>Tutti i partecipanti hanno già una prenotazione!</center>
                                    : null}

                            {gruppoSelezionato && utenti && utenti !== null && utenti.length > 0 && utentiSelezionati && utentiSelezionati.length > 0 &&
                                <center style={{ marginTop: 18 }}>
                                    <Button onClick={() => prenotaUtenti()} type="primary" size="large" shape="round"
                                        className="btn_secondary">Effettua prenotazione</Button>
                                </center>}
                        </div>
                    </div>
                </div> : <ErrorPage onClick={() => {
                    setError(false);
                    downloadGruppiByOwner(setLoadingGruppi, setGruppi, handleRemoveToken, navigate);
                    setGruppoSelezionato(null);
                    setUtentiSelezionati([]);
                    setUtenti(null);
                }} />}
        </div>);
}