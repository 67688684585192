import { IconHome, IconMenu2, IconPhone, IconTicket } from '@tabler/icons-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../css/Navigation.css';
const Navigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = useSelector(state=>state.token);
    const isCheckQr = location.pathname.startsWith('/check-qr') || location.pathname.startsWith('/pagamento');
    
    return (
        <nav className="nav_bottom" style={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 999, 
        height: isCheckQr?0:60, display: location.pathname === '/' ? "none" : "block",visibility:isCheckQr?"hidden":"visible" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", height: "100%", paddingLeft: 15, paddingRight: 15 }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 30 }}>
                    <IconHome size={35} style={{ cursor: "pointer", userSelect: "none" }}
                        onClick={() => navigate('/homepage')} color="#fff" fill={location.pathname === '/homepage' ? "rgba(255,255,255,0.5)" : "none"} />
                    <IconPhone onClick={() => navigate('/contatti')} size={35} style={{ cursor: "pointer", userSelect: "none" }}
                        color="#fff" fill={location.pathname === '/contatti' ? "rgba(255,255,255,0.5)" : "none"}/>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 30 }}>
                    {token && token !== null &&
                        <IconTicket onClick={() => navigate('/prenotazioni')} size={35} color="#fff" 
                        style={{ cursor: "pointer", userSelect: "none" }} 
                        fill={location.pathname === '/prenotazioni' ? "rgba(255,255,255,0.5)" : "none"}
                        />}

                    <IconMenu2 onClick={() => navigate('/menu')} size={35} color="#fff" style={{ cursor: "pointer", userSelect: "none" }} />
                </div>
            </div>

        </nav>
    )
}
export default Navigation;