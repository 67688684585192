/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { IconBan, IconBrandWhatsapp, IconMail, IconPhone, IconTicket } from "@tabler/icons-react";
import { Modal } from "antd";
import React, { useState } from "react";
import { rimuoviPrenotazioneByAdmin } from "../../../Utils/ApiUtils";
import { apriTelefono, apriWhatsapp, showPropsConfirm } from "../../../Utils/FunctionUtils";

const MenuPrenotazioniEffettuate = props => {
    const { visible, setVisible,navigate,eventId,download,handleRemoveToken,setError,apriInviaEmail,apriBiglietto } = props;

    const [loadingModal, setLoadingModal] = useState(false);

    const deleteTicket = () => {
        const title = "Vuoi annullare la prenotazione?";
        const content = "Sei sicuro di voler annullare la prenotazione per l'utente: "+(visible.nome+" "+visible.cognome);
        const onOk = () => rimuoviPrenotazioneByAdmin(eventId, visible.id, setLoadingModal,setVisible, download, handleRemoveToken, navigate, setError);
        const okText = "Annulla prenotazione"
        const cancelText = "Esci";
        const okType = "primary";
        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    const items = [
        {titolo:"Chiamata",icon:<IconPhone stroke={0} fill="rgba(131, 56, 236, 1)"/>,action:()=>apriTelefono(visible.cellulare)},
        {titolo:"Whatsapp",icon:<IconBrandWhatsapp/>,action:()=>apriWhatsapp(visible.cellulare,"")},
        {titolo:"Email",icon:<IconMail color="#fff" fill="rgba(131, 56, 236, 1)"/>,action:()=>apriInviaEmail(visible)},
        {titolo:"Visualizza biglietto",icon:<IconTicket />,action:()=>apriBiglietto(visible)},
        {titolo:"Rimuovi prenotazione",icon:<IconBan color="rgba(131, 56, 236, 1)"/>,action:()=>deleteTicket()},
    ]

    return (
        <Modal
            open={visible}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => setVisible(null)}
            title={null}
            footer={null}
            bodyStyle={{padding:0,margin:0}}
            className="no-padding-modal">
            <div>
                <LoadingOverlay visible={loadingModal} />
                <div style={{paddingTop:50}}>
                {items.map((item,index)=>
                <div key={index} style={{display:"flex",justifyContent:"space-between",
                alignItems:"center",color:"rgba(131, 56, 236, 1)",fontFamily:"Poppins",fontSize:16,fontWeight:600,
                borderBottom:index===items.length-1 ? "none" : "4px solid rgba(131, 56, 236, 0.1)",
                width:"100%",paddingLeft:40,paddingRight:50,paddingBottom:index===items.length-1 ?20:10,paddingTop:10,
                cursor:"pointer",userSelect:"none"}}
                onClick={()=>item.action()}>
                    <div>{item.titolo}</div>
                    {item.icon}
                </div>)}
                </div>
            </div>
        </Modal>
    )
}
export default MenuPrenotazioniEffettuate;