/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from '@mantine/core';
import { notification } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { verificaQrCode } from '../Utils/ApiUtils';
import { API } from '../Utils/Request';
import { useTokenActions } from '../Utils/tokenUtils';
import QRCodeScanner from './Components/check-qr/QrCodeScanner';
import ErrorPage from './Components/misc/ErrorPage';
import { apriWhatsapp } from '../Utils/FunctionUtils';
import { cellulareAssistenza } from '../Utils/Costants';
import QrCodeRequest from './Components/check-qr/QrCodeRequest';
import QrCodeVerifica from './Components/check-qr/QrCodeVerifica';
import useWindowDimensions from '../Utils/windowDimension';

const CheckQr = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const { handleRemoveToken } = useTokenActions();
    const { qr } = useParams();
    const [result, setResult] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [verifica, setVerifica] = useState(null);
    const [isCameraStarted, setIsCameraStarted] = useState(false);
    const { width,height } = useWindowDimensions();

    function startCamera() {
        setLoading(true)
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
                .then(() => {
                    setIsCameraStarted(true);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                    setIsCameraStarted(false);
                });
        } else {
            setLoading(true);
        }
    }

    useEffect(() => {
        if (token === null) {
            notification.error({
                placement: "top",
                message: "Effettua l'accesso",
                description:
                    "Effettua l'accesso prima di effettuare i controlli",
            });
            navigate('/login')
            return;
        } else {
            setLoading(true);
            if (isCameraStarted || qr !== "verifica") {
                axios.get(API.isControllore, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }).then(() => {
                    if (qr && qr !== null && qr.length > 0 && qr !== "verifica") {
                        verificaQrCode(true, qr, setLoading, setVerifica, setResult, navigate, startCamera, handleRemoveToken, isCameraStarted);
                        setLoading(false);
                    }
                }).catch(() => {
                    setLoading(false);
                    notification.error({
                        placement: "top",
                        message: 'Non hai i permessi necessari',
                        description:
                            "Non hai i permessi necessari per accedere a questa pagina",
                    });
                    navigate('/');
                })

            } else startCamera();
        }
    }, [])

    return (
        <div style={{
            height: height,width: width,
            backgroundColor: error ? "#228be6" : "#1a1a1a",maxWidth:width,overflow:"hidden"
        }}>
            {error ?
                <>
                    <ErrorPage
                        txt="Devi concedere l'autorizzazione per l'utilizzo della videocamera"
                        onClick={() => {
                            setError(false);
                            startCamera();
                        }} logout={true} />
                </> :
                <div style={{width:width,height:height}}>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    {isCameraStarted && result === null && verifica === null && 
                        <QRCodeScanner height={height} width={width} setResult={r => setResult(r)} />}
                    {result && <QrCodeRequest
                        loading={loading}
                        verifica={() => {
                            verificaQrCode(true, result, setLoading, setVerifica, setResult, navigate, startCamera, handleRemoveToken, isCameraStarted);
                        }}
                        indietro={() => {
                            setResult(null);
                            if (!isCameraStarted) {
                                startCamera();
                            }
                        }} />
                    }
                    {verifica && <QrCodeVerifica
                        segnala={() => apriWhatsapp(cellulareAssistenza, verifica.qrCode)}
                        data={verifica}
                        verifica={() => {
                            verificaQrCode(false, verifica.qrCode, setLoading, setVerifica, setResult, navigate, startCamera, handleRemoveToken, isCameraStarted);
                        }}
                        indietro={() => {
                            setVerifica(null);
                            navigate('/check-qr/verifica')
                            if (!isCameraStarted) {
                                startCamera();
                            }
                        }} />
                    }
                </div>
            }
        </div>
    );
};

export default CheckQr;
