import axios from 'axios';
import { store } from './redux/store/store';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
    const { token } = store.getState();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default axiosInstance;
