/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { saveContatti } from "../../../Utils/ApiUtils";

const AggiungiContatti = props => {
    const { visible, setVisible, handleRemoveToken, navigate, setLoading, setContatti, setError } = props;
    const labelProps = { style: { color: "#fb5607", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "3px solid #fb5607", backgroundColor: "#fff" }, error: { color: "#fb5607", fontFamily: "Poppins", fontSize: 12 } };

    const [loadingCrea, setLoadingCrea] = useState(false);

    const form = useForm({
        initialValues: {
            cellulare: '',
            facebook: '',
            instagram: '',
            telegram: '',
        }
    });

    useEffect(() => {
        if (visible !== null) {
            form.setValues(visible);
        } else {
            form.reset();
        }
    }, [visible])


    return (
        <Modal
            open={visible !== null}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => setVisible(null)}
            title={<center style={{ color: "rgb(160, 12, 152)", fontFamily: "Poppins", fontWeight: 600, marginBottom: 20 }}>
                {visible && visible !== null && visible.id !== null ? "Modifica contatti" : "Aggiungi contatti"}
            </center>}
            footer={null}>
            <>
                <LoadingOverlay visible={loadingCrea} />
                <form style={{ marginBottom: 5 }}
                    onSubmit={form.onSubmit((values) =>
                        saveContatti(visible.id, values, setLoading, setLoadingCrea, setVisible, setContatti, setError, handleRemoveToken, navigate))}>

                    <TextInput
                        label="Cellulare"
                        placeholder="Cellulare"
                        value={form.values.cellulare}
                        onChange={(event) => form.setFieldValue('cellulare', event.currentTarget.value)}
                        error={form.errors.cellulare}
                        radius="lg"
                        size='lg'
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <TextInput
                        label="Facebook"
                        placeholder="URL Facebook"
                        value={form.values.facebook}
                        onChange={(event) => form.setFieldValue('facebook', event.currentTarget.value)}
                        error={form.errors.facebook}
                        radius="lg"
                        size='lg'
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <TextInput
                        label="Instagram"
                        placeholder="URL Instagram"
                        value={form.values.instagram}
                        onChange={(event) => form.setFieldValue('instagram', event.currentTarget.value)}
                        error={form.errors.instagram}
                        radius="lg"
                        size='lg'
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <TextInput
                        label="Telegram"
                        placeholder="URL Telegram"
                        value={form.values.telegram}
                        onChange={(event) => form.setFieldValue('telegram', event.currentTarget.value)}
                        error={form.errors.telegram}
                        radius="lg"
                        size='lg'
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <div style={{ marginTop: 25, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                        <Button onClick={() => {
                            form.reset();
                            setVisible(null);
                        }}
                            style={{ fontWeight: 600, fontFamily: "Poppins" }}>
                            Annulla
                        </Button>
                        <Button type="primary" shape="round" loading={loadingCrea} style={{ fontWeight: 600, fontFamily: "Poppins" }}
                            htmlType="submit">
                            {visible && visible !== null && visible.id !== null ? "Modifica contatti" : "Aggiungi contatti"}
                        </Button>
                    </div>
                </form>
            </>
        </Modal>
    )
}
export default AggiungiContatti;