import { Button, Container, Group, Text, Title, createStyles } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTokenActions } from '../../../Utils/tokenUtils';
const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 80,
        paddingBottom: 120,
        backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
        height: "100vh"
    },

    label: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 220,
        lineHeight: 1,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        color: theme.colors[theme.primaryColor][3],

        [theme.fn.smallerThan('sm')]: {
            fontSize: 120,
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 38,
        color: theme.white,

        [theme.fn.smallerThan('sm')]: {
            fontSize: 32,
        },
    },

    description: {
        maxWidth: 540,
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        color: theme.colors[theme.primaryColor][1],
    },
}));

export default function ErrorPage(props) {
    const { onClick, txt, logout } = props;
    const { handleRemoveToken } = useTokenActions();
  const { classes } = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const doLogout = () => {
        handleRemoveToken();
        navigate('/login')
    }

    return (
        <div className={classes.root}>
            <Container>
                <div className={classes.label}>500</div>
                <Title className={classes.title}>Errore Interno</Title>
                <Text size="lg" align="center" className={classes.description}>
                    {txt ? txt : "Il nostro server non ha potuto rispondere alla tua richiesta."}
                    <br />
                    Prova a ricaricare la pagina.
                </Text>
                <Group position="center" mt={60}>
                    <Button variant="white" size="md" fullWidth onClick={onClick}>
                        Ricarica la pagina
                    </Button>
                    {location.pathname !== '/' && <Button variant="white" size="md" fullWidth onClick={() => navigate('/homepage')}>
                        Torna alla home
                    </Button>}
                    {logout &&
                        <Button variant="white" size="md" fullWidth onClick={()=>doLogout()}>
                            Logout
                        </Button>
                    }
                </Group>
            </Container>
        </div>
    );
}