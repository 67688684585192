import { LoadingOverlay, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { creaGruppo } from "../../../Utils/ApiUtils";

const CreaGruppo = props => {
    const { visible, setVisible, handleRemoveToken, navigate, setLoading, setGruppi, setError } = props;
    const labelProps = { style: { color: "#fb5607", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "3px solid #fb5607", backgroundColor: "#fff" }, error: { color: "#fb5607", fontFamily: "Poppins", fontSize: 12 } };

    const [loadingCrea, setLoadingCrea] = useState(false);

    const form = useForm({
        initialValues: {
            groupName: '',
            groupDescription: '',
        },
        validate: {
            groupName: (val) => (typeof val === 'undefined' || val.trim().length <= 0 ? "Nome non valido" : null),
        }
    });

    return (
        <Modal
            open={visible}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => setVisible(false)}
            title={<div style={{ color: "rgb(160, 12, 152)", fontFamily: "Poppins", fontWeight: 600, marginBottom: 20 }}>Crea Gruppo</div>}
            okText="Crea"
            cancelText="Annulla"
            footer={null}>
            <>
                <LoadingOverlay visible={loadingCrea} />
                <form style={{ marginBottom: 5 }}
                    onSubmit={form.onSubmit((values) => creaGruppo(setLoadingCrea, values, setLoading, setGruppi, setError, handleRemoveToken, navigate, form, setVisible))}>
                    <TextInput
                        required
                        label="Nome Gruppo"
                        placeholder="Nome gruppo"
                        value={form.values.groupName}
                        onChange={(event) => form.setFieldValue('groupName', event.currentTarget.value)}
                        error={form.errors.groupName}
                        radius="lg"
                        size='lg'
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <TextInput
                        label="Descrizione"
                        placeholder="Descrizione gruppo"
                        value={form.values.groupDescription}
                        onChange={(event) => form.setFieldValue('groupDescription', event.currentTarget.value)}
                        radius="lg"
                        size='lg'
                        style={{ marginTop: 10 }}
                        labelProps={labelProps}
                        styles={labelStyles} />

                    <div style={{ marginTop: 25, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                        <Button onClick={() => {
                            form.reset();
                            setVisible(false);
                        }}>
                            Annulla
                        </Button>
                        <Button className="btn_secondary" type="primary" shape="round" loading={loadingCrea} style={{ fontWeight: "normal" }}
                            htmlType="submit">
                            Crea gruppo
                        </Button>
                    </div>
                </form>
            </>
        </Modal>
    )
}
export default CreaGruppo;