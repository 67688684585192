/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { LoadingOverlay } from "@mantine/core";
import { IconCheck, IconChevronLeft, IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { cancellaNotifica, getNotifiche, rispondiInvitoGruppo } from "../Utils/ApiUtils";
import { useTokenActions } from "../Utils/tokenUtils";
import ErrorPage from "./Components/misc/ErrorPage";
import { Button } from "antd";
import { showPropsConfirm } from "../Utils/FunctionUtils";
import { LoadingOutlined } from "@ant-design/icons";
import useWindowDimensions from "../Utils/windowDimension";

const Notifiche = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [notifiche, setNotifiche] = useState([]);
    const [error, setError] = useState(false);
    const [loadingInvito, setLoadingInvito] = useState(null);
    const [loadingCancella, setLoadingCancella] = useState(null);
    const { height } = useWindowDimensions();
    const { handleRemoveToken } = useTokenActions();

    const accettaInvito = userGroupId => {
        const okText = "Accetta";
        const okType = "primary";
        const cancelText = "Annulla"
        const onCancel = () => { return };
        const title = "Vuoi accettare questo invito?";
        const content = "Sei sicuro di voler accettare questo invito?";
        const onOk = () => rispondiInvitoGruppo(userGroupId, true, setLoadingInvito, handleRemoveToken, loading, setLoading, setNotifiche, navigate, setError);
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    const rifiutaInvito = userGroupId => {
        const okText = "Rifiuta";
        const okType = "primary";
        const cancelText = "Annulla"
        const onCancel = () => { return };
        const title = "Vuoi rifiutare questo invito?";
        const content = "Sei sicuro di voler rifiutare questo invito?";
        const onOk = () => rispondiInvitoGruppo(userGroupId, false, setLoadingInvito, handleRemoveToken, loading, setLoading, setNotifiche, navigate, setError);
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    const getBottoniNotifica = item => {
        if (item.type === 'inviti') {
            return (<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 5 }}>
                <Button type="primary" shape="circle" onClick={() => accettaInvito(item.extra)}>
                    {loadingInvito === item.extra?<LoadingOutlined spin />:<IconCheck color="#fff" />}
                </Button>
                {loadingInvito !== item.extra &&
                    <Button type="primary" shape="circle" onClick={() => rifiutaInvito(item.extra)}>
                        <IconX color="#fff" />
                    </Button>}
            </div>)
        } else {
            return (<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 5 }}>
                <Button type="primary" shape="circle" 
                onClick={() => cancellaNotifica(item.extra, setLoadingCancella, loading, setLoading, setNotifiche, handleRemoveToken, navigate, setError)}>
                    {loadingCancella === item.extra?<LoadingOutlined spin />:<IconX color="#fff" />}
                </Button>
            </div>)
        }
    }

    const getTestoNotifica = item => {
        if (item.type === 'inviti') {
            return item.start+" "+item.txt+" "+item.end;
        }else return item.txt;
    }

    useEffect(() => {
        getNotifiche(loading, setLoading, setNotifiche, handleRemoveToken, navigate, setError);
        const interval = setInterval(() => {
            getNotifiche(loading, setLoading, setNotifiche, handleRemoveToken, navigate, setError);
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset", overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL+"/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20 }}>
                            <IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            />
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ color: "#fff", fontSize: 30, marginBottom: 10, fontWeight: "bold" }}>
                                Notifiche
                            </div>
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <div style={{ width: "100%", borderRadius: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 20, height: height-300, overflowY: "auto",
                            maxWidth:1000 }}>
                                {notifiche && notifiche !== null && notifiche.length > 0 ? notifiche.map((notifica, index) =>
                                    <div style={{ marginBottom: 20 }} key={index}>
                                        <div style={{ color: "#A00C98", fontSize: 14 }}>{notifica.titolo}</div>
                                        <div style={{ color: "#fb5607", fontSize: 14 }}>{getTestoNotifica(notifica)}</div>
                                        {getBottoniNotifica(notifica)}
                                    </div>)
                                    : <div style={{ marginBottom: 20 }}>
                                        <div style={{ color: "#A00C98", fontSize: 14 }}>Nessuna notifica al momento</div>
                                    </div>}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    getNotifiche(loading, setLoading, setNotifiche, handleRemoveToken, navigate, setError);
                }} />}
        </div>
    )
}
export default Notifiche;