/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { IconChartPie, IconCircuitSwitchClosed, IconPencil, IconPlayerPause, IconPlayerPlay, IconTrash, IconUsers } from "@tabler/icons-react";
import { Modal } from "antd";
import React, { useState } from "react";
import { cancellaEvento, concludiEvento } from "../../../Utils/ApiUtils";
import { showPropsConfirm } from "../../../Utils/FunctionUtils";

const MenuEvento = props => {
    const { visible, setVisible,item,navigate,loading,setLoading,setEventi,handleRemoveToken,setError,apriModificaCapienza } = props;

    const [loadingModal, setLoadingModal] = useState(false);

    const deleteEvent = item => {
        const title = "Vuoi cancellare questo evento?";
        const content = "Sei sicuro di voler cancellare l'evento: "+item.titolo;
        const onOk = () => cancellaEvento(item.id,setLoadingModal,loading,setLoading,setEventi,handleRemoveToken,navigate,setError,setVisible);
        const okText = "Cancella";
        const cancelText = "Annulla";
        const okType = "primary";
        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    const stopPlayEvento = item => {
        const title = "Vuoi "+(!item.concluso?"concludere":"far ripartire")+" questo evento?";
        const content = "Sei sicuro di voler "+(!item.concluso?"concludere":"far ripartire")+" l'evento: "+item.titolo;
        const onOk = () => concludiEvento(item.id,setVisible,loading,setLoading,setError,setEventi,setLoadingModal,item,handleRemoveToken,navigate);
        const okText = (item.concluso?"Fai ripartire":"Concludi");
        const cancelText = "Annulla";
        const okType = "primary";
        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    const items = [
        {titolo:(item.concluso?"Avvia evento":"Concludi evento"),icon:(item.concluso?<IconPlayerPlay/>:<IconPlayerPause/>),action:()=>stopPlayEvento(item)},
        {titolo:"Prenotazioni",icon:<IconUsers/>,action:()=>navigate('/amministra-prenotazioni/'+item.id)},
        {titolo:"Modifica evento",icon:<IconPencil/>,action:()=>navigate('/evento/'+item.id)},
        {titolo:"Capienza evento",icon:<IconCircuitSwitchClosed/>,action:()=>apriModificaCapienza(item)},
        {titolo:"Statistiche evento",icon:<IconChartPie/>,action:()=>navigate('/statistiche/'+item.id)},
        {titolo:"Elimina evento",icon:<IconTrash/>,action:()=>deleteEvent(item)},
    ]

    return (
        <Modal
            open={visible}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => setVisible(null)}
            title={null}
            footer={null}
            bodyStyle={{padding:0,margin:0}}
            className="no-padding-modal">
            <div>
                <LoadingOverlay visible={loadingModal} />
                <div style={{paddingTop:50}}>
                {items.map((item,index)=>
                <div key={index} style={{display:"flex",justifyContent:"space-between",
                alignItems:"center",color:"rgba(131, 56, 236, 1)",fontFamily:"Poppins",fontSize:16,fontWeight:600,
                borderBottom:index===items.length-1 ? "none" : "4px solid rgba(131, 56, 236, 0.1)",
                width:"100%",paddingLeft:40,paddingRight:50,paddingBottom:index===items.length-1 ?10:5,paddingTop:5,
                cursor:"pointer",userSelect:"none"}}
                onClick={()=>item.action()}>
                    <div>{item.titolo}</div>
                    <div>{item.icon}</div>
                </div>)}
                </div>
            </div>
        </Modal>
    )
}
export default MenuEvento;