/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { inviaEmailByAdmin } from "../../../Utils/ApiUtils";

const InvioEmailUtente = props => {
    const { visible, setVisible, handleRemoveToken, navigate, setError } = props;
    const labelProps = { style: { color: "#fb5607", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "3px solid #fb5607", backgroundColor: "#fff" }, error: { color: "#fb5607", fontFamily: "Poppins", fontSize: 12 } };

    const [loadingCrea, setLoadingCrea] = useState(false);

    const form = useForm({
        initialValues: {
            testo: '',
        },
        validate: {
            testo: (val) => (typeof val === 'undefined' || val.trim().length <= 0 ? "Testo non valido" : null),
        }
    });

    useEffect(() => {
        if (visible === null) {
            form.reset();
        }
    }, [visible])


    return (
        <Modal
            open={visible !== null}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => setVisible(null)}
            title={<center style={{ color: "rgb(160, 12, 152)", fontFamily: "Poppins", fontWeight: 600, marginBottom: 20 }}>
                Invio Email
            </center>}
            footer={null}>
            <>
                <LoadingOverlay visible={loadingCrea} />
                <form style={{ marginBottom: 5 }}
                    onSubmit={form.onSubmit((values) =>
                        inviaEmailByAdmin(visible.email,values.testo,setLoadingCrea,handleRemoveToken,navigate,setError,setVisible))}>
                    <Textarea
                        required
                        minRows={4}
                        mt="md"
                        label="Testo"
                        placeholder="Scrivi un testo.."
                        value={form.values.testo}
                        error={form.errors.testo}
                        onChange={(event) => form.setFieldValue("testo", event.target.value)}
                        radius="lg"
                        size='xl'
                        labelProps={labelProps}
                        styles={labelStyles} />
                    <div style={{ marginTop: 25, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                        <Button onClick={() => {
                            form.reset();
                            setVisible(null);
                        }}
                            style={{ fontWeight: 600, fontFamily: "Poppins" }}>
                            Annulla
                        </Button>
                        <Button type="primary" shape="round" loading={loadingCrea} style={{ fontWeight: 600, fontFamily: "Poppins" }}
                            htmlType="submit">
                            Invia Email
                        </Button>
                    </div>
                </form>
            </>
        </Modal>
    )
}
export default InvioEmailUtente;