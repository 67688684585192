/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { IconBan, IconBrandWhatsapp, IconDeviceMobileMessage, IconMail, IconPhone, IconShield, IconTrash } from "@tabler/icons-react";
import { Modal } from "antd";
import React, { useState } from "react";
import { bannaUtente, cancellaUtente } from "../../../Utils/ApiUtils";
import { apriTelefono, apriWhatsapp, showPropsConfirm } from "../../../Utils/FunctionUtils";

const MenuGestioneUtenti = props => {
    const { visible, setVisible,navigate,page,search,setLoading,setUtenti,setUsersCount,handleRemoveToken,setError,apriInviaEmail,apriInviaNotifica,apriCambiaRuolo } = props;

    const [loadingModal, setLoadingModal] = useState(false);

    const deleteUser = () => {
        const title = "Vuoi cancellare questo utente?";
        const content = "Sei sicuro di voler cancellare l'utente: "+(visible.nome+" "+visible.cognome);
        const onOk = () => cancellaUtente(visible.id, setLoadingModal, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError,setVisible);
        const okText = "Cancella";
        const cancelText = "Annulla";
        const okType = "primary";
        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    const banUser = () => {
        const title = "Vuoi "+(visible.banned?"sbannare":"bannare")+" questo utente?";
        const content = "Sei sicuro di voler "+(visible.banned?"sbannare":"bannare")+" l'utente: "+(visible.nome+" "+visible.cognome);
        const onOk = () => bannaUtente(setLoadingModal, visible.id, visible.banned, page, search, handleRemoveToken, navigate, setError, setLoading, setUtenti, setUsersCount,setVisible);
        const okText = visible.banned?"Sbanna utente":"Banna utente";
        const cancelText = "Annulla";
        const okType = "primary";
        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    const items = [
        {titolo:"Chiamata",icon:<IconPhone stroke={0} fill="rgba(131, 56, 236, 1)"/>,action:()=>apriTelefono(visible.cellulare)},
        {titolo:"Whatsapp",icon:<IconBrandWhatsapp/>,action:()=>apriWhatsapp(visible.cellulare,"")},
        {titolo:"Email",icon:<IconMail color="#fff" fill="rgba(131, 56, 236, 1)"/>,action:()=>apriInviaEmail(visible)},
        {titolo:"Invia Notifica",icon:<IconDeviceMobileMessage color="#fff" fill="rgba(131, 56, 236, 1)"/>,action:()=>apriInviaNotifica(visible)},
        {titolo:"Cambia ruolo",icon:<IconShield stroke={0} fill="rgba(131, 56, 236, 1)"/>,action:()=>apriCambiaRuolo(visible)},
        {titolo:"Banna utente",icon:<IconBan color="rgba(131, 56, 236, 1)"/>,action:()=>banUser()},
        {titolo:"Elimina utente",icon:<IconTrash />,action:()=>deleteUser()},
    ]

    return (
        <Modal
            open={visible}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => setVisible(null)}
            title={null}
            footer={null}
            bodyStyle={{padding:0,margin:0}}
            className="no-padding-modal">
            <div>
                <LoadingOverlay visible={loadingModal} />
                <div style={{paddingTop:50}}>
                {items.map((item,index)=>
                <div key={index} style={{display:"flex",justifyContent:"space-between",
                alignItems:"center",color:"rgba(131, 56, 236, 1)",fontFamily:"Poppins",fontSize:16,fontWeight:600,
                borderBottom:index===items.length-1 ? "none" : "4px solid rgba(131, 56, 236, 0.1)",
                width:"100%",paddingLeft:40,paddingRight:50,paddingBottom:index===items.length-1 ?10:5,paddingTop:5,
                cursor:"pointer",userSelect:"none"}}
                onClick={()=>item.action()}>
                    <div>{item.titolo}</div>
                    <div>{item.icon}</div>
                </div>)}
                </div>
            </div>
        </Modal>
    )
}
export default MenuGestioneUtenti;