/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { IconBell, IconChevronLeft, IconPencil, IconX } from "@tabler/icons-react";
import { Badge, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteStaff, getNotificheCount, getStaff } from "../Utils/ApiUtils";
import { openWebsiteInNewTab, showPropsConfirm } from "../Utils/FunctionUtils";
import ErrorPage from "./Components/misc/ErrorPage";
import AggiungiStaff from "./Components/staff/AggiungiStaff";
import { useTokenActions } from "../Utils/tokenUtils";
import useWindowDimensions from "../Utils/windowDimension";
const Staff = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const isAdmin = useSelector(state => state.role);
    const { handleRemoveToken } = useTokenActions();
    const { height } = useWindowDimensions();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);
    const [staff, setStaff] = useState(null);
    const [aggiungi, setAggiungi] = useState(null);

    const cancellaStaffer = (item) => {
        const okType = "primary";
        const cancelText = "Annulla"

        const title = "Cancellare questo membro dello staff?";
        const content = "Sei sicuro di voler cancellare il membro dello staff: " + item.nome + "?";
        const okText = "Cancella";
        const onOk = () => deleteStaff(setLoading, setStaff, setError, item.id, handleRemoveToken, navigate);
        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    useEffect(() => {
        getStaff(setLoading, setStaff, setError);
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
        }

        const interval = setInterval(() => {
            getStaff(setLoading, setStaff);
            if (token && token !== null) {
                getNotificheCount(setNotificheCount);
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {staff && staff !== null && staff.length > 0 ?
                                    <div style={{
                                        width: "100%", height: height-305, overflowY: "auto", paddingLeft: 10, paddingRight: 10, marginTop: 30,
                                        maxWidth: 800
                                    }}>
                                        {staff.map((item, index) =>
                                            <div key={index}
                                                style={{
                                                    userSelect: "none",
                                                    marginBottom: 10,
                                                    borderBottom: "1px solid rgba(100, 100, 100, 0.1)",
                                                    display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%",
                                                    borderRadius: 10, backgroundColor: "rgba(255, 255, 255, 0.6)"
                                                }}>
                                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                    {isAdmin &&
                                                        <div style={{ alignSelf: "flex-start",margin:3 }}>
                                                            <div style={{lineHeight:0}}><IconX color="red" style={{cursor:"pointer",userSelect:"none"}} 
                                                            onClick={()=>cancellaStaffer(item)}/></div>
                                                            <div style={{lineHeight:0}}><IconPencil color="#ffb60b" style={{cursor:"pointer",userSelect:"none"}} 
                                                            onClick={()=>setAggiungi(item)}/></div>
                                                        </div>}
                                                    <div style={{padding: 15,paddingRight:0}}>
                                                        <div
                                                            style={{
                                                                color: "#A00C98", fontSize: 16, fontWeight: 600, fontFamily: "Poppins",
                                                                lineHeight: 1
                                                            }}>
                                                            {item.nome + " " + item.cognome}
                                                        </div>
                                                        <div style={{ color: "#fb5607", fontSize: 14, fontWeight: 500, userSelect: "none" }}>{item.ruolo}</div>
                                                    </div>
                                                </div>
                                                {item.instagram && <div style={{ cursor: "pointer", userSelect: "none",paddingRight:15 }}>
                                                    <img src="instagram.png" alt={index} style={{ width: "100%", height: "auto", maxWidth: 20, maxHeight: 20 }}
                                                        onClick={() => openWebsiteInNewTab(item.instagram)}
                                                    />
                                                </div>}
                                            </div>
                                        )}
                                    </div> :
                                    <div style={{ marginBottom: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 15, borderRadius: 10, marginTop: 30 }}>
                                        <div style={{ color: "#A00C98", fontSize: 14 }}>Nessun membro dello staff al momento</div>
                                    </div>}
                            </div>
                            {isAdmin &&
                                <center style={{ marginTop: 10 }}>
                                    <Button type="primary" shape="round" size="large"
                                        onClick={() => setAggiungi({ id: null })}>
                                        Aggiungi membro
                                    </Button>
                                </center>}
                        </div>
                    </div>
                    <AggiungiStaff visible={aggiungi} setVisible={setAggiungi}
                        handleRemoveToken={handleRemoveToken} navigate={navigate} setLoading={setLoading}
                        setStaff={setStaff} setError={setError}/>
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    getStaff(setLoading, setStaff, setError);
                }} />}
        </div>
    )
}
export default Staff;