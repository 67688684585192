/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from "@ant-design/icons";
import { IconBell, IconChevronLeft, IconChevronRight, IconPlus, IconUser } from "@tabler/icons-react";
import { Avatar, Badge, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { downloadRole, getMenu, getNotificheCount } from "../Utils/ApiUtils";
import CreaGruppo from "./Components/menu/CreaGruppo";
import ErrorPage from "./Components/misc/ErrorPage";
import { useTokenActions } from "../Utils/tokenUtils";
import useWindowDimensions from "../Utils/windowDimension";
const Menu = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const isAdmin = useSelector(state => state.role);

    const { handleRemoveToken,handleRemoveRole,handleSetRole } = useTokenActions();
    const { height } = useWindowDimensions();

    const [error, setError] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);
    const [profilo, setProfilo] = useState(null);
    const [loadingProfilo, setLoadingProfilo] = useState(false);
    const [creaGruppo, setCreaGruppo] = useState(false);

    const aggiungiGruppo = () => {
        setCreaGruppo(true);
    }

    const items = [
        {
            titolo: "Prenotazioni", descrizione: "I miei ticket",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/prenotazioni'),
            iconAction: () => navigate('/prenotazioni'), onlyLogin:true
        },
        {
            titolo: "Statistiche", descrizione: "Visualizza le statistiche generali",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/statistiche/generali'),
            iconAction: () => navigate('/statistiche/generali'), onlyLogin:true,onlyAdmin:true
        },
        {
            titolo: "Utenti", descrizione: "Gestisci gli utenti",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/utenti'),
            iconAction: () => navigate('/utenti'), onlyLogin:true,onlyAdmin:true
        },
        {
            titolo: "Scannerizza", descrizione: "Convalida i biglietti di ingresso",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/check-qr/verifica'),
            iconAction: () => navigate('/check-qr/verifica'), onlyLogin:true,onlyAdmin:true
        },
        {
            titolo: "Modifica schermata home", descrizione: "Cambia la gif della schermata home",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/modifica-homepage'),
            iconAction: () => navigate('/modifica-homepage'), onlyLogin:true,onlyAdmin:true
        },
        {
            titolo: "Gruppi", descrizione: "Gestisci i tuoi gruppi",
            icon: <IconPlus color="#fff" />, action: () => navigate('/gruppi'),
            iconAction: () => aggiungiGruppo(), onlyLogin:true
        },
        {
            titolo: "Impostazioni", descrizione: "Modifica il tuo profilo",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/impostazioni'),
            iconAction: () => navigate('/impostazioni'), onlyLogin:true
        },
        {
            titolo: "Calendario", descrizione: "Visualizza la programmazione",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/calendario'),
            iconAction: () => navigate('/calendario')
        },
        {
            titolo: "Avvisi", descrizione: "Gli avvisi dello staff",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/avvisi'),
            iconAction: () => navigate('/avvisi')
        },
        {
            titolo: "Staff", descrizione: "Il nostro Staff",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/staff'),
            iconAction: () => navigate('/staff')
        },
        {
            titolo: "Partners", descrizione: "I nostri partners",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/partners'),
            iconAction: () => navigate('/partners')
        },
        {
            titolo: "Logout", descrizione: "Effettua il logout",
            icon: <IconChevronRight color="#fff" />, action: () => logout(),
            iconAction: () => logout(), onlyLogin:true
        },
    ]

    const logout = () => {
        handleRemoveToken();
        notification.success({
            placement: "top",
            message: 'Logout eseguito',
            description:
                "Logout eseguito con successo",
        });
        setProfilo({ nome: "Effettua il login", email: "" })
    }

    useEffect(() => {
        getMenu(setLoadingProfilo, setProfilo, handleRemoveToken);
        if (token && token !== null) {
            downloadRole(handleSetRole, handleRemoveRole, navigate);
            getNotificheCount(setNotificheCount);
        }

        const interval = setInterval(() => {
            if (token && token !== null) {
                downloadRole(handleSetRole, handleRemoveRole, navigate);
                getNotificheCount(setNotificheCount);
                getMenu(setLoadingProfilo, setProfilo);
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset", overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left",overflow:"hidden" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0,overflow:"hidden" }}>
                            <div style={{ marginBottom: 30, marginTop: 10, display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 20 }}>
                                <div>
                                    <Avatar size={60} icon={loadingProfilo ? <LoadingOutlined spin /> : <IconUser size={25} />} />
                                </div>
                                {profilo && profilo !== null &&
                                    <div>
                                        <div
                                            style={{
                                                color: "#fff", fontSize: 16, fontWeight: 500,
                                                fontFamily: "Poppins", lineHeight: 1, cursor: profilo.email ? "default" : "pointer",
                                                userSelect: "none"
                                            }} onClick={() => !profilo.email && navigate('/login')}>
                                            {profilo.nome}
                                        </div>
                                        {profilo.email && <div style={{ color: "#A00C98", fontSize: 14, fontWeight: 500 }}>{profilo.email}</div>}
                                    </div>}
                            </div>
                            <div style={{ width: "100%", height: height-305, overflowY: "auto", paddingLeft: 10, paddingRight: 10,maxHeight:"100vh" }}>
                                {(token && token !== null ? isAdmin ? items : items.filter(item => !item.onlyAdmin)
                                    : items.filter(item => !item.onlyLogin))
                                    .map((item, index) =>
                                        <div key={index}
                                            style={{
                                                marginBottom: index === items.length - 1 ? 0 : 20,
                                                borderBottom: "1px solid rgba(100, 100, 100, 0.1)", paddingBottom: 10,
                                                display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"
                                            }}>
                                            <div>
                                                <div onClick={() => item.action()}
                                                    style={{ color: "#fff", fontSize: 16, fontWeight: 500, fontFamily: "Poppins", cursor: "pointer", userSelect: "none" }}>
                                                    {item.titolo}
                                                </div>
                                                <div style={{ color: "#A00C98", fontSize: 14, fontWeight: 500, userSelect: "none" }}>{item.descrizione}</div>
                                            </div>
                                            <div style={{ cursor: "pointer", userSelect: "none" }} onClick={() => item.iconAction()}>
                                                {item.icon}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>

                    <CreaGruppo visible={creaGruppo} setVisible={setCreaGruppo} setLoading={null} setGruppi={null} setError={null} />

                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    getMenu(setLoadingProfilo, setProfilo);
                }} />}
        </div>
    )
}
export default Menu;