/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay, Stack, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconBell, IconChevronLeft, IconMail, IconPencil } from "@tabler/icons-react";
import { Badge, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { contattaStaff, getContatti, getNotificheCount } from "../Utils/ApiUtils";
import { apriTelefono, apriWhatsapp, openWebsiteInNewTab } from "../Utils/FunctionUtils";
import AggiungiContatti from "./Components/contatti/AggiungiContatti";
import ErrorPage from "./Components/misc/ErrorPage";
import { useTokenActions } from "../Utils/tokenUtils";
import useWindowDimensions from "../Utils/windowDimension";
const Contatti = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const isAdmin = useSelector(state => state.role);
    const { handleRemoveToken } = useTokenActions();
    const { height } = useWindowDimensions();

    const labelProps = { style: { color: "#fb5607", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "none", color: "rgb(160, 12, 152)", backgroundColor: "#fff" }, error: { color: "#fff", fontFamily: "Poppins", fontSize: 12 } };

    const [loading, setLoading] = useState(false);
    const [contatti, setContatti] = useState(null);
    const [error, setError] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);
    const [aggiungi, setAggiungi] = useState(null);

    const form = useForm({
        initialValues: {
            email: "",
            nome: "",
            messaggio: "",
        },

        validate: {
            nome: (val) => (typeof val === 'undefined' || val === null || val.trim().length <= 0 ? 'Inserisci il tuo nome' : null),
            email: (val) => (typeof val === 'undefined' || val === null || val.trim().length <= 0 ? 'Inserisci la tua email' : null),
            messaggio: (val) => (typeof val === 'undefined' || val === null || val.trim().length <= 0 ? 'Inserisci un messaggio' : null),
        }
    })

    useEffect(() => {
        getContatti(setContatti, setLoading, setError);
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
        }

        const interval = setInterval(() => {
            getContatti(setContatti, setLoading, setError);
            if (token && token !== null) {
                getNotificheCount(setNotificheCount);
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{
                                width: "100%", height: height-230, overflowY: "auto",
                                display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column",
                                marginTop: 20
                            }}>
                                <form
                                    style={{ maxWidth: 400, width: "100%", paddingBottom: 10 }}
                                    onSubmit={form.onSubmit((values) =>
                                        contattaStaff(values, setLoading, form, setError))}>
                                    <Stack style={{ borderRadius: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 20, border: "2px solid #fb5607" }}>
                                        <TextInput
                                            required
                                            label="Email"
                                            placeholder="La tua email"
                                            type="email"
                                            value={form.values.email}
                                            error={form.errors.email}
                                            onChange={(event) => form.setFieldValue("email", event.target.value)}
                                            radius="lg"
                                            size='xl'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <TextInput
                                            required
                                            label="Nome"
                                            placeholder="Il Tuo Nome"
                                            value={form.values.nome}
                                            error={form.errors.nome}
                                            onChange={(event) => form.setFieldValue("nome", event.target.value)}
                                            radius="lg"
                                            size='xl'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <Textarea
                                            required
                                            minRows={4}
                                            mt="md"
                                            label="Messaggio"
                                            placeholder="Scrivi un messaggio.."
                                            value={form.values.messaggio}
                                            error={form.errors.messaggio}
                                            onChange={(event) => form.setFieldValue("messaggio", event.target.value)}
                                            radius="lg"
                                            size='xl'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                    </Stack>
                                    <div style={{ textAlign: "center" }}>
                                        <Button
                                            loading={loading}
                                            type="primary"
                                            size="large"
                                            shape="round"
                                            htmlType="submit"
                                            style={{ marginTop: 10, minWidth: 180, fontWeight: 600, fontFamily: "Poppins" }}>
                                            Invia
                                        </Button>
                                    </div>
                                </form>
                                {((contatti && contatti !== null && contatti.email) || isAdmin) &&
                                    <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", gap: 5 }}>
                                        {isAdmin &&
                                            <IconPencil color="#fb5607" fill="rgb(160, 12, 152)" size={35} style={{ cursor: "pointer", userSelect: "none" }}
                                                onClick={() => setAggiungi(contatti && contatti !== null ? contatti : { id: null })} />}
                                        {contatti && contatti !== null && contatti.email &&
                                            <IconMail color="#fb5607" fill="rgb(160, 12, 152)" size={35} style={{ cursor: "pointer", userSelect: "none" }} />}
                                        {contatti && contatti !== null && contatti.email &&
                                            <div style={{ color: "rgb(160, 12, 152)", fontFamily: "Poppins", fontSize: 14, fontWeight: 600 }}> {contatti.email} </div>}
                                    </div>}

                                {contatti && contatti !== null && (contatti.instragram || contatti.facebook || contatti.cellulare)
                                    && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", width: "100%", gap: 15, marginTop: 10 }}>
                                    {contatti.cellulare &&
                                            <img src="call.png" alt={"call"} style={{
                                                width: "100%", height: "auto", maxWidth: 20, maxHeight: 20,
                                                cursor: "pointer", userSelect: "none"
                                            }} onClick={() => apriTelefono(contatti.cellulare)} />}
                                    {contatti.cellulare &&
                                            <img src="whatsapp.png" alt={"whatsapp"} style={{
                                                width: "100%", height: "auto", maxWidth: 25, maxHeight: 25,
                                                cursor: "pointer", userSelect: "none"
                                            }} onClick={() => apriWhatsapp(contatti.cellulare,"")} />}
                                        {contatti.instagram &&
                                            <img src="instagramFilled.png" alt={"instagram"} style={{
                                                width: "100%", height: "auto", maxWidth: 20, maxHeight: 20,
                                                cursor: "pointer", userSelect: "none"
                                            }} onClick={() => openWebsiteInNewTab(contatti.instagram)} />}
                                        {contatti.facebook &&
                                            <img src="facebook.png" alt={"facebook"} style={{
                                                width: "100%", height: "auto", maxWidth: 20, maxHeight: 20,
                                                cursor: "pointer", userSelect: "none"
                                            }}
                                                onClick={() => openWebsiteInNewTab(contatti.facebook)} />}
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <AggiungiContatti visible={aggiungi} setVisible={setAggiungi}
                        handleRemoveToken={handleRemoveToken} navigate={navigate} setLoading={setLoading}
                        setContatti={setContatti} setError={setError} />
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    getContatti(setContatti, setLoading, setError);
                }} />}
        </div>
    )
}
export default Contatti;