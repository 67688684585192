/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { Modal } from "antd";
import React, { useState } from "react";
import { cambiaRuoloUtente } from "../../../Utils/ApiUtils";
import { traduciRuolo } from "../../../Utils/FunctionUtils";

const MenuCambiaRuolo = props => {
    const { visible, setVisible,page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError } = props;

    const [loadingModal, setLoadingModal] = useState(false);

    const items = [
        "ADMIN", "CONTROLLORE", "USER"
    ]

    return (
        <Modal
            open={visible}
            style={{ maxWidth: 350, minWidth: 200 }}
            width={"92%"}
            onCancel={() => setVisible(null)}
            title={null}
            footer={null}
            bodyStyle={{ padding: 0, margin: 0 }}
            className="no-padding-modal">
            <div>
                <LoadingOverlay visible={loadingModal} />
                <div style={{ paddingTop: 50 }}>
                    {items.map((item, index) => item === visible.ruolo ? null :
                        <div key={index} style={{
                            display: "flex", justifyContent: "center",
                            alignItems: "center", color: "rgba(131, 56, 236, 1)", fontFamily: "Poppins", fontSize: 16, fontWeight: 600,
                            borderBottom: index === items.length - 1 ? "none" : "4px solid rgba(131, 56, 236, 0.1)",
                            width: "100%", paddingLeft: 40, paddingRight: 50, paddingBottom: index === items.length - 1 ? 10 : 5, paddingTop: 5,
                            cursor: "pointer", userSelect: "none"
                        }}
                            onClick={() => cambiaRuoloUtente(visible.id, setLoadingModal,
                                page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError, item, setVisible)}>
                            <div>{traduciRuolo(item)}</div>
                        </div>)}
                </div>
            </div>
        </Modal>
    )
}
export default MenuCambiaRuolo;