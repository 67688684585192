/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, LoadingOverlay, Select, Stack, TextInput, Textarea } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { IconBell, IconChevronLeft, IconPlus } from '@tabler/icons-react';
import { Badge, Button, Upload, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { aggiungiEvento, downloadEventById, getNotificheCount, removeImage } from '../Utils/ApiUtils';
import { validateUrl } from '../Utils/FunctionUtils';
import { useTokenActions } from '../Utils/tokenUtils';
import ErrorPage from './Components/misc/ErrorPage';
import useWindowDimensions from '../Utils/windowDimension';

export default function AggiungiEvento() {
    const navigate = useNavigate();
    const { id } = useParams();

    const token = useSelector(state => state.token);
    const { height } = useWindowDimensions();
    const { handleRemoveToken } = useTokenActions();

    const isNew = id === 'new';
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);

    const labelProps = { style: { color: "#fff", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "none", backgroundColor: "#fff", color: "rgba(131, 56, 236, 1)", fontFamily: "Poppins" }, error: { color: "#fb5607", fontFamily: "Poppins", fontSize: 12 } };

    useEffect(() => {
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
        } else {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }

        if (id !== 'new') {
            downloadEventById(id, setLoading, form, setFileList, handleRemoveToken, navigate, setError);
        }
        const interval = setInterval(() => {
            getNotificheCount(setNotificheCount);
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    const [fileList, setFileList] = useState([]);

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const form = useForm({
        initialValues: {
            titolo: '',
            descrizione: '',
            startDate: new Date(),
            indirizzo: '',
            capienzaMassima: 0,
            capienzaPubblica: false,
            costoPubblico: false,
            prenotatiPubblica: false,
            entratiPubblica: false,
            limiteAnnullamento: 0,
            costo: '',
            alertPrePrenotazione: '',
            capienzaCalcolata: true
        },
        validate: {
            indirizzo: val => val.length <= 0 || validateUrl(val) ? null : "Inserire un url valido"
        }
    });

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflowY: "auto", height: height-50 }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ color: "#fff", fontSize: 30, marginTop: 10, fontWeight: "bolder", marginBottom: 30 }}>
                                {isNew ? "Aggiungi " : "Modifica "} Evento
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <form onSubmit={form.onSubmit((values) => aggiungiEvento(id, isNew, values, setLoading, fileList, token, handleRemoveToken, navigate, setError))}>
                                    <Stack>
                                        <TextInput
                                            required
                                            label="Titolo"
                                            placeholder="Titolo dell'evento"
                                            value={form.values.titolo}
                                            onChange={(event) => form.setFieldValue('titolo', event.target.value)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <Textarea
                                            required
                                            label="Descrizione"
                                            placeholder="Descrizione dell'evento"
                                            value={form.values.descrizione}
                                            onChange={(event) => form.setFieldValue('descrizione', event.target.value)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <DateTimePicker
                                            required
                                            placeholder="Data di inizio"
                                            label="Data di inizio"
                                            clearable
                                            minDate={new Date()}
                                            value={form.values.startDate}
                                            onChange={value => form.setFieldValue('startDate', value)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <TextInput

                                            label="Link indirizzo"
                                            placeholder="Link all'indirizzo di Maps"
                                            value={form.values.indirizzo}
                                            error={form.errors.indirizzo}
                                            onChange={(event) => form.setFieldValue('indirizzo', event.target.value)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <TextInput
                                            required
                                            type="number"
                                            min={0}
                                            label="Costo biglietto"
                                            placeholder="Costo del biglietto"
                                            value={form.values.costo}
                                            onChange={(event) => form.setFieldValue('costo', event.target.value)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />


                                        <TextInput
                                            required
                                            type="number"
                                            min={0}
                                            label="Capienza massima"
                                            placeholder="Numero massimo di partecipanti"
                                            value={form.values.capienzaMassima}
                                            onChange={(event) => form.setFieldValue('capienzaMassima', event.target.value)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <Select
                                            required
                                            label="Capienza visualizzata"
                                            placeholder="Capienza da visualizzare"
                                            data={[
                                                { label: 'Calcolata', value: true },
                                                { label: 'Manuale', value: false },
                                            ]}
                                            value={form.values.capienzaCalcolata}
                                            onChange={(value) => form.setFieldValue('capienzaCalcolata', value)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <Checkbox
                                            style={{ marginTop: 20 }}
                                            label={<span style={{ color: "#fff", fontFamily: "Poppins" }}>Posti visibili a tutti</span>}
                                            checked={form.values.capienzaPubblica}
                                            onChange={(event) => form.setFieldValue('capienzaPubblica', event.target.checked)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <Checkbox
                                            label={<span style={{ color: "#fff", fontFamily: "Poppins" }}>Costo visibile a tutti</span>}
                                            checked={form.values.costoPubblico}
                                            onChange={(event) => form.setFieldValue('costoPubblico', event.target.checked)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <Checkbox
                                            label={<span style={{ color: "#fff", fontFamily: "Poppins" }}>Prenotati visibili a tutti</span>}
                                            checked={form.values.prenotatiPubblica}
                                            onChange={(event) => form.setFieldValue('prenotatiPubblica', event.target.checked)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <Checkbox
                                            style={{ marginBottom: 10 }}
                                            label={<span style={{ color: "#fff", fontFamily: "Poppins" }}>Entrati visibili a tutti</span>}
                                            checked={form.values.entratiPubblica}
                                            onChange={(event) => form.setFieldValue('entratiPubblica', event.target.checked)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <Select
                                            required
                                            label="Tempo massimo annullamento prenotazione"
                                            placeholder="Tempo massimo per l'annullamento"
                                            data={[
                                                { label: 'Nessun tempo massimo', value: 0 },
                                                { label: '1 ora', value: 1 },
                                                { label: '2 ore', value: 2 },
                                                { label: '4 ore', value: 4 },
                                                { label: '12 ore', value: 12 },
                                                { label: '24 ore', value: 24 },
                                            ]}
                                            value={form.values.limiteAnnullamento}
                                            onChange={(value) => form.setFieldValue('limiteAnnullamento', value)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <Textarea
                                            required
                                            label="Messaggio prenotazione"
                                            placeholder="Messaggio di alert da mostrare prima della prenotazione"
                                            value={form.values.alertPrePrenotazione}
                                            onChange={(event) => form.setFieldValue('alertPrePrenotazione', event.target.value)}
                                            radius="lg"
                                            size='lg'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <div style={{ marginTop: 20 }}>
                                            <div style={{ fontWeight: 600, fontFamily: "Poppins", color: "#fff", fontSize: 14, marginBottom: 5 }}>
                                                Immagini di copertina*
                                            </div>
                                            <Upload
                                                accept="image/*"
                                                onRemove={v => id !== 'new' && removeImage(id,v,handleRemoveToken,navigate,setFileList)}
                                                listType="picture-card"
                                                fileList={fileList}
                                                onChange={handleChange}
                                                className="event-upload"
                                                customRequest={dummyRequest}>
                                                {fileList.length >= 8 ? null : <div>
                                                    <IconPlus color="rgba(131, 56, 236, 1)" stroke={4} />
                                                </div>}
                                            </Upload>
                                        </div>
                                        <div style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}>
                                            <Button className="btn_secondary"
                                                loading={loading}
                                                size="large" shape="round"
                                                type="primary" htmlType='submit'>{isNew ? "Aggiungi evento" : "Modifica evento"}</Button>
                                        </div>
                                    </Stack>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> : <ErrorPage onClick={() => {
                    setError(false);
                }} />}
        </div>
    );
}