import { LoadingOverlay } from '@mantine/core';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { Provider } from 'react-redux';
import {
    Route,
    BrowserRouter as Router,
    Routes,
    useBeforeUnload
} from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../Utils/redux/store/store';
import AggiungiEvento from './AggiungiEvento';
import AmministraPrenotazioni from './AmministraPrenotazioni';
import AmministraPrenotazioniAggiungi from './AmministraPrenotazioniAggiungi';
import AmministraPrenotazioniAttesa from './AmministraPrenotazioniAttesa';
import AmministraPrenotazioniEffettuate from './AmministraPrenotazioniEffettuate';
import Avvisi from './Avvisi';
import Calendario from './Calendario';
import CheckQr from './CheckQr';
import Navigation from './Components/misc/Navigation';
import Completion from './Components/payment/Completion';
import Payment from './Components/payment/Payment';
import Contatti from './Contatti';
import Entrypage from './Entrypage';
import Eventi from './Eventi';
import GestioneUtenti from './GestioneUtenti';
import Gruppi from './Gruppi';
import GruppiDettaglio from './GruppiDettaglio';
import Homepage from './Homepage';
import Impostazioni from './Impostazioni';
import InvioEmail from './InvioEmail';
import InvioNotifica from './InvioNotifica';
import Login from './Login';
import Menu from './Menu';
import MissingPage from './MissingPage';
import ModificaHomepage from './ModificaHomepage';
import Notifiche from './Notifiche';
import Partners from './Partners';
import PrenotazioneGruppo from './PrenotazioneGruppo';
import Prenotazioni from './Prenotazioni';
import PrenotazioniAttesa from './PrenotazioniAttesa';
import PrenotazioniAttuali from './PrenotazioniAttuali';
import PrenotazioniPassate from './PrenotazioniPassate';
import RecuperaCredenziali from './RecuperaCredenziali';
import Registrazione from './Registrazione';
import Staff from './Staff';
import Statistiche from './Statistiche';
import './css/App.css';

function App() {
    const [visible, setVisible] = useState(true);

    useBeforeUnload((event) => {
        event.preventDefault();
        event.returnValue = '';
        return 'Sei sicuro di voler lasciare Candizioland?';
    });

    const [stripePromise,] = useState(loadStripe("pk_test_51NlC3aL1KbAsl5VyVjPVZu6zw9o6kRNEQThc2bqiaXm9jt2svT7Ec1YXjfOevuU0UIhA8qHXc4qtAuztAzo8ynrS000CaIHg1p"));

    return (
        <Provider store={store}>
            <PersistGate loading={<LoadingOverlay visible={visible} overlayBlur={2} />} persistor={persistor} onBeforeLift={() => setVisible(false)}>
                <Router>
                    <Navigation />
                    {/*<Menu setApriMenu={setApriMenu} apriMenu={apriMenu} />*/}
                    <Routes>
                        <Route path="/" element={<Entrypage />} />
                        <Route path="/contatti" element={<Contatti />} />
                        <Route path="/homepage" element={<Homepage />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/registrazione" element={<Registrazione />} />
                        <Route path="/recupera-credenziali" element={<RecuperaCredenziali />} />
                        <Route path="/eventi" element={<Eventi />} />
                        <Route path="/evento/:id" element={<AggiungiEvento />} />
                        <Route path="/calendario" element={<Calendario />} />
                        <Route path="/notifiche" element={<Notifiche />} />
                        <Route path="/menu" element={<Menu />} />
                        <Route path="/staff" element={<Staff />} />
                        <Route path="/avvisi" element={<Avvisi />} />
                        <Route path="/partners" element={<Partners />} />
                        <Route path="/gruppi" element={<Gruppi />} />
                        <Route path="/gruppo/:id" element={<GruppiDettaglio />} />
                        <Route path="/impostazioni" element={<Impostazioni />} />
                        <Route path="/prenotazioni" element={<Prenotazioni />} />
                        <Route path="/prenotazioni-attuali" element={<PrenotazioniAttuali />} />
                        <Route path="/prenotazioni-attesa" element={<PrenotazioniAttesa />} />
                        <Route path="/prenotazioni-passate" element={<PrenotazioniPassate />} />
                        <Route path="/statistiche/:id" element={<Statistiche />} />
                        <Route path="/modifica-homepage" element={<ModificaHomepage />} />
                        <Route path="/utenti" element={<GestioneUtenti />} />
                        <Route path="/invio-email" element={<InvioEmail />} />
                        <Route path="/invio-notifica" element={<InvioNotifica />} />
                        <Route path="/prenotazione-gruppo/:id" element={<PrenotazioneGruppo />} />
                        <Route path="/amministra-prenotazioni/:id" element={<AmministraPrenotazioni />} />
                        <Route path="/amministra-prenotazioni-effettuate/:id" element={<AmministraPrenotazioniEffettuate />} />
                        <Route path="/amministra-prenotazioni-attesa/:id" element={<AmministraPrenotazioniAttesa />} />
                        <Route path="/amministra-prenotazioni-aggiungi/:id" element={<AmministraPrenotazioniAggiungi />} />
                        <Route path="/check-qr/:qr" element={<CheckQr />} />
                        <Route path="/pagamento" element={<Payment stripePromise={stripePromise} />} />
                        <Route path="/completion" element={<Completion stripePromise={stripePromise} />} />
                        <Route path="*" element={<MissingPage />} />
                    </Routes>
                </Router>
            </PersistGate>
        </Provider>
    );
}

export default App;
