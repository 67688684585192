import { Modal } from "antd";
import moment from "moment";
import { disdiciEvento, isEventoPrenotabile, isUserPrenotato } from "./ApiUtils";
import { API } from "./Request";

export const getImage = (url) => API.getImage + '?url=' + url;

export const getBtnPrenotaText = (isAdmin, item, token, prenotazione) => {
    if (isAdmin) {
        return item.prenotabile ? "Blocca prenotazioni" : "Sblocca prenotazioni";
    } else {
        if (token === null) {
            return "Accedi per prenotare";
        } else if (prenotazione && prenotazione !== null) {
            return prenotazione.attesa ? "In attesa di conferma" : "Visualizza Biglietto";
        } else {
            return "Prenota ora";
        }
    }
}

export const calcolaCapienzaRimanente = item => {
    let result = 100;
    if (item.capienzaCalcolata) {
        if (item.prenotati && item.capienzaMassima && item.capienzaMassima > 0) {
            result = Math.round(100 - ((item.prenotati / item.capienzaMassima) * 100));
        } else {
            result = 100;
        }
    } else {
        result = (100 - item.perCapienza);
    }
    if (result < 0)
        result = 0;
    return result;
}

export const showPropsConfirm = (title, content, okText, okType, cancelText, onOk, onCancel) => {
    let loading = false;
    Modal.confirm({
        title: <center style={{ color: "rgb(160, 12, 152)", fontFamily: "Poppins", fontWeight: 600 }}>{title}</center>,
        icon: null,
        content: <center style={{ color: "#000", fontFamily: "Poppins", fontWeight: 400, marginTop: 20, marginBottom: 20 }}>
            {content}</center>,
        okText: okText,
        okType: okType,
        okButtonProps: {
            loading: loading,
        },
        cancelButtonProps: {
            style: { visibility: cancelText === null ? "hidden" : "visible" }
        },
        cancelText: cancelText,
        onOk() {
            loading = true;
            onOk();
        },
        onCancel() {
            onCancel();
        },
    });
};

export const btnPrenotaOraAction = (id, titolo, limiteAnnullamento, startDate, prenotaText, setLoadingBtnPrenota, setLoading, setError, setEventi,
    handleRemoveToken, navigate, isAdmin, loading, setBiglietto, token, setPrenotazioni) => {

    if (token === null) {
        navigate('/login');
        return;
    }
    if (isAdmin) {
        isEventoPrenotabile(id, setLoadingBtnPrenota, setLoading, setError, setEventi, handleRemoveToken, navigate, loading);
        return;
    } else {
        isUserPrenotato(id, titolo, limiteAnnullamento, startDate, prenotaText, setLoadingBtnPrenota, setLoading, setError, setEventi, handleRemoveToken, navigate, loading, setBiglietto, token, setPrenotazioni)
    }
}

export const getPrenotazioneByEventId = (prenotazioni, id) => {
    if (prenotazioni && prenotazioni.length > 0)
        return prenotazioni.find(prenotazione => prenotazione.idEvento.toString() === id.toString())
    else return null;
}

export const disdiciEventoWrap = (id, limiteAnnullamento, startDate, setLoadingAnnulla, loading, setLoading, setError, setEventi, setPrenotazioni, handleRemoveToken, navigate, setBiglietto) => {
    const okText = "Conferma";
    const okType = "primary";
    const cancelText = "Annulla"
    const onCancel = () => { return };
    const title = "Vuoi annullare la prenotazione?";

    const now = moment();
    const dataEventoLimite = moment(startDate).subtract(limiteAnnullamento, 'hour');
    const limiteSuperato = now.isAfter(dataEventoLimite);
    let content = "Sei sicuro di voler annullare la prenotazione?";
    if (limiteSuperato) {
        content = "Hai superato il limite di tempo massimo per l'annullamento della prenotazione di " + limiteAnnullamento + " ore, " +
            "se è la terza volta che annulli una prenotazione dopo il tempo limite, verrai bannato!"
    }
    const onOk = () => disdiciEvento(id, setLoadingAnnulla, loading, setLoading, setError, setEventi, setPrenotazioni, handleRemoveToken, navigate, setBiglietto);
    showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
}

export const openWebsiteInNewTab = (link) => {

    let url = null;
    if (link.startsWith("https://") || link.startsWith("http://")) {
        url = link;
    } else url = "https://" + link;

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export function apriTelefono(numero) {
    window.open('tel:' + numero, '_blank');
};

export function apriWhatsapp(cellulare, msg) {
    const prefissoDefault = "+39";
    const whatsappUrl = "https://wa.me/";

    // Aggiungi il prefisso +39 se il numero di cellulare non ha un prefisso
    if (!cellulare.startsWith("+")) {
        cellulare = prefissoDefault + cellulare;
    }

    const url = whatsappUrl + cellulare + "?text=" + encodeURIComponent(msg);
    var win = window.open(url, '_blank');
    win.focus();
}

export function validateUrl(url) {
    const regex = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    return regex.test(url);
}

export const traduciRuolo = role => {
    switch (role) {
        case 'ADMIN':
            return 'Amministratore';
        case 'USER':
            return 'Utente';
        case 'CONTROLLORE':
            return 'Controllore';
        default:
            return 'Non specificato';
    }
}
