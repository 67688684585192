import {
  Button,
  Container,
  Image,
  SimpleGrid,
  Text,
  Title,
  createStyles
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
  
  const useStyles = createStyles((theme) => ({
    root: {
      paddingTop: 80,
      paddingBottom: 80,
    },
  
    title: {
      fontWeight: 900,
      fontSize: 34,
      marginBottom: theme.spacing.md,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      color:"#fff",
      [theme.fn.smallerThan('sm')]: {
        fontSize: 32,
      },
    },
  
    control: {
      [theme.fn.smallerThan('sm')]: {
        width: '100%',
      },
    },
  
    mobileImage: {
      [theme.fn.largerThan('sm')]: {
        display: 'none',
      },
    },
  
    desktopImage: {
      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },
  }));
  
  export default function MissingPage() {
    const { classes } = useStyles();
    const navigate = useNavigate();
    
    return (
      <Container className={classes.root}>
        <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}>
          <Image src={"https://ui.mantine.dev/_next/static/media/image.11cd6c19.svg"} className={classes.mobileImage} />
          <div>
            <Title className={classes.title}>Qualcosa è andato storto...</Title>
            <Text color="#fff" size="lg">
              La pagina che stai provando ad aprire non esiste. Forse hai scritto male l'indirizzo, oppure la pagina è stata spostata su un'altro URL.
              Se pensi che sia un'errore,contatta il supporto.
            </Text>
            <Button size="md" mt="xl" className={classes.control} color="pink"
            onClick={()=>navigate(-1)}>
              Torna indietro
            </Button>
          </div>
          <Image src={"https://ui.mantine.dev/_next/static/media/image.11cd6c19.svg"} className={classes.desktopImage} />
        </SimpleGrid>
      </Container>
    );
  }