/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { IconBell, IconChevronLeft } from "@tabler/icons-react";
import { Badge } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBiglietto, getNotificheCount, getPrenotazioniAttuali } from "../Utils/ApiUtils";
import { useTokenActions } from "../Utils/tokenUtils";
import ErrorPage from "./Components/misc/ErrorPage";
import moment from "moment";
import Biglietto from "./Components/eventi/Biglietto";
import useWindowDimensions from '../Utils/windowDimension';

const PrenotazioniAttuali = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const { handleRemoveToken } = useTokenActions();
    const { height } = useWindowDimensions();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);
    const [prenotazioniAttuali, setPrenotazioniAttuali] = useState(null);
    const [biglietto, setBiglietto] = useState(null);

    const visualizzaPrenotazione = item => {
        getBiglietto(item.idEvento, item.nomeEvento, item.limiteAnnullamento, item.dataEvento, 
            setLoading, setError, setBiglietto, token, handleRemoveToken, navigate);
    }

    useEffect(() => {
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
            getPrenotazioniAttuali(setLoading, setPrenotazioniAttuali, handleRemoveToken, navigate, setError)
            const interval = setInterval(() => {
                getNotificheCount(setNotificheCount);
                getPrenotazioniAttuali(setLoading, setPrenotazioniAttuali, handleRemoveToken, navigate, setError)
            }, 300000);
            return () => clearInterval(interval);
        } else navigate(-1);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ marginTop: 10, color: "#fff", fontSize: 30, fontWeight: "bolder" }}>
                                Ticket attivi
                            </div>
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            {prenotazioniAttuali && prenotazioniAttuali !== null && prenotazioniAttuali.length > 0 ?
                                <div style={{ width: "100%", height: height-275, overflowY: "auto", marginTop: 15,maxWidth:800 }}>
                                    {prenotazioniAttuali.map((item, index) =>
                                        <div key={index}
                                            style={{
                                                marginBottom: 20,
                                                borderBottom: "1px solid rgba(100, 100, 100, 0.1)",
                                                width: "100%",
                                                borderRadius: 7, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 15, paddingBottom: 12,minHeight:138
                                            }}>
                                            <div style={{
                                                color: "#A00C98", fontSize: 14, fontWeight: 600, fontFamily: "Poppins", userSelect: "none"
                                            }}>
                                                {item.nomeEvento}
                                            </div>
                                            <div style={{
                                                color: "#fb5607", fontSize: 12, fontFamily: "Poppins", userSelect: "none"
                                            }}>
                                                {moment(item.dataEvento).format("DD-MM-YYYY")}
                                            </div>
                                            <div style={{
                                                textAlign: "right", marginTop: 20
                                            }}>
                                                <span onClick={() => visualizzaPrenotazione(item)}
                                                    style={{ color: "#fb5607", fontSize: 14, marginRight: 15, userSelect: "none", cursor: "pointer" }}>
                                                    Visualizza biglietto:</span>
                                                <img src="qr-example.png" alt={index} onClick={() => visualizzaPrenotazione(item)}
                                                    style={{ width: "100%", height: "auto", maxWidth: 30, userSelect: "none", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    )}
                                </div> :
                                <div style={{ marginBottom: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 15, borderRadius: 10, marginTop: 30 }}>
                                    <div style={{ color: "#A00C98", fontSize: 14 }}>Nessun ticket attivo al momento</div>
                                </div>}
                                </div>
                        </div>
                    </div>

                    <Biglietto biglietto={biglietto} setBiglietto={setBiglietto}
                        loading={loading} setLoading={setLoading} setError={setError} setEventi={null}
                        setPrenotazioni={setPrenotazioniAttuali} handleRemoveToken={handleRemoveToken} navigate={navigate}
                    />

                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    getPrenotazioniAttuali(setLoading, setPrenotazioniAttuali, handleRemoveToken, navigate, setError)
                }} />}
        </div>
    )
}
export default PrenotazioniAttuali;