import { useDispatch } from 'react-redux';
import { removeRole, removeToken, setRole, setToken } from './redux/reducers/reducers';

export const useTokenActions = () => {
  const dispatch = useDispatch();

  const handleSetToken = (token) => {
    dispatch(setToken(token));
  };

  const handleRemoveToken = () => {
    dispatch(removeToken());
    dispatch(removeRole());
  };

  const handleSetRole = (role) => {
    dispatch(setRole(role));
  };

  const handleRemoveRole = () => {
    dispatch(removeRole());
  };

  return { handleSetToken, handleRemoveToken, handleRemoveRole,handleSetRole};
};