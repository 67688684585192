/* eslint-disable react-hooks/exhaustive-deps */
import { IconBell, IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { Badge } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getNotificheCount } from "../Utils/ApiUtils";
import ErrorPage from "./Components/misc/ErrorPage";
import { useSelector } from "react-redux";
const AmministraPrenotazioni = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const { id } = useParams();

    const [error, setError] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);

    const items = [
        {
            titolo: "Prenotazioni", descrizione: "Prenotazioni effettuate",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/amministra-prenotazioni-effettuate/'+id),
            iconAction: () => navigate('/amministra-prenotazioni-effettuate/'+id)
        },
        {
            titolo: "In attesa", descrizione: "Prenotazioni in attesa di conferma",
            icon: <IconChevronRight color="#fff" />, action: () => navigate('/amministra-prenotazioni-attesa/'+id),
            iconAction: () => navigate('/amministra-prenotazioni-attesa/'+id),
        }
    ]

    useEffect(() => {
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
            const interval = setInterval(() => {
                getNotificheCount(setNotificheCount);
            }, 300000);
            return () => clearInterval(interval);
        } else navigate(-1);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 10 }}>
                            <div style={{ color: "#fff", fontSize: 30, marginBottom: 10, fontWeight: "bold", lineHeight: 1.4 }}>
                                Prenotazioni
                            </div>
                            <div style={{ width: "100%", marginTop:30 }}>
                                {items.map((item, index) =>
                                    <div key={index}
                                        style={{
                                            marginBottom: index === items.length - 1 ? 0 : 20,
                                            borderBottom: "1px solid rgba(100, 100, 100, 0.1)", paddingBottom: 10,
                                            display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"
                                        }}>
                                        <div>
                                            <div onClick={() => item.action()}
                                                style={{ color: "#fff", fontSize: 16, fontWeight: 500, fontFamily: "Poppins", cursor: "pointer", userSelect: "none" }}>
                                                {item.titolo}
                                            </div>
                                            <div style={{ color: "#A00C98", fontSize: 14, fontWeight: 500, userSelect: "none" }}>{item.descrizione}</div>
                                        </div>
                                        <div style={{ cursor: "pointer", userSelect: "none" }} onClick={() => item.iconAction()}>
                                            {item.icon}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                }} />}
        </div>
    )
}
export default AmministraPrenotazioni;