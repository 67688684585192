import { LoadingOverlay, PasswordInput, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button, Drawer } from "antd";
import React from "react";
import { changePassword } from "../../../Utils/ApiUtils";

const CambiaPassword = props => {
    const { password, setPassword, loading, setLoading, handleRemoveToken, navigate, setError } = props;

    const labelProps = { style: { color: "rgb(160, 12, 152)", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "none", color: "#fb5607", backgroundColor: "#fff" }, error: { color: "#fff", fontFamily: "Poppins", fontSize: 12 } };

    const passwordForm = useForm({
        initialValues: {
            password: "",
            newPassword: "",
            confirmPassword: "",
        },
        validate: {
            password: (val) => (typeof val === 'undefined' || val === null || val.length <= 6 ? 'La password deve contenere minimo 6 caratteri' : null),
            newPassword: (val) => (typeof val === 'undefined' || val === null || val.trim().length <= 6 ? 'La nuova password deve contenere minimo 6 caratteri' : null),
            confirmPassword: val => val !== passwordForm.values.newPassword ? "Le password non corrispondono" : null
        }
    })

    return (
        <Drawer
            open={password}
            onClose={() => setPassword(false)}
            placement="bottom" height={480}
            style={{ backgroundColor: "#fb5607", borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            maskStyle={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            bodyStyle={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            rootStyle={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            drawerStyle={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            contentWrapperStyle={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
            headerStyle={{ display: "none" }}>
            <div style={{ marginTop: 15, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start" }}>
                <LoadingOverlay visible={loading} />
                <div
                    style={{ color: "#fff", fontFamily: "Poppins", fontWeight: 600, textAlign: "center", fontSize: 16, marginBottom: 20 }}>
                    Cambia Password
                </div>
                <form
                    style={{ maxWidth: 400, width: "100%", paddingBottom: 10 }}
                    onSubmit={passwordForm.onSubmit((values) =>
                        changePassword(values, setLoading, passwordForm, handleRemoveToken, navigate, setError))}>
                    <Stack>
                        <PasswordInput
                            required
                            placeholder={"Vecchia password"}
                            value={passwordForm.values.password}
                            error={passwordForm.errors.password}
                            onChange={(event) => passwordForm.setFieldValue('password', event.currentTarget.value)}
                            radius="md"
                            size='xl'
                            labelProps={labelProps}
                            styles={labelStyles} />

                        <PasswordInput
                            required
                            placeholder={"Nuova password"}
                            value={passwordForm.values.newPassword}
                            error={passwordForm.errors.newPassword}
                            onChange={(event) => passwordForm.setFieldValue('newPassword', event.currentTarget.value)}
                            radius="md"
                            size='xl'
                            labelProps={labelProps}
                            styles={labelStyles}
                            style={{ marginTop: 20 }} />
                        <PasswordInput
                            required
                            placeholder={"Ripeti nuova password"}
                            value={passwordForm.values.confirmPassword}
                            error={passwordForm.errors.confirmPassword}
                            onChange={(event) => passwordForm.setFieldValue('confirmPassword', event.currentTarget.value)}
                            radius="md"
                            size='xl'
                            labelProps={labelProps}
                            styles={labelStyles} />
                    </Stack>
                    <div style={{ textAlign: "center", marginTop: 40 }}>
                        <Button
                            loading={loading}
                            type="primary"
                            className="btn_secondary"
                            size="large"
                            htmlType="submit"
                            style={{ marginTop: 10 }}>
                            Cambia Password
                        </Button>
                    </div>
                </form>
            </div>

        </Drawer>

    )
}
export default CambiaPassword;