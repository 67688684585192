/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, LoadingOverlay, TextInput } from '@mantine/core';
import { IconBell, IconChevronLeft, IconSearch } from '@tabler/icons-react';
import { Badge, Button, Pagination, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadListaPrenotabiliByAdmin, getNotificheCount, prenotaUtenteByAdmin } from '../Utils/ApiUtils';
import { showPropsConfirm } from '../Utils/FunctionUtils';
import { useTokenActions } from '../Utils/tokenUtils';
import useWindowDimensions from '../Utils/windowDimension';
import ErrorPage from './Components/misc/ErrorPage';

export default function AmministraPrenotazioniAggiungi() {
    const { height } = useWindowDimensions();

    const token = useSelector(state => state.token);
    const { handleRemoveToken } = useTokenActions();

    const navigate = useNavigate();
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);
    const [utenti, setUtenti] = useState([])
    const [utentiSelezionati, setUtentiSelezionati] = useState([]);
    const [page, setPage] = useState(0);
    const [usersCount, setUsersCount] = useState(0);
    const [search, setSearch] = useState("");

    const labelProps = { style: { color: "#fff", fontWeight: 600, fontSize: 16, paddingBottom: 5, fontFamily: "Poppins" } };
    const labelStyles = {
        input: { border: "none", backgroundColor: "rgba(255,255,255,0.69)", color: "#fb5607", fontWeight: 600, fontFamily: "Poppins" },
        error: { color: "#fff", fontFamily: "Poppins", fontSize: 12 }
    };

    const prenotaUtenti = () => {
        const content = "Sei sicuro di voler effettuare la prenotazione per questi utenti?";
        const title = "Effettuare la prenotazione?";
        const onOk = () => prenotaUtenteByAdmin(setLoading, id, utentiSelezionati, handleRemoveToken, navigate, setError);
        const onCancel = () => { return };
        const okText = "Prenota";
        const okType = "primary";
        const cancelText = "Annulla";
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    useEffect(() => {
        if (token && token !== null) {
            downloadListaPrenotabiliByAdmin(id, 0, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError);
            getNotificheCount(setNotificheCount);
        } else {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }

        const interval = setInterval(() => {
            if (token && token !== null) {
                getNotificheCount(setNotificheCount);
            } else {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ color: "#fff", fontSize: 30, marginBottom: 10, fontWeight: "bold", lineHeight: 1.4 }}>
                                Aggiungi prenotazione
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ maxWidth: 800, paddingLeft: 20, paddingRight: 20, width: "100%" }}>
                                    <TextInput placeholder='Cerca un utente' value={search} onChange={e => {
                                        setSearch(e.target.value)
                                        setPage(0);
                                        downloadListaPrenotabiliByAdmin(id, 0, e.target.value, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError)
                                    }}
                                        icon={<IconSearch style={{ padding: 3, color: "rgba(131, 56, 236, 1)" }} />}
                                        radius="md"
                                        size='lg'
                                        labelProps={labelProps}
                                        styles={labelStyles}
                                    />
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column" }}>
                                <div style={{
                                    width: "100%", height: height - 440, overflowY: "auto", marginTop: 20, maxWidth: 800,
                                    display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", paddingLeft: 20, paddingRight: 20
                                }}>
                                    {utenti && utenti.length > 0 ? utenti.map((item, i) => (
                                        <div key={i}
                                            style={{
                                                color: "#444", backgroundColor: "rgba(255,255,255,0.69)", padding: 14,
                                                borderRadius: 10, marginBottom: i === utenti.length - 1 ? 0 : 20, width: "100%", fontFamily: "Poppins",
                                                display: "flex", alignItems: "center", justifyContent: "space-between"
                                            }}>
                                            <div style={{ color: "rgba(131, 56, 236, 1)", fontFamily: "Poppins", fontWeight: 600, fontSize: 18 }}>
                                                {item.nome + " " + item.cognome}
                                            </div>
                                            <div style={{
                                            }}>
                                                <Checkbox
                                                    size={"md"}
                                                    label={<div style={{ color: "rgba(131, 56, 236, 1)", fontSize: 16, fontFamily: "Poppins", fontWeight: 600 }}>
                                                        {item.label}
                                                    </div>}
                                                    value={utentiSelezionati.includes(item.id)}
                                                    checked={utentiSelezionati.includes(item.id)}
                                                    onChange={() => utentiSelezionati.includes(item.id) ?
                                                        setUtentiSelezionati(utentiSelezionati.filter(id => id.toString() !== item.id.toString()))
                                                        : setUtentiSelezionati([...utentiSelezionati, item.id])}
                                                    labelProps={labelProps} />
                                            </div>
                                        </div>
                                    )) :
                                        <center style={{ fontWeight: 600, fontFamily: "Poppins", color: "#fff", marginTop: 20 }}>Nessun risultato trovato</center>}
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingRight: 10, paddingLeft: 10, marginTop: 20 }}>
                                    <Pagination simple current={page + 1} total={usersCount} pageSize={10} onChange={(r) => {
                                        setPage(r - 1)
                                        downloadListaPrenotabiliByAdmin(id, r - 1, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError)
                                    }} />
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <Button shape="round" type="primary" className="btn_secondary" size="large"
                                        onClick={() => prenotaUtenti()}>
                                        Prenota utenti {utentiSelezionati.length>0&&"("+utentiSelezionati.length+")"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <ErrorPage onClick={() => {
                    setError(false);
                    downloadListaPrenotabiliByAdmin(id, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError);
                    setUtentiSelezionati([]);
                    setUtenti(null);
                }} />}
        </div>);
}