import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { Button } from 'antd';
import { useState } from 'react';

export default function CheckoutForm(props) {
  const { ammount } = props;

  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={{ color: "#fff" }}>
      <PaymentElement
        id="payment-element"
      />
      <Button shape="round" type="primary" className="btn_secondary" size="large"
        disabled={isLoading || !stripe || !elements} htmlType='submit' style={{marginTop:20}}>
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Paga ora "+ammount/100+"€"}
        </span>
      </Button>
      {message && <div style={{color:"red",fontWeight:"bold",marginTop:20}}>{message}</div>}
    </form>
  )
}