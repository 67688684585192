/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { IconBell, IconChevronLeft } from "@tabler/icons-react";
import { Badge } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNotificheCount, getPrenotazioniAttesa } from "../Utils/ApiUtils";
import { useTokenActions } from "../Utils/tokenUtils";
import ErrorPage from "./Components/misc/ErrorPage";
import useWindowDimensions from "../Utils/windowDimension";
const PrenotazioniAttesa = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const { handleRemoveToken } = useTokenActions();
    const { height } = useWindowDimensions();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);
    const [prenotazioniAttesa, setPrenotazioniAttesa] = useState(null);

    useEffect(() => {
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
            getPrenotazioniAttesa(setLoading, setPrenotazioniAttesa, handleRemoveToken, navigate, setError)
            const interval = setInterval(() => {
                getNotificheCount(setNotificheCount);
                getPrenotazioniAttesa(setLoading, setPrenotazioniAttesa, handleRemoveToken, navigate, setError)
            }, 300000);
            return () => clearInterval(interval);
        } else navigate(-1);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ marginTop: 10, color: "#fff", fontSize: 30, fontWeight: "bolder" }}>
                                Ticket in attesa
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {prenotazioniAttesa && prenotazioniAttesa !== null && prenotazioniAttesa.length > 0 ?
                                    <div style={{ width: "100%", height: height-275, overflowY: "auto", marginTop: 15, maxWidth: 800 }}>
                                        {prenotazioniAttesa.map((item, index) =>
                                            <div key={index}
                                                style={{
                                                    marginBottom: 20,
                                                    borderBottom: "1px solid rgba(100, 100, 100, 0.1)",
                                                    width: "100%",
                                                    borderRadius: 7, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 15, paddingBottom: 12, minHeight: 138
                                                }}>
                                                <div style={{
                                                    color: "#A00C98", fontSize: 14, fontWeight: 600, fontFamily: "Poppins", userSelect: "none"
                                                }}>
                                                    {item.nomeEvento}
                                                </div>
                                                <div style={{
                                                    color: "#fb5607", fontSize: 12, fontFamily: "Poppins", userSelect: "none", paddingBottom: 27
                                                }}>
                                                    {moment(item.dataEvento).format("DD-MM-YYYY")}
                                                </div>
                                            </div>
                                        )}
                                    </div> :
                                    <div style={{ marginBottom: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 15, borderRadius: 10, marginTop: 30 }}>
                                        <div style={{ color: "#A00C98", fontSize: 14 }}>Nessun ticket in attesa al momento</div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    getPrenotazioniAttesa(setLoading, setPrenotazioniAttesa, handleRemoveToken, navigate, setError)
                }} />}
        </div>
    )
}
export default PrenotazioniAttesa;