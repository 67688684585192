/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { IconBell, IconChevronLeft, IconPlus, IconTrash } from "@tabler/icons-react";
import { Badge } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteGroup, esciGroup, getGruppi, getNotificheCount } from "../Utils/ApiUtils";
import ErrorPage from "./Components/misc/ErrorPage";
import { showPropsConfirm } from "../Utils/FunctionUtils";
import { LoadingOutlined } from "@ant-design/icons";
import { useTokenActions } from "../Utils/tokenUtils";
import CreaGruppo from "./Components/menu/CreaGruppo";
import useWindowDimensions from "../Utils/windowDimension";
const Gruppi = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const { handleRemoveToken } = useTokenActions();
    const { height } = useWindowDimensions();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);
    const [gruppi, setGruppi] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [creaGruppo, setCreaGruppo] = useState(false);

    const cancellaGruppo = item => {
        const okType = "primary";
        const cancelText = "Annulla"

        let title = "Cancellare il gruppo?";
        let content = "Sei sicuro di voler cancellare il gruppo: " + item.name + "?";
        let okText = "Cancella";
        let onOk = () => deleteGroup(setLoading, setGruppi, setError, setLoadingDelete, item.id, handleRemoveToken, navigate);

        if (!item.proprietario) {
            title = "Uscire dal gruppo?";
            content = "Sei sicuro di voler uscire dal gruppo: " + item.name + "?";
            okText = "Esci";
            onOk = () => esciGroup(setLoading, setGruppi, setError, setLoadingDelete, item.id, handleRemoveToken, navigate);
        }

        const onCancel = () => { return };
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }

    const visualizzaGruppo = item => {
        navigate('/gruppo/' + item.id);
    }

    useEffect(() => {
        getGruppi(setLoading, setGruppi, setError, handleRemoveToken, navigate);
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
        }

        const interval = setInterval(() => {
            getGruppi(setLoading, setGruppi, setError, handleRemoveToken, navigate);
            if (token && token !== null) {
                getNotificheCount(setNotificheCount);
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ marginTop: 10, display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
                                <div style={{ color: "#fff", fontSize: 30, fontWeight: "bolder" }}>
                                    Gruppi
                                </div>
                                <div>
                                    <IconPlus
                                        onClick={() => setCreaGruppo(true)}
                                        style={{ cursor: "pointer", userSelect: "none" }}
                                        color="#fff" size={25} />
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {gruppi && gruppi !== null && gruppi.length > 0 ?
                                    <div style={{ width: "100%", height: height-278, overflowY: "auto", marginTop: 15,maxWidth:800 }}>
                                        {gruppi.map((item, index) =>
                                            <div key={index}
                                                style={{
                                                    marginBottom: 10,
                                                    borderBottom: "1px solid rgba(100, 100, 100, 0.1)",
                                                    display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: "100%",
                                                    borderRadius: 7, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 15, paddingBottom: 12
                                                }}>
                                                <div style={{
                                                    color: "#A00C98", fontSize: 15, fontWeight: 600, fontFamily: "Poppins",
                                                    flex: 0.8, userSelect: "none", cursor: "pointer"
                                                }} onClick={() => visualizzaGruppo(item)}>
                                                    {item.name}
                                                </div>
                                                <div style={{ cursor: loadingDelete ? "not-allowed" : "pointer", userSelect: "none", flex: 0.05 }}
                                                    onClick={() => !loadingDelete && cancellaGruppo(item)}>
                                                    {loadingDelete ? <LoadingOutlined spin /> : <IconTrash color="rgb(160, 12, 152)" size={24} />}
                                                </div>
                                            </div>
                                        )}
                                    </div> :
                                    <div style={{ marginBottom: 20, backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 15, borderRadius: 10, marginTop: 30 }}>
                                        <div style={{ color: "#A00C98", fontSize: 14 }}>Non sei in nessun gruppo</div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <CreaGruppo visible={creaGruppo} setVisible={setCreaGruppo} setLoading={setLoading} setGruppi={setGruppi} setError={null} />
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    getGruppi(setLoading, setGruppi, setError, handleRemoveToken, navigate);
                }} />}
        </div>
    )
}
export default Gruppi;