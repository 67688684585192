/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay, PasswordInput, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconBell, IconChevronLeft } from "@tabler/icons-react";
import { Badge, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changePersonalInfo, getImpostazioni, getNotificheCount } from "../Utils/ApiUtils";
import { useTokenActions } from "../Utils/tokenUtils";
import ErrorPage from "./Components/misc/ErrorPage";
import CambiaEmail from "./Components/impostazioni/CambiaEmail";
import CambiaPassword from "./Components/impostazioni/CambiaPassword";
import useWindowDimensions from "../Utils/windowDimension";
const Impostazioni = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token);
    const { handleRemoveToken } = useTokenActions();
    const { height } = useWindowDimensions();
    const labelProps = { style: { color: "rgb(160, 12, 152)", fontWeight: 600, fontSize: 16, paddingBottom: 5 } };
    const labelStyles = { input: { border: "none", color: "#fb5607", backgroundColor: "#fff" }, error: { color: "#fff", fontFamily: "Poppins", fontSize: 12 } };

    const [loading, setLoading] = useState(false);
    const [impostazioni, setImpostazioni] = useState(null);
    const [error, setError] = useState(false);
    const [notificheCount, setNotificheCount] = useState(0);
    const [email, setEmail] = useState(false);
    const [password, setPassword] = useState(false);

    const defaultForm = useForm({
        initialValues: {
            nome: "",
            cognome: "",
            cellulare: "",
            password: "",
            email: "Email",
        },

        validate: {
            nome: (val) => (typeof val === 'undefined' || val === null || val.trim().length <= 0 ? 'Inserisci il tuo nome' : null),
            cognome: (val) => (typeof val === 'undefined' || val === null || val.trim().length <= 0 ? 'Inserisci il tuo cognome' : null),
            cellulare: (val) => (typeof val === 'undefined' || val === null || val.trim().length < 10 ? 'Inserisci un cellulare valido' : null),
            password: (val) => (typeof val === 'undefined' || val === null ? 'Inserisci la password' : null),
        }
    })

    useEffect(() => {
        getImpostazioni(setImpostazioni, setLoading, defaultForm, handleRemoveToken, navigate, setError);
        if (token && token !== null) {
            getNotificheCount(setNotificheCount);
        }

        const interval = setInterval(() => {
            getImpostazioni(setImpostazioni, setLoading, defaultForm, handleRemoveToken, navigate, setError);
            if (token && token !== null) {
                getNotificheCount(setNotificheCount);
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>

                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins", padding: 20, paddingTop: 0 }}>
                            <div style={{ color: "#fff", fontSize: 30, marginBottom: 10, fontWeight: "bold", lineHeight: 1.4 }}>
                                Impostazioni
                                <div style={{ color: "rgb(160, 12, 152)", fontSize: 15, fontWeight: "bold" }}>
                                    Informazioni personali
                                </div>
                            </div>
                            <div style={{
                                width: "100%", height: height-280, overflowY: "auto", paddingLeft: 10, paddingRight: 10,
                                display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column"
                            }}>
                                <form
                                    style={{ maxWidth: 400, width: "100%", paddingBottom: 10 }}
                                    onSubmit={defaultForm.onSubmit((values) =>
                                        changePersonalInfo(values, setLoading, defaultForm, handleRemoveToken, navigate, setError))}>
                                    <Stack>
                                        <TextInput
                                            required
                                            placeholder="Il Tuo Nome"
                                            value={defaultForm.values.nome}
                                            error={defaultForm.errors.nome}
                                            onChange={(event) => defaultForm.setFieldValue("nome", event.target.value)}
                                            radius="lg"
                                            size='xl'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <TextInput
                                            required
                                            placeholder="Il Tuo cognome"
                                            value={defaultForm.values.cognome}
                                            error={defaultForm.errors.cognome}
                                            onChange={(event) => defaultForm.setFieldValue("cognome", event.target.value)}
                                            radius="lg"
                                            size='xl'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <TextInput
                                            required
                                            type="tel"
                                            placeholder="Il Tuo Cellulare"
                                            value={defaultForm.values.cellulare}
                                            error={defaultForm.errors.cellulare}
                                            onChange={(event) => defaultForm.setFieldValue("cellulare", event.target.value)}
                                            radius="lg"
                                            size='xl'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <div
                                            style={{ backgroundColor: "#fff", borderRadius: 15, padding: 17.6, fontSize: 16, color: "#fb5607", cursor: "pointer", userSelect: "none" }}
                                            onClick={() => setEmail(true)} >
                                            {impostazioni && impostazioni !== null && impostazioni.email ? impostazioni.email:"Email"}
                                        </div>

                                        <PasswordInput
                                            required
                                            label="Password"
                                            placeholder={"La tua password"}
                                            value={defaultForm.values.password}
                                            error={defaultForm.errors.password}
                                            onChange={(event) => defaultForm.setFieldValue('password', event.currentTarget.value)}
                                            radius="lg"
                                            size='xl'
                                            labelProps={labelProps}
                                            styles={labelStyles} />

                                        <Button
                                            loading={loading}
                                            type="primary"
                                            size="large"
                                            shape="round"
                                            htmlType="submit"
                                            style={{ marginTop: 10 }}>
                                            Salva
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="large"
                                            shape="round"
                                            onClick={()=>setPassword(true)}>
                                            Cambia password
                                        </Button>
                                    </Stack>
                                </form>
                            </div>
                        </div>
                    </div>
                    <CambiaEmail email={email} setEmail={setEmail} loading={loading} setLoading={setLoading}
                        handleRemoveToken={handleRemoveToken} navigate={navigate} setError={setError} />

                    <CambiaPassword password={password} setPassword={setPassword} loading={loading} setLoading={setLoading}
                        handleRemoveToken={handleRemoveToken} navigate={navigate} setError={setError} />
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    getImpostazioni(setLoading, setImpostazioni, setError);
                }} />}
        </div>
    )
}
export default Impostazioni;