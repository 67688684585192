/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from "@mantine/core";
import { Calendar, DatesProvider } from "@mantine/dates";
import { IconBalloon, IconBell, IconChevronLeft } from "@tabler/icons-react";
import { Badge } from "antd";
import dayjs from "dayjs";
import 'dayjs/locale/it';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { downloadDateEventi, downloadEventiByDate, downloadPrenotazioniByDate, downloadRole, getNotificheCount } from "../Utils/ApiUtils";
import { getPrenotazioneByEventId } from "../Utils/FunctionUtils";
import { useTokenActions } from "../Utils/tokenUtils";
import Biglietto from './Components/eventi/Biglietto';
import EventCard from "./Components/eventi/EventCard";
import ErrorPage from "./Components/misc/ErrorPage";
import useWindowDimensions from "../Utils/windowDimension";

const Calendario = () => {
    const navigate = useNavigate();
    const { handleSetRole, handleRemoveRole, handleRemoveToken } = useTokenActions();

    const token = useSelector(state => state.token);
    const isAdmin = useSelector(state => state.role);
    const { height } = useWindowDimensions();

    const [prenotazioni, setPrenotazioni] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [eventi, setEventi] = useState([]);
    const [biglietto, setBiglietto] = useState(null);
    const [notificheCount, setNotificheCount] = useState(0);
    const [date, setDate] = useState(new Date());
    const [dateEventi, setDateEventi] = useState(null);

    const dayRender = (date) => {
        const day = date.getDate();
        if (dateEventi && dateEventi !== null
            && dateEventi.length > 0) {
            const month = date.getMonth();
            const year = date.getFullYear();
            const foundEvent = dateEventi.find(e => new Date(e).getDate() === day && new Date(e).getMonth() === month && new Date(e).getFullYear() === year);

            if (typeof foundEvent === 'undefined') {
                return (
                    <div>{day}</div>
                );
            } else {
                return (
                    <Badge count={<IconBalloon color="red" size={20} />} offset={[8, -5]}>
                        <div style={{ fontSize: "1.25rem" }}>{day}</div>
                    </Badge>
                );
            }

        } else return (
            <div>{day}</div>
        );

    }

    const handleSelect = (date) => {
        setDate(date);
        downloadEventiByDate(loading, setLoading, setError, setEventi, date);
        if (token && token !== null) {
            downloadPrenotazioniByDate(setLoading, setError, setPrenotazioni, handleRemoveToken, navigate,date);
        }
    };

    useEffect(() => {
        downloadDateEventi(loading, setLoading, setError, setDateEventi);
        downloadEventiByDate(loading, setLoading, setError, setEventi, date);
        if (token && token !== null) {
            downloadRole(handleSetRole, handleRemoveRole, navigate);
            downloadPrenotazioniByDate(setLoading, setError, setPrenotazioni, handleRemoveToken, navigate,date); 
            getNotificheCount(setNotificheCount);
        }

        const interval = setInterval(() => {
            downloadDateEventi(loading, setLoading, setError, setDateEventi);
            downloadEventiByDate(loading, setLoading, setError, setEventi, date);
            if (token && token !== null) {
                downloadRole(handleSetRole, handleRemoveRole, navigate);
                downloadPrenotazioniByDate(setLoading, setError, setPrenotazioni, handleRemoveToken, navigate,date);
                getNotificheCount(setNotificheCount);
            }
        }, 300000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            backgroundColor: error ? "#228be6" : "unset",  overflow: "hidden"
        }}>
            {!error ?
                <div style={{ textAlign: "center", paddingTop: 15, overflow: "hidden" }}>
                    <LoadingOverlay visible={loading} />
                    <div style={{ marginBottom: 25, userSelect: "none" }}>
                        <img draggable={false} alt="logo" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 50, width: 50 }} />
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div style={{ marginLeft: 20, marginRight: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div><IconChevronLeft color="#fff" size={40} style={{ cursor: "pointer", userSelect: "none" }}
                                onClick={() => navigate(-1)}
                            /></div>
                            {token && token !== null && <div>
                                <Badge count={notificheCount} size="small" style={{ marginRight: 6, boxShadow: "none" }} color="yellow" dot>
                                    <IconBell color="transparent" fill="#fff" size={40} style={{ cursor: "pointer", userSelect: "none", paddingTop: 5 }}
                                        onClick={() => navigate("/notifiche")}
                                    /></Badge>
                            </div>}
                        </div>
                        <div style={{ fontFamily: "Poppins" }}>
                            <div style={{
                                marginTop: 10, color: "#fff", fontSize: 30, fontWeight: "bolder", padding: 20, paddingTop: 0, paddingBottom: 0,
                                alignSelf: "flex-start"
                            }}>
                                Calendario
                            </div>
                            <div style={{
                                display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-start",
                                height: height-250, overflow: "auto"
                            }}>
                                <div style={{ padding: 10, backgroundColor: "#fff", borderRadius: 10, marginTop: 10 }}>
                                    <DatesProvider settings={{ locale: 'it', firstDayOfWeek: 1, weekendDays: [0] }}>
                                        <Calendar
                                            size='lg'
                                            date={date} renderDay={dayRender}
                                            getDayProps={(data) => ({
                                                selected: dayjs(date).isSame(data, 'date'),
                                                onClick: () => handleSelect(data),
                                            })}
                                            onDateChange={data => handleSelect(data)} />
                                    </DatesProvider>
                                </div>
                                <div style={{ userSelect: "none", marginTop: 20, padding: 20 }}>
                                    {eventi && eventi.length > 0 && eventi.map((item, index) =>
                                        <div key={index} style={{ marginBottom: index === eventi.length - 1 ? 20 : 40 }}>
                                            <EventCard prenotazione={getPrenotazioneByEventId(prenotazioni, item.id)}
                                                item={item} setLoading={setLoading} setError={setError} setEventi={setEventi}
                                                handleRemoveToken={handleRemoveToken} navigate={navigate} isAdmin={isAdmin} token={token} loading={loading} setBiglietto={setBiglietto}
                                                setPrenotazioni={setPrenotazioni} />
                                        </div>)}
                                </div>
                            </div>

                            <Biglietto biglietto={biglietto} setBiglietto={setBiglietto}
                                loading={loading} setLoading={setLoading} setError={setError} setEventi={setEventi}
                                setPrenotazioni={setPrenotazioni} handleRemoveToken={handleRemoveToken} navigate={navigate}
                            />
                        </div>
                    </div>
                </div>
                :
                <ErrorPage onClick={() => {
                    setError(false);
                    downloadDateEventi(loading, setLoading, setError, setDateEventi);
                }} />}
        </div>
    )
}
export default Calendario;