import { message, notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { getImage, showPropsConfirm } from './FunctionUtils';
import { API } from './Request';
import axiosInstance from './axiosConfig';

export const downloadHomepage = (setLoading, setError, setEventi, handleRemoveToken) => {
    setLoading(true);
    axios.get(API.getHomepage).then(res => {
        setLoading(false);
        setEventi(res.data);
    }).catch(err => {
        setLoading(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
        }
        setError(true);
    })
}

export const downloadEventi = (loading, setLoading, setError, setEventi, navigate, handleRemoveToken) => {
    if (loading)
        return;

    setLoading(true);
    axiosInstance.get(API.getEventi).then(res => {
        setLoading(false);
        setEventi(res.data);
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description: "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
            setError(true);
        }
        setError(true);
    })
}

export const downloadEventiByDate = (loading, setLoading, setError, setEventi, data) => {
    if (loading)
        return;

    setLoading(true);
    const dataParsed = moment(data).format('YYYY-MM-DD');
    axios.get(API.getEventiByDate + '?data=' + dataParsed).then(res => {
        setLoading(false);
        setEventi(res.data);
    }).catch(() => {
        setLoading(false);
        setError(true);
    })
}

export const downloadDateEventi = (loading, setLoading, setError, setDate) => {
    if (loading)
        return;

    setLoading(true);
    axios.get(API.getEventiAllDate).then(res => {
        setLoading(false);
        setDate(res.data);
    }).catch(() => {
        setLoading(false);
        setError(true);
    })
}

export const downloadRole = (handleSetRole, handleRemoveRole, navigate) => {
    axiosInstance.get(API.getRole).then(res => {
        if (res.data.controllore)
            navigate('/check-qr/verifica')
        else
            handleSetRole(res.data.admin);
    }).catch(err => {
        handleRemoveRole();
    })
}

export const isEventoPrenotabile = (id, setLoadingBtnPrenota, setLoading, setError, setEventi, handleRemoveToken, navigate, loading) => {
    const okText = "Conferma";
    const cancelText = "Annulla";
    const okType = "primary";
    const onCancel = () => { return };
    message.loading({
        type: "loading",
        content: "Caricamento in corso...",
        key: "isEventoPrenotabile",
        duration: 0
    })
    axiosInstance.get(API.getEventoPrenotabile + "?id=" + id).then(res => {
        message.destroy("isEventoPrenotabile");
        const responseToText = res.data ? "bloccare" : "sbloccare";
        const title = "Vuoi " + responseToText + " le prenotazioni?";
        const content = "Sei sicuro di voler " + responseToText + " le prenotazioni per questo evento?";
        const prenotabile = res.data;
        const onOk = () => changeEventoPrenotabile(!prenotabile, id, setLoadingBtnPrenota, setLoading, setError, setEventi, handleRemoveToken, navigate, loading);
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
    }).catch(err => {
        message.destroy("isEventoPrenotabile");
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description: "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const changeEventoPrenotabile = (prenotabile, id, setLoadingBtnPrenota, setLoading, setError, setEventi, handleRemoveToken, navigate, loading) => {
    setLoadingBtnPrenota(true);
    const body = {
        id: id,
        prenotabile: prenotabile
    }
    axiosInstance.post(API.bloccaEvento, body)
        .then(() => {
            setLoadingBtnPrenota(false);
            downloadEventi(loading, setLoading, setError, setEventi, navigate, handleRemoveToken);
        }).catch(err => {
            setLoadingBtnPrenota(false);
            if (err && err.response) {
                if (err && err.response && err.response.status === 401) {
                    handleRemoveToken();
                    notification.error({
                        placement: "top",
                        message: 'Accesso scaduto',
                        description: "Effettua nuovamente l'accesso",
                    });
                    navigate('/login')
                    return;
                }
                setError(true);
            }
        })
}

export const isUserPrenotato = (id, titolo, limiteAnnullamento, startDate, prenotaText, setLoadingBtnPrenota, setLoading, setError, setEventi, handleRemoveToken, navigate, loading, setBiglietto, token, setPrenotazioni) => {
    setLoadingBtnPrenota(true);
    axiosInstance.get(API.getUserPrenotato + "?id=" + id).then(res => {
        setLoadingBtnPrenota(false);
        const response = res.data;

        if (response.prenotato) {
            if (response.attesa)
                notification.warning({
                    placement: "top",
                    message: 'In attesa di approvazione',
                    description: "Attendi che il tuo biglietto venga approvato da un'amministratore",
                });
            else getBiglietto(id, titolo, limiteAnnullamento, startDate, setLoadingBtnPrenota, setError, setBiglietto, token, handleRemoveToken, navigate);
            return;
        } else {
            const title = "Vuoi effettuare la prenotazione?";
            const content = prenotaText;
            const onOk = () => prenotaEvento(id, titolo, limiteAnnullamento, startDate, setLoadingBtnPrenota, setLoading, loading, setEventi, setError, handleRemoveToken, navigate, setBiglietto, token, setPrenotazioni);
            const okText = "Prenota";
            const cancelText = "Annulla";
            const okType = "primary";
            const onCancel = () => { return };
            showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
        }
    }).catch(err => {
        setLoadingBtnPrenota(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description: "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const prenotaEvento = (id, titolo, limiteAnnullamento, startDate, setLoadingBtnPrenota, setLoading, loading, setEventi, setError, handleRemoveToken, navigate, setBiglietto, token, setPrenotazioni) => {
    setLoadingBtnPrenota(true);
    axiosInstance.post(API.prenotaEvento + '?id=' + id, {})
        .then(response => {
            setLoadingBtnPrenota(false);
            downloadEventi(loading, setLoading, setError, setEventi, navigate, handleRemoveToken);
            downloadPrenotazioni(setLoading, setError, setPrenotazioni, handleRemoveToken, navigate);
            if (response.data === 'attesa') {
                notification.warning({
                    placement: "top",
                    message: 'Sei entrato in lista di attesa',
                    description:
                        "Attendi che un amministratore accetti la tua prenotazione",
                });
            } else {
                getBiglietto(id, titolo, limiteAnnullamento, startDate, setLoadingBtnPrenota, setError, setBiglietto, token, handleRemoveToken, navigate);
            }
        }).catch(err => {
            setLoadingBtnPrenota(false);
            if (err && err.response) {
                if (err && err.response && err.response.status === 401) {
                    handleRemoveToken();
                    notification.error({
                        placement: "top",
                        message: 'Accesso scaduto',
                        description:
                            "Effettua nuovamente l'accesso",
                    });
                    navigate('/login')
                    return;
                }
            }
            setError(true);
        })
}

export const getBiglietto = (id, titolo, limiteAnnullamento, startDate, setLoadingBtnPrenota, setError, setBiglietto, token, handleRemoveToken, navigate) => {
    setLoadingBtnPrenota(true);

    const config = {
        responseType: 'arraybuffer',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    axios.get(API.getBiglietto + '?id=' + id, config).then(response => {
        setLoadingBtnPrenota(false);
        const blob = new Blob([response.data], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        setBiglietto({ startDate: startDate, limiteAnnullamento: limiteAnnullamento, titolo: titolo, id: id, qrCode: url });
    }).catch(err => {
        setLoadingBtnPrenota(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const getBigliettoByAdmin = (idEvento, idUser, titolo, setLoadingBtnPrenota, setError, setBiglietto, token, handleRemoveToken, navigate) => {
    setLoadingBtnPrenota(true);

    const config = {
        responseType: 'arraybuffer',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    axios.get(API.getBigliettoByAdmin + '?idEvento=' + idEvento + '&idUser=' + idUser, config).then(response => {
        setLoadingBtnPrenota(false);
        const blob = new Blob([response.data], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        setBiglietto({ startDate: null, limiteAnnullamento: null, titolo: titolo, id: idEvento, qrCode: url });
    }).catch(err => {
        setLoadingBtnPrenota(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const downloadPrenotazioniByDate = (setLoading, setError, setPrenotazioni, handleRemoveToken, navigate, data) => {
    setLoading(true);
    const dataParsed = moment(data).format('YYYY-MM-DD');
    axiosInstance.get(API.getPrenotazioniByDate + "?data=" + dataParsed).then(res => {
        setLoading(false);
        setPrenotazioni(res.data);
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const downloadPrenotazioni = (setLoading, setError, setPrenotazioni, handleRemoveToken, navigate) => {
    setLoading(true);
    axiosInstance.get(API.getPrenotazioni).then(res => {
        setLoading(false);
        setPrenotazioni(res.data);
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const disdiciEvento = (id, setLoadingAnnulla, loading, setLoading, setError, setEventi, setPrenotazioni, handleRemoveToken, navigate, setBiglietto) => {
    setLoadingAnnulla(true);
    axiosInstance.post(API.disdiciEvento + '?id=' + id, {})
        .then(() => {
            setLoadingAnnulla(false);
            if (setEventi === null) {
                getPrenotazioniAttuali(setLoading, setPrenotazioni, handleRemoveToken, navigate, setError);
            } else {
                downloadEventi(loading, setLoading, setError, setEventi, navigate, handleRemoveToken);
                downloadPrenotazioni(setLoading, setError, setPrenotazioni, handleRemoveToken, navigate);
            }
            notification.success({
                placement: "top",
                message: 'Hai annullato la prenotazione',
                description: "Hai annullato la prenotazione con successo"
            })
            setBiglietto(null);
        }).catch(err => {
            setLoadingAnnulla(false);
            if (err && err.response) {
                if (err && err.response && err.response.status === 401) {
                    handleRemoveToken();
                    notification.error({
                        placement: "top",
                        message: 'Accesso scaduto',
                        description:
                            "Effettua nuovamente l'accesso",
                    });
                    navigate('/login')
                    return;
                }
            }
            setError(true);
        })
}

export const getNotifiche = (loading, setLoading, setNotifiche, handleRemoveToken, navigate, setError) => {
    if (loading)
        return;

    setLoading(true);
    axiosInstance.get(API.getNotifiche).then(response => {
        setLoading(false);
        setNotifiche(response.data)
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const getNotificheCount = (setNotificheCount) => {
    axiosInstance.get(API.getNotificheCount).then(response => {
        setNotificheCount(response.data)
    })
}

export const rispondiInvitoGruppo = (idGruppo, result, setLoadingInvito, handleRemoveToken, loading, setLoading, setNotifiche, navigate, setError) => {
    setLoadingInvito(idGruppo);
    axiosInstance.post(API.rispondiInvitoGruppo + '?userGroupId=' + idGruppo + '&result=' + result, {}).then(() => {
        setLoadingInvito(false);
        getNotifiche(loading, setLoading, setNotifiche, handleRemoveToken, navigate, setError);
        notification.success({
            placement: "top",
            message: 'Hai ' + (result ? 'accettato' : 'rifiutato') + " l'invito",
            description:
                result ? "Ora fai parte del gruppo" : "Hai rifiutato l'invito a partecipare al gruppo",
        });
    }).catch(err => {
        setLoadingInvito(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
    })

}

export const cancellaNotifica = (id, setLoadingCancella, loading, setLoading, setNotifiche, handleRemoveToken, navigate, setError) => {
    setLoadingCancella(id);
    axiosInstance.post(API.deleteNotifiche + '?id=' + id, {}).then(() => {
        setLoadingCancella(null);
        getNotifiche(loading, setLoading, setNotifiche, handleRemoveToken, navigate, setError);
        notification.success({
            placement: "top",
            message: 'Notifica cancellata',
            description:
                "Hai cancellato con successo la notifica",
        });
    }).catch(err => {
        setLoadingCancella(null);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
    })
}

export const getMenu = (setLoadingProfilo, setProfilo, handleRemoveToken) => {
    setLoadingProfilo(true);
    axiosInstance.get(API.getMenu).then(response => {
        setLoadingProfilo(false);
        setProfilo(response.data)
    }).catch(() => {
        setLoadingProfilo(false);
        setProfilo({ nome: "Effettua il login", email: "" });
        handleRemoveToken();
    })
}

export const getStaff = (setLoading, setStaff, setError) => {
    setLoading(true);
    axios.get(API.getStaff).then(response => {
        setLoading(false);
        setStaff(response.data)
    }).catch(() => {
        setLoading(false);
        setError(true);
    })
}

export const saveStaff = (id, values, setLoading, setLoadingCrea, setVisible, setStaffs, setError, handleRemoveToken, navigate) => {
    if (id !== null)
        values.id = id;

    setLoadingCrea(true);

    axiosInstance.post(API.saveStaff, values).then(() => {
        setLoadingCrea(false);
        setVisible(null);
        getStaff(setLoading, setStaffs, setError);
        notification.success({
            placement: "top",
            message: id === null ? 'Staffer aggiunto' : "Staffer modificato",
            description:
                (id === null ? 'Staffer aggiunto' : "Staffer modificato") + " con successo",
        });
    }).catch(err => {
        setLoadingCrea(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
};

export const deleteStaff = (setLoading, setStaff, setError, id, handleRemoveToken, navigate) => {
    setLoading(true);
    axiosInstance.post(API.deleteStaff + "?id=" + id, {}).then(() => {
        getStaff(setLoading, setStaff, setError);
    }).catch(err => {
        setLoading(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }
        setError(true);
    })
}

export const getAvvisi = (setLoading, setAvvisi, setError) => {
    setLoading(true);
    axios.get(API.getAvvisi).then(response => {
        setLoading(false);
        setAvvisi(response.data)
    }).catch(() => {
        setLoading(false);
        setError(true);
    })
}

export const saveAvviso = (id, values, setLoading, setLoadingCrea, setVisible, setAvvisi, setError, handleRemoveToken, navigate) => {
    if (id !== null)
        values.id = id;

    setLoadingCrea(true);

    axiosInstance.post(API.salvaAvvisi + "?notifica=" + values.notifica, values).then(() => {
        setLoadingCrea(false);
        setVisible(null);
        getAvvisi(setLoading, setAvvisi, setError);
        notification.success({
            placement: "top",
            message: id === null ? 'Avviso aggiunto' : "Avviso modificato",
            description:
                (id === null ? 'Avviso aggiunto' : "Avviso modificato") + " con successo",
        });
    }).catch(err => {
        setLoadingCrea(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
};

export const deleteAvviso = (setLoading, setAvvisi, setError, id, handleRemoveToken, navigate) => {
    setLoading(true);
    axiosInstance.post(API.cancellaAvviso + "?id=" + id, {}).then(() => {
        getAvvisi(setLoading, setAvvisi, setError);
    }).catch(err => {
        setLoading(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }
        setError(true);
    })
}


export const getPartners = (setLoading, setPartners, setError) => {
    if (setLoading !== null)
        setLoading(true);
    axios.get(API.getPartners).then(response => {
        if (setLoading !== null)
            setLoading(false);
        setPartners(response.data)
    }).catch(() => {
        if (setLoading !== null)
            setLoading(false);
        if (setError !== null)
            setError(true);
    })
}

export const savePartner = (id, values, setLoading, setLoadingCrea, file, token, setVisible, setPartners, setError, handleRemoveToken, navigate) => {
    if (id !== null)
        values.id = id;

    setLoadingCrea(true);

    const formData = new FormData();

    formData.append('partner', JSON.stringify({ ...values }));

    if (file && file !== null && file.originFileObj) {
        formData.append('photo', file.originFileObj);
    }

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    };

    axios.post(API.savePartners, formData, config).then(() => {
        setLoadingCrea(false);
        setVisible(null);
        getPartners(setLoading, setPartners, setError);
        notification.success({
            placement: "top",
            message: id === null ? 'Partner aggiunto' : "Partner modificato",
            description:
                (id === null ? 'Partner aggiunto' : "Partner modificato") + " con successo",
        });
    }).catch(err => {
        setLoadingCrea(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
};

export const deletePartner = (setLoading, setPartners, setError, id, handleRemoveToken, navigate) => {
    setLoading(true);
    axiosInstance.post(API.deletePartner + "?id=" + id, {}).then(() => {
        getPartners(setLoading, setPartners, setError);
    }).catch(err => {
        setLoading(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }
        setError(true);
    })
}

export const getGruppi = (setLoading, setGruppi, setError, handleRemoveToken, navigate) => {
    setLoading(true);
    axiosInstance.get(API.getGruppi).then(response => {
        setLoading(false);
        setGruppi(response.data)
    }).catch(err => {
        setLoading(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }
        setError(true);
    })
}

export const deleteGroup = (setLoading, setGruppi, setError, setLoadingDelete, id, handleRemoveToken, navigate) => {
    setLoadingDelete(true);
    axiosInstance.post(API.cancellaGruppo + "?id=" + id, {}).then(() => {
        notification.success({
            placement: "top",
            message: 'Gruppo cancellato',
            description:
                "Gruppo cancellato con successo",
        });
        setLoadingDelete(false);
        if (setGruppi !== null)
            getGruppi(setLoading, setGruppi, setError, handleRemoveToken, navigate);
        else navigate(-1);
    }).catch(err => {
        setLoadingDelete(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }
        setError(true);
    })
}

export const esciGroup = (setLoading, setGruppi, setError, setLoadingDelete, id, handleRemoveToken, navigate) => {
    setLoadingDelete(true);
    axiosInstance.post(API.esciGruppo + "?id=" + id, {}).then(() => {
        notification.success({
            placement: "top",
            message: 'Uscito dal gruppo',
            description:
                "Uscito dal gruppo con successo",
        });
        setLoadingDelete(false);
        if (setGruppi !== null)
            getGruppi(setLoading, setGruppi, setError, handleRemoveToken, navigate);
        else navigate(-1);
    }).catch(err => {
        setLoadingDelete(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }
        setError(true);
    })
}

export const getGruppo = (id, setLoading, setGruppo, navigate, handleRemoveToken) => {
    setLoading(true);
    axiosInstance.get(API.getGruppo + '?id=' + id).then(response => {
        setLoading(false);
        setGruppo(response.data)
    }).catch(err => {
        setLoading(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }
        navigate(-1);
    })
}

export const invitaGruppo = (id, cellulare, setLoading, setGruppo, navigate, form, handleRemoveToken, setError) => {
    setLoading(true);
    axiosInstance.post(API.invitaGruppo + '?groupId=' + id + "&cellulare=" + cellulare, {}).then(() => {
        notification.success({
            placement: "top",
            message: 'Invito inoltrato',
            description:
                "Invito inoltrato con successo",
        });
        form.reset();
        getGruppo(id, setLoading, setGruppo, navigate, handleRemoveToken);
    }).catch(err => {
        setLoading(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        } else if (err && err.response && err.response.status === 400) {
            notification.error({
                placement: "top",
                message: 'Errore interno',
                description:
                    err.response.data,
            });
            return;
        }
        setError(true);
    })
}

export const espelliGruppo = (id, userId, setLoadingDelete, setLoading, setGruppo, navigate, handleRemoveToken, setError) => {
    setLoadingDelete(true);
    axiosInstance.post(API.espelliGruppo + '?groupId=' + id + "&userId=" + userId, {}).then(() => {
        setLoadingDelete(false);
        notification.success({
            placement: "top",
            message: 'Utente espulso',
            description:
                "Utente espulso con successo",
        });
        getGruppo(id, setLoading, setGruppo, navigate, handleRemoveToken);
    }).catch(err => {
        setLoadingDelete(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }
        setError(true);
    })
}

export const creaGruppo = (setLoadingCrea, body, setLoading, setGruppi, setError, handleRemoveToken, navigate, form, setVisible) => {
    setLoadingCrea(true);
    axiosInstance.post(API.creaGruppo, body).then(() => {
        setLoadingCrea(false);
        setVisible(false);
        form.reset();
        notification.success({
            placement: "top",
            message: 'Gruppo creato',
            description:
                "Gruppo creato con successo",
        });
        if (setLoading !== null)
            getGruppi(setLoading, setGruppi, setError, handleRemoveToken, navigate);
    }).catch(err => {
        setLoadingCrea(false);
        if (err && err.response) {
            if (err.response.status === 400) {
                form.setFieldError(err.response.data, "Esiste già un gruppo con questo nome")
                return;
            }
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
            if (setError !== null)
                setError(true);
            else notification.error({
                placement: "top",
                message: 'Errore interno',
                description:
                    "Impossibile creare il gruppo, riprova",
            });
        }
    })
}

export const downloadPartecipantiGruppo = (setLoading, setError, setUtenti, handleRemoveToken, navigate,
    nomeGruppo, idEvento) => {

    setLoading(true);
    axiosInstance.get(API.getPartecipantiGruppo + "?groupName=" + nomeGruppo + "&eventId=" + idEvento).then(res => {
        setLoading(false);
        setUtenti(res.data);
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const changePersonalInfo = (values, setLoading, defaultForm, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    axiosInstance.post(API.cambiaInformazioni, values)
        .then(() => {
            setLoading(false);
            notification.success({
                placement: "top",
                message: "Informazioni personali aggiornate",
                description:
                    "Hai effettuato la modifica con successo"
            });

            defaultForm.setFieldValue('password', "");
        })
        .catch(err => {
            setLoading(false);
            if (err && err.response) {
                if (err.response.status === 400) {
                    switch (err.response.data) {
                        case 'password': {
                            defaultForm.setFieldError("password", "Password non corretta")
                            break;
                        }
                        case 'cellulare': {
                            defaultForm.setFieldError("cellulare", "cellulare già utilizzato")
                            break;
                        }
                        default:
                            break;
                    }
                    return;
                }
                if (err && err.response && err.response.status === 401) {
                    handleRemoveToken();
                    notification.error({
                        placement: "top",
                        message: 'Accesso scaduto',
                        description:
                            "Effettua nuovamente l'accesso",
                    });
                    navigate('/login')
                    return;
                }
            }
            setError(true);
        })
}

export const changePassword = (values, setLoading, passwordForm, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    axiosInstance.post(API.cambiaPassword, values)
        .then(() => {
            setLoading(false);
            notification.success({
                placement: "top",
                message: "Password aggiornata",
                description:
                    "Effettua nuovamente l'accesso per ragioni di sicurezza"
            });

            passwordForm.setFieldValue('password', "");
            passwordForm.setFieldValue('newPassword', "");
            passwordForm.setFieldValue('confirmPassword', "");

            handleRemoveToken();
            navigate('/login')
        })
        .catch(err => {
            setLoading(false);
            if (err && err.response) {
                if (err.response.status === 400) {
                    switch (err.response.data) {
                        case 'password': {
                            passwordForm.setFieldError("password", "Password non corretta")
                            break;
                        }
                        case 'passwordAlreadyUsed': {
                            passwordForm.setFieldError("newPassword", "La password deve essere diversa da quella attuale")
                            break;
                        }
                        default:
                            break;
                    }
                    return;
                }
                if (err && err.response && err.response.status === 401) {
                    handleRemoveToken();
                    notification.error({
                        placement: "top",
                        message: 'Accesso scaduto',
                        description:
                            "Effettua nuovamente l'accesso",
                    });
                    navigate('/login')
                    return;
                }
            }
            setError(true);
        })
}

export const changeEmail = (values, setLoading, emailForm, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    axiosInstance.post(API.cambiaEmail, values)
        .then(() => {
            setLoading(false);
            notification.success({
                placement: "top",
                message: "Indirizzo email aggiornato",
                description:
                    "Effettua nuovamente l'accesso per ragioni di sicurezza"
            });

            emailForm.setFieldValue('newEmail', "");
            emailForm.setFieldValue('confirmEmail', "");
            emailForm.setFieldValue('email', "");
            emailForm.setFieldValue('password', "");

            handleRemoveToken();
            navigate('/login')
        })
        .catch(err => {
            setLoading(false);
            if (err && err.response) {
                if (err.response.status === 400) {
                    switch (err.response.data) {
                        case 'password': {
                            emailForm.setFieldError("password", "Password non corretta")
                            break;
                        }
                        case 'email': {
                            emailForm.setFieldError("email", "Email non corretta")
                            break;
                        }
                        case 'emailAlreadyExists': {
                            emailForm.setFieldError("newEmail", "Email già utilizzata")
                            break;
                        }
                        default:
                            break;
                    }
                    return;
                }
                if (err && err.response && err.response.status === 401) {
                    handleRemoveToken();
                    notification.error({
                        placement: "top",
                        message: 'Accesso scaduto',
                        description:
                            "Effettua nuovamente l'accesso",
                    });
                    navigate('/login')
                    return;
                }
            }
            setError(true);
        })
}

export const getImpostazioni = (setImpostazioni, setLoadingInfo, form, handleRemoveToken, navigate, setError) => {
    setLoadingInfo(true);
    axiosInstance.get(API.getInfoPersonali)
        .then(res => {
            setLoadingInfo(false);
            form.setValues(res.data);
            setImpostazioni(res.data);
        })
        .catch(err => {
            if (err && err.response) {
                if (err && err.response && err.response.status === 401) {
                    handleRemoveToken();

                    notification.error({
                        placement: "top",
                        message: 'Accesso scaduto',
                        description:
                            "Effettua nuovamente l'accesso",
                    });
                    navigate('/login')
                    return;
                }
            }
            setLoadingInfo(false);
            setError(true);
        })
}

export const getContatti = (setContatti, setLoading, setError) => {
    setLoading(true);
    axios.get(API.getContatti)
        .then(res => {
            setLoading(false);
            setContatti(res.data);
        })
        .catch(() => {
            setLoading(false);
            setError(true);
        })
}

export const saveContatti = (id, values, setLoading, setLoadingCrea, setVisible, setContatti, setError, handleRemoveToken, navigate) => {
    if (id !== null)
        values.id = id;

    setLoadingCrea(true);

    axiosInstance.post(API.saveContatti, values).then(() => {
        setLoadingCrea(false);
        setVisible(null);
        getContatti(setContatti, setLoading, setError);
        notification.success({
            placement: "top",
            message: id === null ? 'Contatti aggiunti' : "Contatti modificati",
            description:
                (id === null ? 'Contatti aggiunti' : "Contatti modificati") + " con successo",
        });
    }).catch(err => {
        setLoadingCrea(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
};

export const contattaStaff = (values, setLoading, form, setError) => {
    setLoading(true);
    axios.post(API.contattaStaff, values)
        .then(res => {
            setLoading(false);
            form.reset();
            notification.success({
                placement: "top",
                message: 'Messaggio inoltrato',
                description:
                    "Messaggio inoltrato con successo",
            });
        })
        .catch(() => {
            setLoading(false);
            setError(true);
        })
}

export const getPrenotazioniAttuali = (setLoading, setPrenotazioni, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    axiosInstance.get(API.getPrenotazioniAttuali).then(res => {
        setLoading(false);
        setPrenotazioni(res.data)
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const getPrenotazioniAttesa = (setLoading, setPrenotazioni, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    axiosInstance.get(API.getPrenotazioniAttesa).then(res => {
        setLoading(false);
        setPrenotazioni(res.data)
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const getPrenotazioniPassate = (setLoading, setPrenotazioni, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    axiosInstance.get(API.getPrenotazioniPassate).then(res => {
        setLoading(false);
        setPrenotazioni(res.data)
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const downloadStatistiche = (id, setLoading, isGenerali, setStatistiche, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    const url = isGenerali ? API.getStatistiche : API.getStatistiche + '?id=' + id;
    axiosInstance.get(url).then(response => {
        setLoading(false);
        setStatistiche(response.data);
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    });
}

export const aggiungiEvento = (id, isNew, values, setLoading, fileList, token, handleRemoveToken, navigate, setError) => {
    if (!isNew)
        values.id = id;

    setLoading(true);

    const formData = new FormData();

    values.images = null;

    formData.append('event', JSON.stringify({ ...values }));

    if (fileList && fileList.length > 0) {
        fileList.forEach(file => file.originFileObj && formData.append('photos', file.originFileObj))
    }


    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    };

    axios.post(API.salvaEvento, formData, config).then(() => {
        setLoading(false);
        notification.success({
            placement: "top",
            message: 'Evento ' + (isNew ? "aggiungo" : "modificato"),
            description:
                'Evento ' + (isNew ? "aggiungo" : "modificato") + " con successo",
        });
        navigate(-1);
    })
        .catch(err => {
            setLoading(false);
            if (err && err.response) {
                if (err && err.response && err.response.status === 401) {
                    handleRemoveToken();
                    notification.error({
                        placement: "top",
                        message: 'Accesso scaduto',
                        description:
                            "Effettua nuovamente l'accesso",
                    });
                    navigate('/login')
                    return;
                }
            }
            setError(true);

        })
};

export const downloadEventById = (id, setLoading, form, setFileList, handleRemoveToken, navigate, setError) => {
    setLoading(true);

    axiosInstance.get(API.getEventoById + "?id=" + id).then(response => {
        setLoading(false);
        const newEdit = response.data;
        newEdit.startDate = new Date(newEdit.startDate);
        form.setValues(newEdit)
        if (newEdit.images && newEdit.images.length > 0) {
            let newFileList = [];
            newEdit.images.forEach((img, i) => {
                newFileList = [...newFileList, {
                    uid: i,
                    name: img.url,
                    fromEdit: true,
                    status: 'done',
                    url: getImage(img.url),
                }]
            });
            setFileList(newFileList);
        }
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        navigate(-1);
    })
}

export const cancellaEvento = (id, setLoadingModal, loading, setLoading, setEventi, handleRemoveToken, navigate, setError, setVisible) => {
    setLoadingModal(true);
    axiosInstance.post(API.cancellaEvento + '?id=' + id, {}).then(() => {
        setLoadingModal(false);
        setVisible(false);
        notification.success({
            placement: "top",
            message: 'Evento cancellato',
            description:
                "Evento cancellato con successo",
        });
        downloadEventi(loading, setLoading, setError, setEventi, navigate, handleRemoveToken);
    }).catch(err => {
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setLoadingModal(false);
        setVisible(false);
        setError(true);
    })
}

export const concludiEvento = (id, setVisible, loading, setLoading, setError, setEventi, setLoadingModal, item, handleRemoveToken, navigate) => {
    setLoadingModal(true);
    axiosInstance.post(API.concludiEvento + '?id=' + id, {}).then(() => {
        setLoadingModal(false);
        setVisible(false);
        notification.success({
            placement: "top",
            message: item.concluso ? "Evento ripartito" : "Evento concluso",
            description:
                item.concluso ? "Evento ripartito con successo" : "Evento concluso con successo",
        });
        downloadEventi(loading, setLoading, setError, setEventi, navigate, handleRemoveToken);
    }).catch(err => {
        setLoadingModal(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        notification.error({
            placement: "top",
            message: "Errore interno",
            description:
                "Errore interno con l'evento, riprova",
        });
    })

}

export const cambiaCapienza = (id, perCapienza, setLoadingCapienza, loading, setLoading, handleRemoveToken, navigate, setError, setEventi, setVisible) => {
    setLoadingCapienza(true);
    axiosInstance.post(API.cambiaCapienza + '?idEvento=' + id + '&capienza=' + perCapienza, {}).then(() => {
        setLoadingCapienza(false);
        downloadEventi(loading, setLoading, setError, setEventi, navigate, handleRemoveToken);
        notification.success({
            placement: "top",
            message: 'Capienza aggiornata',
            description:
                "Capienza aggiornata con successo",
        });
        setVisible(null);
    }).catch(err => {
        setLoadingCapienza(false);
        setVisible(null);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const downloadCapienza = (setLoadingDownloadCapienza, id, setCapienza, handleRemoveToken, navigate, setVisible) => {
    setLoadingDownloadCapienza(true);
    axiosInstance.get(API.downloadCapienza + '?idEvento=' + id).then(response => {
        setLoadingDownloadCapienza(false);
        setCapienza(response.data);
    }).catch(err => {
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setLoadingDownloadCapienza(false);
        setVisible(null);
        notification.error({
            placement: "top",
            message: 'Impossibile scaricare la capienza',
            description:
                "Impossibile scarica la capienza, riprova",
        });
    })
}

export const downloadEntrypage = (setLoading, setUrl, setError) => {
    setLoading(true);
    axios.get(API.getEntrypageGIF).then(response => {
        setLoading(false);
        setUrl(response.data.img);
    }).catch(() => {
        setLoading(false);
        if (setError !== null)
            setError(true);
    })
}

export const saveEntrypage = (setLoadingCrea, file, token, handleRemoveToken, navigate, setError) => {
    setLoadingCrea(true);
    const formData = new FormData();
    if (file && file !== null && file.originFileObj) {
        formData.append('photo', file.originFileObj);
    }
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    };

    axiosInstance.post(API.saveEntrypageGIF, formData, config).then(() => {
        setLoadingCrea(false);
        notification.success({
            placement: "top",
            message: "GIF caricata",
            description: "GIF caricata con successo",
        });
    }).catch(err => {
        setLoadingCrea(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
};

export const downloadUtentiByAdmin = (page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    axiosInstance.get(API.getUtenti + '?page=' + page + '&size=25' + (search !== null && search.trim() !== "" ? '&search=' + search : ""))
        .then(res => {
            setLoading(false);
            setUtenti(res.data.utenti);
            setUsersCount(res.data.usersCount);
        })
        .catch(err => {
            if (err && err.response) {
                if (err && err.response && err.response.status === 401) {
                    handleRemoveToken();
                    notification.error({
                        placement: "top",
                        message: 'Accesso scaduto',
                        description:
                            "Effettua nuovamente l'accesso",
                    });
                    navigate('/login')
                    return;
                }
            }
            setLoading(false);
            setError(true);
        })
}

export const downloadGruppiByAdmin = (setLoadingGruppi, setGruppi, handleRemoveToken, navigate) => {
    setLoadingGruppi(true);
    axiosInstance.get(API.getGruppiSelect).then(res => {
        setLoadingGruppi(false);
        setGruppi(res.data)
    }).catch(err => {
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();

                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setLoadingGruppi(false);
    })
}

export const downloadGruppiByOwner = (setLoadingGruppi, setGruppi, handleRemoveToken, navigate) => {
    if (setLoadingGruppi !== null)
        setLoadingGruppi(true);
    axiosInstance.get(API.getGruppiByOwnerSelect).then(res => {
        if (setLoadingGruppi !== null)
            setLoadingGruppi(false);
        setGruppi(res.data)
    }).catch(err => {
        if (setLoadingGruppi !== null)
            if (err && err.response) {
                if (err && err.response && err.response.status === 401) {
                    handleRemoveToken();
                    navigate('/login')
                    return;
                }
            }
        setLoadingGruppi(false);
    })
}

export const prenotaUtenteGruppo = (setLoading, gruppo, utentiSelezionati, idEvento, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    const body = {
        groupName: gruppo,
        utentiSelezionati: utentiSelezionati,
        eventId: idEvento
    }
    axiosInstance.post(API.prenotaUtenteGruppo, body).then(res => {
        setLoading(false);
        const content = (<><div>Sono state effettuate <b style={{ color: "#FF006E" }}>{res.data.totali}</b> prenotazioni</div>
            <div><b style={{ color: "#FF006E" }}>{res.data.prenotati}</b> prenotazioni confermate</div>
            {res.data.attesa > 0 && <div><b style={{ color: "#FF006E" }}>{res.data.attesa}</b> in attesa di conferma per mancanza di posti disponibili</div>}</>);
        const title = "Resoconto prenotazioni";
        const onOk = () => { return };
        const onCancel = () => { return };
        const okText = "Ho capito";
        const okType = "primary";
        const cancelText = null;
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
        navigate(-1);
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const bannaUtente = (setLoadingBan, id, banned, page, search, handleRemoveToken, navigate, setError, setLoading, setUtenti, setUsersCount, setVisible) => {
    setLoadingBan(id);
    axiosInstance.post(API.bannaUser + '?id=' + id, {}).then(() => {
        setLoadingBan(false);
        notification.success({
            placement: "top",
            message: 'Utente ' + (banned ? "Sbannato" : "Bannato"),
            description:
                "Utente " + (banned ? "sbannato" : "bannato") + " con successo",
        });
        downloadUtentiByAdmin(page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError);
        setVisible(null);
    }).catch(err => {
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setLoadingBan(false);
        setVisible(null);
        setError(true);
    })
}

export const cancellaUtente = (id, setLoadingDelete, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError, setVisible) => {
    setLoadingDelete(id);
    axiosInstance.post(API.cancellaUser + '?id=' + id, {}).then(() => {
        setLoadingDelete(false);
        notification.success({
            placement: "top",
            message: 'Utente cancellato',
            description:
                "Utente cancellato con successo",
        });
        downloadUtentiByAdmin(page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError);
        setVisible(null);
    }).catch(err => {
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setLoadingDelete(false);
        setVisible(null);
        setError(true);
    })
}

export const cambiaRuoloUtente = (id, setLoadingRole, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError, ruolo, setVisible) => {
    setLoadingRole(true);
    axiosInstance.post(API.ruoloUser + '?id=' + id + '&role=' + ruolo, {}).then(() => {
        setLoadingRole(false);
        notification.success({
            placement: "top",
            message: 'Ruolo cambiato',
            description:
                "Ruolo dell'utente cambiato con successo",
        });
        setVisible(null);
        downloadUtentiByAdmin(page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError);
    }).catch(err => {
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setLoadingRole(false);
        setVisible(null);
        setError(true);
    })
}

export const inviaEmailByAdmin = (email, testo, setLoadingEmail, handleRemoveToken, navigate, setError, setVisible) => {
    setLoadingEmail(true);
    axiosInstance.post(API.mailUser, { email: email, txt: testo }).then(() => {
        setLoadingEmail(false);
        notification.success({
            placement: "top",
            message: 'Email inviata',
            description:
                "Email inviata con successo",
        });
        setVisible(null);
    }).catch(err => {
        setLoadingEmail(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setVisible(null);
        setError(true);
    })
}

export const inviaEmailMultipleByAdmin = (testo, type, gruppo, handleRemoveToken, navigate, setError, setLoading) => {
    setLoading(true);
    axiosInstance.post(API.mailForwardUser, { txt: testo, email: type, group: gruppo }).then(() => {
        setLoading(false);
        notification.success({
            placement: "top",
            message: 'Email inviate',
            description:
                "Email inviate con successo",
        });
        navigate(-1);
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();

                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const inviaNotificaMultipleByAdmin = (testo, type, gruppo, title, handleRemoveToken, navigate, setError, setLoading, single) => {
    setLoading(true);
    axiosInstance.post(API.notificaForwardUser, { title: title, body: testo, type: type, group: gruppo }).then(() => {
        setLoading(false);
        notification.success({
            placement: "top",
            message: 'Notifica inviata',
            description:
                "Notifica inviata con successo",
        });
        if (!single)
            navigate(-1);
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();

                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const removeImage = (id, image, handleRemoveToken, navigate, setFileList) => {
    axiosInstance.post(API.removeImage + '?url=' + image.name + '&id=' + id, {}).then(() => {
    }).catch(err => {
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setFileList(prev => [...prev, image])
        notification.error({
            placement: "top",
            message: 'Errore interno',
            description:
                "Impossibile rimuovere l'immagine, riprova",
        });
    })
}

export const downloadListaPrenotatiByAdmin = (id, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    axiosInstance.get(API.getListaPrenotatiByAdmin + '?id=' + id + '&page=' + page + '&size=10' + (search !== null && search.trim() !== "" ? '&search=' + search : "")
    ).then(response => {
        setLoading(false);
        if (response.data && response.data.prenotazioni.length > 0) {
            setUtenti(response.data.prenotazioni);
            setUsersCount(response.data.count);
        }
        else {
            setUtenti(null);
            setUsersCount(0);
        }
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const downloadListaAttesaByAdmin = (id, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    axiosInstance.get(API.getListaAttesaByAdmin + '?id=' + id + '&page=' + page + '&size=10' + (search !== null && search.trim() !== "" ? '&search=' + search : "")
    ).then(response => {
        setLoading(false);
        if (response.data && response.data.prenotazioni.length > 0) {
            setUtenti(response.data.prenotazioni);
            setUsersCount(response.data.count);
        }
        else {
            setUtenti(null);
            setUsersCount(0);
        }
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const downloadListaPrenotabiliByAdmin = (id, page, search, setLoading, setUtenti, setUsersCount, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    axiosInstance.get(API.getListaPrenotabiliByAdmin + '?id=' + id + '&page=' + page + '&size=10' + (search !== null && search.trim() !== "" ? '&search=' + search : "")
    ).then(response => {
        setLoading(false);
        if (response.data && response.data.prenotazioni.length > 0) {
            setUtenti(response.data.prenotazioni);
            setUsersCount(response.data.count);
        }
        else {
            setUtenti(null);
            setUsersCount(0);
        }
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}

export const prenotaUtenteByAdmin = (setLoading, idEvento, utentiSelezionati, handleRemoveToken, navigate, setError) => {
    setLoading(true);
    const body = {
        id: idEvento,
        selezionati: utentiSelezionati
    }
    axiosInstance.post(API.prenotaEventoByAdmin, body).then(res => {
        setLoading(false);
        const content = (<><div>Sono state effettuate <b style={{ color: "#FF006E" }}>{res.data.totali}</b> prenotazioni</div></>);
        const title = "Resoconto prenotazioni";
        const onOk = () => { return };
        const onCancel = () => { return };
        const okText = "Ho capito";
        const okType = "primary";
        const cancelText = null;
        showPropsConfirm(title, content, okText, okType, cancelText, onOk, onCancel);
        navigate(-1);
    }).catch(err => {
        setLoading(false);
        if (err && err.response) {
            if (err && err.response && err.response.status === 401) {
                handleRemoveToken();
                notification.error({
                    placement: "top",
                    message: 'Accesso scaduto',
                    description:
                        "Effettua nuovamente l'accesso",
                });
                navigate('/login')
                return;
            }
        }
        setError(true);
    })
}


export const rimuoviPrenotazioneByAdmin = (id, userId, setLoadingModal, setVisible, download, handleRemoveToken, navigate, setError) => {
    setLoadingModal(true);
    axiosInstance.post(API.rimuoviPrenotazioneByAdmin + '?eventId=' + id + '&userId=' + userId)
        .then(() => {
            setLoadingModal(false);
            notification.success({
                placement: "top",
                message: 'Prenotazione annullata',
                description:
                    "Prenotazione annullata con successo!",
            });
            setVisible(null);
            download();
        }).catch(err => {
            setLoadingModal(false);
            if (err && err.response) {
                if (err && err.response && err.response.status === 401) {
                    handleRemoveToken();
                    notification.error({
                        placement: "top",
                        message: 'Accesso scaduto',
                        description:
                            "Effettua nuovamente l'accesso",
                    });
                    navigate('/login')
                    return;
                }
            }
            setVisible(null);
            setError(true);
        })
}

export const approvaPrenotazioneByAdmin = (setLoadingModal, id, idEvento, download, navigate, setVisible, setError, handleRemoveToken) => {
    setLoadingModal(true);
    axiosInstance.post(API.approvaAttesaByAdmin + '?userId=' + id + '&eventId=' + idEvento, {}).then(() => {
        setLoadingModal(false);
        notification.success({
            placement: "top",
            message: 'Prenotazione approvata',
            description:
                "Prenotazione approvata con successo",
        });
        setVisible(null);
        download();
    }).catch(err => {
        setLoadingModal(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }
        setVisible(null);
        setError(true);
    })
}

export const verificaQrCode = (soloCheck, qrCode, setLoading, setVerifica, setResult, navigate, startCamera, handleRemoveToken, isCameraStarted) => {
    if (qrCode.startsWith(API.verificaQrCode)) {
        const params = qrCode.split('/');
        qrCode = params[params.length - 1];
    }

    setLoading(true);
    axiosInstance.post(API.validaQr + '?soloCheck=' + soloCheck, { qrCode: qrCode }).then(response => {
        setLoading(false);
        if (soloCheck) {
            setVerifica(response.data);
            setResult(null);
        } else {
            if (response.data.valido) {
                setVerifica(null);
                setResult(null);
                notification.success({
                    placement: "top",
                    message: 'Biglietto utilizzato',
                    description:
                        "Puoi procedere a far entrare li cliente, il biglietto è passato",
                });
                navigate('/check-qr/verifica')
                if (!isCameraStarted) {
                    startCamera();
                }
            } else {
                setVerifica({ ...response.data, valido: false, utilizzi: 0 });
            }
        }

    }).catch(err => {
        setLoading(false);
        if (err && err.response && err.response.status === 401) {
            handleRemoveToken();
            notification.error({
                placement: "top",
                message: 'Accesso scaduto',
                description:
                    "Effettua nuovamente l'accesso",
            });
            navigate('/login')
            return;
        }
        setResult(null);
    })
};

